import { memo, MouseEventHandler, PropsWithChildren, RefObject, useEffect } from 'react';

type RowProps = {
  selected: boolean;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  index: number;
  wrapper: RefObject<HTMLDivElement>;
};

const Row = memo(({ children, selected, onClick, index, wrapper }: PropsWithChildren<RowProps>) => {
  useEffect(() => {
    if (selected && wrapper?.current) {
      const htmlElement = document.getElementById(`row-${index}`);
      wrapper.current.scrollTo({
        top: htmlElement?.offsetTop ? htmlElement?.offsetTop - htmlElement.offsetHeight : 0,
        behavior:'smooth',
      });
    }
  }, [index, selected, wrapper]);
  return (
    <tr
      onClick={onClick || (() => { })}
      className={`modern ${selected ? 'selected' : ''} ${onClick ? 'clickable' : ''}`}
      id={`row-${index}`}
    >
      {children}
    </tr>
  );
});

export default Row;
