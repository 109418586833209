import { useCallback, useEffect, useState } from 'react';
import * as THREE from 'three';
import PointClassStyles, { StyledPointClass } from '../../@types/PointClassStyles';
import Tree from '../../@types/Tree';
import TreePointCloud from '../../@types/TreePointCloud';
import LASLoader from '../../utils/LASLoader';

const treeLoadingManager = new THREE.LoadingManager();
const treeLazLoader = new LASLoader(treeLoadingManager);

const environmentLoadingManager = new THREE.LoadingManager();
const environmentLazLoader = new LASLoader(environmentLoadingManager);

export interface UsePointCloudValue {
  originalPointCloud: any;
  pointCloud: any;
  pointCloudLoading: boolean;
  pointCloudError: any;
  isEnvironmentVisible: boolean;
  environmentPointCloud: any;
  environmentPointCloudLoading: boolean;
  environmentPointCloudError: any;
  environmentNotFound: boolean;
  configItems: {
    [key: string]: string;
  };
  pointClassStyles: PointClassStyles;
  pointSize: number;

  handleLasLoad: () => Promise<void>;
  handleEnvironmentLasLoad: () => Promise<void>;
  setIsEnvironmentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  resetPointCloud: () => Promise<void>;
  resetEnvironmentPointCloud: () => Promise<void>;
  resetPointClouds: () => Promise<void>;
  setOriginalPointCloud: React.Dispatch<React.SetStateAction<any>>;
  setPointCloud: React.Dispatch<React.SetStateAction<any>>;
  setEnvironmentPointCloud: React.Dispatch<React.SetStateAction<any>>;
  setPointClassStyles: React.Dispatch<React.SetStateAction<PointClassStyles>>
  setPointSize: React.Dispatch<React.SetStateAction<number>>;
  setConfigItems: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  setConfigColor: (name: string, value: string) => void;
  toggleOpacity: (pointClassPart: keyof typeof StyledPointClass) => void;
}

export interface UsePointCloudProps {
  currentTree: Tree;
}

export const usePointCloud = ({ currentTree }: UsePointCloudProps): UsePointCloudValue => {
  const [originalPointCloud, setOriginalPointCloud] = useState<any>(null);
  const [pointCloud, setPointCloud] = useState<any>(null);
  const [pointCloudLoading, setPointCloudLoading] = useState<any>(false);
  const [pointCloudError, setPointCloudError] = useState<any>(null);
  const [isEnvironmentVisible, setIsEnvironmentVisible] = useState<boolean>(false);
  const [environmentPointCloud, setEnvironmentPointCloud] = useState<any>(null);
  const [environmentPointCloudLoading, setEnvironmentPointCloudLoading] = useState<any>(false);
  const [environmentPointCloudError, setEnvironmentPointCloudError] = useState<any>(null);
  const [environmentNotFound, setEnvironmentNotFound] = useState<boolean>(false);
  const [pointSize, setPointSize] = useState<number>(1);
  const [pointClassStyles, setPointClassStyles] = useState<PointClassStyles>({
    trunk: {
      color: '#795548',
      opacity: true,
    },
    otherTrunk: {
      color: '#0000ff',
      opacity: true,
    },
    canopy: {
      color: '#48BB78',
      opacity: true,
    },
    otherCanopy: {
      color: '#b801b9',
      opacity: true,
    },
    environment: {
      color: '#818385',
      opacity: true,
    },
  });

  const [configItems, setConfigItems] = useState<{ [key: string]: string }>({
    canopy: pointClassStyles.canopy.color,
    trunk: pointClassStyles.trunk.color,
    environment: pointClassStyles.environment.color,
    otherCanopy: pointClassStyles.otherCanopy.color,
    otherTrunk: pointClassStyles.otherTrunk.color,
  });

  const setConfigColor = (name: string, value: string) => setConfigItems(prev => ({ ...prev, [name]: value }));

  const resetPointCloud = useCallback(async () => {
    setOriginalPointCloud(null);
    setPointCloud(null);
    setPointCloudLoading(false);
    setPointCloudError(null);
  }, []);

  const resetEnvironmentPointCloud = useCallback(async () => {
    setEnvironmentPointCloud(null);
    setEnvironmentPointCloudLoading(false);
    setEnvironmentPointCloudError(null);
    setIsEnvironmentVisible(false);
  }, []);

  const resetPointClouds = useCallback(async () => {
    await resetPointCloud();
    await resetEnvironmentPointCloud();
  }, [resetPointCloud, resetEnvironmentPointCloud]);

  const handleLasLoad = useCallback(async () => {
    setOriginalPointCloud(null);
    setPointCloud(null);
    setPointCloudLoading(true);
    setPointCloudError(null);
    resetEnvironmentPointCloud();

    try {
      const url = currentTree.tree_segmentation_laz_url;
      treeLazLoader.load(url, (pc: any) => {
        setOriginalPointCloud(pc);
        setPointCloud(pc);

        if (!pc?.geometry) return;

        const configItems = localStorage.getItem('pointcloud-colors');
        if (configItems) {
          const parsedConfigItems = JSON.parse(configItems);
          setConfigItems((prev) => ({ ...prev, ...parsedConfigItems }));
          const items = Object.entries(parsedConfigItems);
          items.forEach((item) => setClassColor(item[0] as keyof PointClassStyles, item[1] as string));
        }

        toggleOpacity('canopy', true);
        toggleOpacity('trunk', true);
      });
    } catch (error) {
      console.error('Error fetching tree segmentation LAS:', error);
      setPointCloudError(error as any);
    } finally {
      setPointCloudLoading(false);
    }
  }, [currentTree?.id, pointCloudLoading]);

  const handleEnvironmentLasLoad = useCallback(async () => {
    if (!isEnvironmentVisible) return;

    setEnvironmentPointCloud(null);
    setEnvironmentPointCloudLoading(true);
    setEnvironmentPointCloudError(null);

    if (environmentPointCloud?.id !== currentTree?.id) {
      setEnvironmentPointCloud(null);
      setEnvironmentNotFound(false);

      if (currentTree?.id && isEnvironmentVisible) {
        try {
          const url = currentTree.tree_segmentation_environment_laz_url;
          environmentLazLoader.load(
            url,
            (pc: any) => {
              setEnvironmentPointCloud(pc);
              setEnvironmentPointCloudLoading(false);
            },
            () => { },
            () => setEnvironmentNotFound(true)
          );
        } catch (error) {
          console.error('Error fetching tree environment LAS:', error);
          setEnvironmentPointCloudError(error as any);
        }
      }
    }
  }, [currentTree?.id, isEnvironmentVisible]);

  const colorHexStringToRgbArray = (hex: string) => {
    if (hex.length !== 7) {
      throw new Error('Only six-digit hex colors are allowed (eq: #1122BB).');
    }

    let tmp = hex.substring(1).match(/.{1,2}/g);
    if (tmp === null) {
      throw new Error('Invalid hex color format.');
    }

    return [parseInt(tmp[0], 16), parseInt(tmp[1], 16), parseInt(tmp[2], 16)];
  };

  const writeColor = (laz: TreePointCloud, color: string, pclass: keyof typeof StyledPointClass) => {
    const newColors = new Float32Array(laz?.pc.pointCount * 3);

    const oldColors = laz?.geometry?.getAttribute('color').array;
    const classifications = laz?.geometry?.getAttribute('classification').array;
    const rgb = colorHexStringToRgbArray(color);
    for (const [index, pointClass] of (classifications as any).entries()) {
      if (StyledPointClass[pclass] === pointClass) {
        newColors[3 * index] = rgb[0] / 255;
        newColors[3 * index + 1] = rgb[1] / 255;
        newColors[3 * index + 2] = rgb[2] / 255;
      } else {
        newColors[3 * index] = oldColors[3 * index];
        newColors[3 * index + 1] = oldColors[3 * index + 1];
        newColors[3 * index + 2] = oldColors[3 * index + 2];
      }
    }
    return newColors;
  };

  const setClassColor = (pclass: any, color: any) => {
    const newLazColors = writeColor(pointCloud, color, pclass);
    pointCloud?.geometry?.setAttribute('color', new THREE.BufferAttribute(newLazColors, 3));

    const envLAz = environmentPointCloud;
    if (envLAz) {
      const newEnvColors = writeColor(envLAz, color, pclass);
      envLAz?.geometry?.setAttribute('color', new THREE.BufferAttribute(newEnvColors, 3));
    }
  };

  useEffect(() => {
    // handle color change
    const items = Object.entries(configItems);
    !!pointCloud?.geometry && items.forEach((item) => setClassColor(item[0] as keyof PointClassStyles, item[1] as string));
  }, [configItems, environmentPointCloud])

  const setVisibilityForPoint = (classification: number) => {
    if (classification === 21) {
      return pointClassStyles.canopy.opacity ? 1 : 0;
    } else if (classification === 20) {
      return pointClassStyles.trunk.opacity ? 1 : 0;
    } else if (classification === 22) {
      return pointClassStyles.otherTrunk.opacity ? 1 : 0;
    } else if (classification === 23) {
      return pointClassStyles.otherCanopy.opacity ? 1 : 0;
    } else if (classification === 0) {
      return pointClassStyles.environment.opacity ? 1 : 0;
    } else {
      return 0;
    }
  }

  const toggleOpacity = (pointClassPart: keyof typeof StyledPointClass, value: boolean | null = null) => {
    setPointClassStyles((prev) => ({
      ...prev,
      [pointClassPart]: {
        color: prev[pointClassPart].color,
        opacity: value ?? !prev[pointClassPart].opacity,
      },
    }));
  };

  useEffect(() => {
    // handle visibility change for canopy and trunk
    if (!pointCloud?.geometry) return;
    const classifications = pointCloud.geometry.getAttribute('classification').array;

    const newVisibility = new Float32Array(pointCloud.pc.pointCount);
    for (let pointIndex = 0; pointIndex < pointCloud.pc.pointCount; pointIndex++) {
      newVisibility[pointIndex] = setVisibilityForPoint(classifications[pointIndex]);
    }

    pointCloud.geometry.setAttribute("visibility", new THREE.BufferAttribute(newVisibility, 1));
  }, [pointClassStyles])

  useEffect(() => {
    if (!currentTree?.id) {
      console.log('Tree not found');
      resetPointClouds();

      return;
    }

    handleLasLoad();
  }, [currentTree?.id]);

  useEffect(() => {
    if (!environmentPointCloud && isEnvironmentVisible) {
      handleEnvironmentLasLoad();
    }
  }, [isEnvironmentVisible])

  return {
    originalPointCloud,
    pointCloud,
    pointCloudLoading,
    pointCloudError,
    isEnvironmentVisible,
    environmentPointCloud,
    environmentPointCloudLoading,
    environmentPointCloudError,
    environmentNotFound,
    pointClassStyles,
    pointSize,
    configItems,

    handleLasLoad,
    handleEnvironmentLasLoad,
    setIsEnvironmentVisible,
    resetPointCloud,
    resetEnvironmentPointCloud,
    resetPointClouds,
    setOriginalPointCloud,
    setPointCloud,
    setEnvironmentPointCloud,
    setPointClassStyles,
    setPointSize,
    setConfigItems,
    setConfigColor,
    toggleOpacity
  };
};
