import { useMemo } from 'react';
import { Vector3 } from 'three';
import ValidationAction from '../../../@types/enums/ValidationAction';
import { useScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import SectionControl from '../../Slider/SectionControl';

const SectionToolbar = () => {
  const { trunkActions, helper, section } = useScreenContext();

  const isGirthActive = helper.activeTool === ValidationAction.Girth;
  const isFirstBifActive = helper.activeTool === ValidationAction.FirstBifurcationPoint;
  const isLeanAngleActive = helper.activeTool === ValidationAction.LeaningVector;

  const isHeightDisabled = useMemo(() => {
    if (isGirthActive) return false;
    if (!isGirthActive && !isLeanAngleActive && !isFirstBifActive) return false;
    return true;
  }, [isFirstBifActive, isGirthActive, isLeanAngleActive]);

  const updateGirthHeight = (height: number) => {
    if (!trunkActions.shownGirth) return;
    trunkActions.updateGirth({ ...trunkActions.shownGirth, height });
  };

  const handleHeightUpdate = (height: number) => {
    const correctedHeight = height + helper.viewerPosition.z;
    section.setTarget(new Vector3().set(section.target?.x ?? 0, section.target?.y ?? 0, correctedHeight));

    if (isGirthActive) {
      updateGirthHeight(height);
    }
  };

  return (
    <SectionControl
      height={section.target?.z! - helper.viewerPosition.z || 0}
      depth={section.depth}
      setHeight={handleHeightUpdate}
      setDepth={section.setDepth}
      disabled={{ height: helper.isEditingDisabled || isHeightDisabled, depth: helper.isEditingDisabled }}
    />
  );
};

export default SectionToolbar;
