import { clone } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router';
import Tree from '../../@types/Tree';
import AttributesInput from '../../components/Microclimate/Attributes/AttributesInput';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import { BetaManagedAreaContextValue, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { Grid, LoaderWrapper } from '../../layout';
import SingleTree from '../Completed/SingleTree';

type MicroclimateValidationProps = {
  isToolbarVisible: boolean;
  managedArea: BetaManagedAreaContextValue['managedArea'];
  contextsLoadingState: boolean;
  activeTabs: Record<'left' | 'right', string>;
  setActiveTabs: React.Dispatch<React.SetStateAction<null>>;
  selectTree: (treeId: string) => void;
  getNextTree: (direction?: number) => Partial<Tree> | null;
};

const MicroclimateValidation = ({
  isToolbarVisible,
  managedArea,
  contextsLoadingState,
  activeTabs,
  setActiveTabs,
  selectTree,
  getNextTree,
}: MicroclimateValidationProps) => {
  const urlParams = useParams<{ tree: string }>();

  const [voxel, setVoxel] = useState(null);
  const [pointcloud, setPointcloud] = useState(null);

  const selectedTreeId = urlParams.tree;

  const { fetchTrees: reloadTrees, pipeline, fetch: reloadManagedArea } = useBetaManagedAreaContext();

  const betaTree = useBetaTree({
    treeId: selectedTreeId,
    managedAreaId: managedArea?.id,
    managedAreaCode: managedArea?.code,
    isMicroclimate: true,
  });

  const currentTree = clone(betaTree?.tree);

  const [panoramicLoading, setPanoramicLoading] = useState(false);

  if (!managedArea?.id || !pipeline?.id || !currentTree?.id) return null;

  const isLoading = () => {
    return !currentTree?.location || betaTree?.loading || contextsLoadingState || panoramicLoading;
  };

  return (
    <LoaderWrapper loading={isLoading()}>
      <Grid
        fullHeight={true}
        tabcontent={
          currentTree && (
            <div className='microclimate-tight-side-wrapper tight-side-wrapper'>
              <AttributesInput
                tree={currentTree}
                managedAreaId={managedArea.id}
                activeTabs={activeTabs}
                setGenusAndSpecies={betaTree.setGenusAndSpecies}
                updateMicroclimate={betaTree.updateMicroclimate}
                addTreeComment={betaTree.addComment}
                getNextTree={getNextTree}
                selectTree={selectTree}
                updateTree={async (tree_flow_status: TreeFlowStatus.SpeciesValidationQueued | TreeFlowStatus.SpeciesValidationDone) => {
                  await betaTree.updateTree({ tree_flow_status });
                }}
                undo={{
                  onAction: () => console.log('Unimplemented feature -> onAction to history'),
                }}
                isToolbarVisible={isToolbarVisible}
                reloadTrees={reloadTrees}
                getGenuses={betaTree.getGenuses}
                getSpecies={betaTree.getSpecies}
                reloadManagedArea={reloadManagedArea}
              />
            </div>
          )
        }
        infocontent={undefined}
      >
        <div className='location-validation-wrapper microclimate-validation'>
          <div className='viewers'>
            <SingleTree
              tree={currentTree}
              activeTabs={activeTabs}
              setActiveTabs={setActiveTabs}
              selectTree={selectTree}
              setPanoramicLoading={setPanoramicLoading}
              {...{ voxel, setVoxel, pointcloud, setPointcloud }}
            />
          </div>
        </div>
      </Grid>
    </LoaderWrapper>
  );
};

export default MicroclimateValidation;
