import { Table } from '../../components';
import useUsers from '../../hooks/users';

const ROLES = [
  /*{
    label: 'No role assigned',
    value: 'default'
  },*/
  {
    label: 'Guest',
    value: 'guest'
  },
  {
    label: 'Officer',
    value: 'officer'
  },
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Supervisor',
    value: 'supervisor'
  },

];

const generateConfig = (updateUser = () => {}) => ({
  columns: [
    {
      title: 'Employer ID',
      key: 'id',
      resolver: 'id',
      sortable: true,
    },
    {
      title: 'Name',
      key: 'name',
      resolver: (user) => user.user_name,
      sortable: true,
    },
    {
      title: 'Role',
      key: 'role',
      resolver: 'roles', // ({ roles }) => roles || 'default',
      sortable: true,
      format: 'MULTISELECT',
      options: ROLES,
      label: 'No role assigned', 
      onChange: (key, value, user) => updateUser(user, value),
    },
  ],
  selection: (entry) => null,
});

const AdministrativePreferences = () => {
  const { users, updateUsers } = useUsers();

  const _handleUpdate = (user, roles) => {
    updateUsers([{ id: user.id, diff: { roles }}]);
  }

  return (
    <div className="preferences-wrapper">
      <h1>Administrative</h1>
      <Table config={generateConfig(_handleUpdate)} data={users} />
    </div>
  )
}

export default AdministrativePreferences;
