import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { BetaManagedAreasContextProvider, useBetaManagedAreasContext } from '../../hooks/betaHooks/beta-managed-areas-context';
import { BetaManagedAreaContextProvider, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import LocationValidation from '../Validation/LocationValidation';
import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import { ScreenTypes } from '../../hooks/betaHooks/beta-use-tree';
import TreePopup from '../../components/TreePopup';
import TreeSelector from '../../components/HeaderBar/TreeSelector';
import { QualityCheckedTreesContextProvider, useQualityCheckedTreesContext } from '../../hooks/betaHooks/quality-checked-trees-context';
import SegmentationValidation from '../Validation/SegmentationValidation';
import SemanticValidation from '../Validation/SemanticValidation';
import { QC_STATUSES } from '../../@types/enums/QualityCheckStatus';

// Note: if no 'statuses', 'id' is used as status.
const layerFilter = [
  {
    id: QC_STATUSES.QC_PENDING,
    source: 'trees',
    type: 'circle',
  },
  {
    id: QC_STATUSES.QC_ERROR,
    source: 'trees',
    type: 'circle',
  },
  {
    id: QC_STATUSES.QC_MODIFIED,
    source: 'trees',
    type: 'circle',
  },
  {
    id: QC_STATUSES.QC_APPROVED,
    source: 'trees',
    type: 'circle',
  },
  {
    id: 'null',
    source: 'trees',
    type: 'circle',
    statuses: [null], //! This is important because it's base value: "null" never gonna be equal to null
  },
];

const tableColumns = (pipeline, sortTrees, jumpToTree) => (selection) => {
  return selection
    ? [
        {
          title: 'ID',
          key: 'id',
          resolver: 'id',
          sortable: true,
          onSort: sortTrees,
          format: 'ESS_ID',
          clickNavigation: (entry) => jumpToTree(entry, pipeline),
        },
        {
          title: 'Status',
          resolver: 'tree_flow_status',
          key: 'tree_flow_status',
          format: 'tree_flow_status',
          sortable: true,
          onSort: sortTrees,
        },
        {
          title: 'Area code',
          key: 'managed_area',
          resolver: 'managed_area',
          format: 'CODE',
          sortable: true,
        },
        {
          title: 'QC Status',
          key: 'qc_status',
          resolver: 'qc_status',
          format: 'QC_STATUS',
          sortable: true,
        },
        {
          title: 'Issues',
          key: 'issues',
          resolver: 'qc_issues',
          format: 'QC_ISSUES',
          sortable: true,
        },
        {
          title: 'Actions',
          key: 'actions',
          resolver: 'qc_actions',
          format: 'QC_ACTIONS',
          sortable: false,
        },
      ]
    : [
        {
          title: 'ID',
          key: 'id',
          resolver: 'id',
          sortable: true,
          onSort: sortTrees,
          format: 'ESS_ID',
          clickNavigation: (entry) => jumpToTree(entry, pipeline),
        },
        {
          title: 'Status',
          key: 'status',
          resolver: (ma) => {
            if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error' || ma.segmentation === 'error') return 'ERROR';
            if (
              ma.location_proposal === 'todo' ||
              ma.semantic_extraction === 'todo' ||
              ma.segmentation === 'todo' ||
              ma.location_proposal === 'started' ||
              ma.semantic_extraction === 'started' ||
              ma.segmentation === 'started' ||
              ma.processing
            )
              return 'PROCESSING';
            return ma.current_manual_step || 'completed';
          },
          format: 'STATUS',
          sortable: true,
        },
        {
          title: 'Area code',
          key: 'code',
          resolver: 'code',
          format: 'CODE',
          sortable: true,
        },
        {
          title: 'All Trees',
          key: 'qc_trees',
          resolver: 'qc_trees',
          sortable: true,
        },
        {
          title: 'Pending',
          key: 'qc_todo_trees',
          resolver: 'qc_todo_trees',
          sortable: true,
        },
        {
          title: 'Approved',
          key: 'qc_approved_trees',
          resolver: 'qc_approved_trees',
          sortable: true,
        },
        {
          title: 'Modified',
          key: 'qc_modified_trees',
          resolver: 'qc_modified_trees',
          sortable: true,
        },
        {
          title: 'Error',
          key: 'qc_error_trees',
          resolver: 'qc_error_trees',
          sortable: true,
        },
      ];
};

const QualityCheckRouterComponent = ({ history, match }) => {
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  const qctCtx = useQualityCheckedTreesContext();
  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  useEffect(() => {
    qctCtx.fetchQualityCheckedTrees(managedAreaId);
  }, [managedAreaId]);

  useEffect(() => {
    betaMASCtx.reset();

    betaMASCtx.fetch({isQualityCheck: true});
  }, [managedAreaId]);

  useEffect(() => {
    betaMACtx.reset();

    if (!managedAreaId) return;
      const qcIssues = [];

      qctCtx.qualityCheckedTrees?.forEach((qct) => {
        qct?.issues?.forEach((qcIssue) => !qcIssues.includes(qcIssue) && qcIssues.push(qcIssue))
      });

      betaMACtx.setQualityCheckIssues(qcIssues);

      betaMACtx.fetch({isQualityCheck: true});
  }, [managedAreaId, qctCtx?.qualityCheckedTrees]);

  const mapRef = useRef(null);
  const [uploadQCTreesModalOpen, setUploadQCTreesModalOpen] = useState(false);
  const pipeline = betaMACtx?.pipeline;
  const managedArea = betaMACtx?.managedArea;

  const betaMASCtxLoading = betaMASCtx.loading;

  const betaMACtxLoading = betaMACtx.loading || betaMACtx.loadingManagedAreaAndPipeline || betaMACtx.loadingTrees;

  const contextsLoadingState = betaMASCtxLoading || betaMACtxLoading;

  const selection = useMemo(() => [managedArea, managedArea?.id], [managedArea?.id]);

  const layerFilters = useMemo(() => {
    return layerFilter.map((layer) => ({
      ...layer,
    }));
  }, []);

  const todoTreesFilter = (tree) => tree?.qc_status && tree.qc_status === QC_STATUSES.QC_PENDING;

  const treePopup = useCallback(
    ({ feature, ...props }) => {
      const clickedTree = betaMACtx?.trees?.find((tree) => Number(tree.id) === Number(feature.id));

      const treeData = {
        ...feature,
        properties: {
          ...feature.properties,
          manual_scientific_name: clickedTree?.manual_scientific_name,
          maven_tree_id: clickedTree?.tree_id,
          spsc_nm: clickedTree?.spsc_nm,
        },
      };
      return <TreePopup tree={treeData} {...props} />;
    },
    [betaMACtx?.trees]
  );

  const generateNavigationTabs = useCallback(
    (managedAreaId, managedAreaCode, treeId, onChangeTree) => {
      return [
        {
          type: 'link',
          path: `/quality_check`,
          active: true,
          onClick: () => history.push(`/quality_check`),
          icon: 'map',
          titleOnHover: 'Jump to the Organization',
        },
        {
          type: 'link',
          active: !!managedAreaId && !!managedAreaCode,
          onClick: () => history.push(`/quality_check/${managedAreaId}`),
          title: managedAreaCode,
          titleOnHover: 'Jump to the Managed Area',
        },
        {
          type: 'component',
          active: !!treeId,
          params: {
            treeId: treeId,
            onChangeTree,
          },
          component: TreeSelector,
        },
      ];
    },
    [history]
  );

  useEffect(() => {
    const storedManagedAreaId = localStorage.getItem('managedAreaId');

    if (storedManagedAreaId && managedArea?.id && storedManagedAreaId.toString() !== managedArea.id.toString()) {
      localStorage.removeItem('skippedTrees');
    }
  }, [managedArea?.id]);

  const jumpToTree = (tree) => {
    history.push((window.location.pathname.split('/').slice(0, 4).join('/') + `/semantics/` + tree.id).replace('//', '/'));
  };

  return (
    <SkeletonWithManagedAreaMap
      basePath='quality_check'
      title='Quality Check'
      screens={[
        {
          title: `${managedArea?.code} | Segmentation | Validation`,
          path: '/quality_check/:MA/segmentation/:tree?',
          Component: SegmentationValidation,
        },
        {
          title: `${managedArea?.code} | Semantics | Validation`,
          path: '/quality_check/:MA/semantics/:tree?',
          Component: SemanticValidation,
        },
        {
          title: `${managedArea?.code} | Location Validation | Validation`,
          path: '/quality_check/:MA/location/:tree?',
          Component: LocationValidation,
        },
      ]}
      mapRef={mapRef}
      layerFilter={layerFilters}
      treePopup={treePopup}
      customColumns={tableColumns(pipeline, () => void 0, jumpToTree)}
      screenType={ScreenTypes.QUALITY_CHECK}
      selection={selection}
      contextsLoadingState={contextsLoadingState}
      isMicroClimate={false}
      todoTreesFilter={todoTreesFilter}
      generateNavigationTabs={generateNavigationTabs}
      isQualityCheck={true}
      uploadQCTreesModalOpen={uploadQCTreesModalOpen}
      setUploadQCTreesModalOpen={setUploadQCTreesModalOpen}
      refetchQualityCheckTrees={qctCtx.fetchQualityCheckedTrees}
    />
  );
};

const QualityCheckRouter = (props) => {
  const match = useRouteMatch();
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  return (
    <QualityCheckedTreesContextProvider>
      <BetaManagedAreasContextProvider>
        <BetaManagedAreaContextProvider managedAreaId={managedAreaId}>
          <QualityCheckRouterComponent {...props} />
        </BetaManagedAreaContextProvider>
      </BetaManagedAreasContextProvider>
    </QualityCheckedTreesContextProvider>
  );
};

export default QualityCheckRouter;
