import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip, useTheme as useMuiTheme } from '@mui/material';
import * as _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Vector3 } from 'three';
import { CardProps } from '../../../../@types/CardProps';
import ValidationAction from '../../../../@types/enums/ValidationAction';
import { useSemanticScreenContext } from '../../../../hooks/betaHooks/semantic-screen-context';
import { radiusFromCircumference } from '../../../../utils/mathUtils';
import AttributeCard from '../../../Card/AttributeCard';
import Icon from '../../../Icon';
import { PlainNumberInput } from '../../../inputs/PlainNumberInput';
import { calculateGirth } from '../../ValidationUtils';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './Constants';
import { TOAST_TYPE } from '../../../../@types/Toast';
import { Girth } from '../../../../@types/Girth';

const TrunkDetailsCard = ({ disabled }: CardProps): JSX.Element => {
  const { treeMetrics, trunkActions, visibility, cards, validation, actions, helper, section } = useSemanticScreenContext();
  const isToolActive = useMemo(() => helper.activeTool === ValidationAction.Girth, [helper.activeTool]);

  // const isAddGirthActionValid =  trunkActions.largestTrunk.girths.length < MAX_GIRTH_PER_TRUNK;

  const muiTheme = useMuiTheme();

  const updateGirthRadius = (axis: 'rX' | 'rY', radius: number) => {
    trunkActions.setShownGirth((prev: any) => ({ ...prev, [axis]: radius }));
    trunkActions.updateGirth({ ...trunkActions.shownGirth, [axis]: radius });
  };

  /* Removed on PRD-654 */
  /* const [selectedTrunk, setSelectedTrunk] = useState<TrunkWithGirths | null>(trunkActions.largestTrunk || defaultTrunk || null); */

  /* Removed on PRD-515  */
  // const selectedTrunkIndex = useMemo(() => treeMetrics.trunks.findIndex((t) => t.local_id === selectedTrunk?.local_id), [selectedTrunk, treeMetrics.trunks]);

  const alert = cards.alerts.find((a) => a.name === METRICS_NAMES.NUMBER_OF_STEMS);

  useEffect(() => {
    const isNumberOfStemsIsInRange = _.inRange(
      Number(treeMetrics.numberOfStems),
      METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].max
    );
    if (!isNumberOfStemsIsInRange) {
      const alreadyAlerted = cards.alerts.find((a) => a.name === METRICS_NAMES.NUMBER_OF_STEMS);

      if (!alreadyAlerted?.name) {
        cards.setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.NUMBER_OF_STEMS,
            message: `Number of stems should be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].min} and ${METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].max
              } ${METRICS_VALUE_LIMITS[METRICS_NAMES.NUMBER_OF_STEMS].unit}`,
          },
        ]);
      }
    } else {
      cards.setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.NUMBER_OF_STEMS));
    }
  }, [treeMetrics.numberOfStems]);

  /* Removed on PRD-515  */
  /*   const isTrunkAdditionDisabled = treeMetrics.trunks.length >= MAX_TRUNKS || helper.isEditingDisabled;
  const isTrunkRemovalDisabled = !selectedTrunk || !treeMetrics.trunks.length || helper.isEditingDisabled; */

  const onToolActivation = () => {
    if (helper.activeTool === ValidationAction.Girth) return;

    const { x, y, z } = helper.viewerPosition;
    const sectionHeight = trunkActions.shownGirth?.height || z + 1

    section.setDefaultDepth();
    actions.setActiveTool(ValidationAction.Girth);
    section.setTarget(new Vector3(x, y, sectionHeight));
    section.setNormal(new Vector3(0, 0, 1));
  };

  const removeGirthValue = () => {
    if (!trunkActions.isRemoveGirthActionValid || helper.isEditingDisabled) return;
    trunkActions.removeGirth(trunkActions.largestTrunk.local_id, trunkActions.shownGirth);
  };

  const editGirth = (event: any, girth: Girth) => {
    if (girth === trunkActions.shownGirth) event.stopPropagation();
  };

  return (
    <AttributeCard
      title='Trunk details'
      color='#DB8100'
      setCompleted={validation.setIsTrunkDetailsCompleted}
      isExpanded={visibility.girth}
      onExpand={visibility.setGirth}
      completed={validation.isTrunkDetailsCompleted}
      disabled={helper.isEditingDisabled}
      isActive={isToolActive}
      setIsActive={onToolActivation}
    >
      <div className='trunk-details-card-body-wrapper'>
        {/* Removed on PRD-515  */}
        {/*         <div className='heading'>
          <p>Trunks</p>
          <div className='actions-wrapper'>
            <i className={isTrunkAdditionDisabled ? 'disabled' : ''} onClick={addTrunk}>
              <Icon
                icon='plusWithCircle'
                style={{ color: isTrunkAdditionDisabled ? muiTheme.palette.text.disabled : muiTheme.palette.text.primary }}
              />
            </i>

            <i className={isTrunkRemovalDisabled ? 'disabled' : ''} onClick={removeTrunk}>
              <Icon
                icon='minusWithCircle'
                style={{ color: isTrunkRemovalDisabled ? muiTheme.palette.text.disabled : muiTheme.palette.text.primary }}
              />
            </i>
          </div>
        </div>
        <ul className='stem-list'>
          {treeMetrics.trunks.map((trunk, i) => (
            <li
              key={trunk.id + 'stem' + i}
              className={`stem-list-item ${selectedTrunkIndex === i ? 'selected' : ''}`}
              onClick={() => onTrunkClick(trunk)}
              style={{
                outline: selectedTrunkIndex === i ? '1px solid ' + muiTheme.palette.primary.main : 'none',
              }}
            >
              <p> Stem {i + 1}</p>
            </li>
          ))}
        </ul> */}
        {trunkActions.largestTrunk && (
          <>
            <div className='heading'>
              <p>Girths</p>
              <div className='actions-wrapper'>
                {/* <i className={isAddGirthActionValid ? '' : 'disabled'} onClick={addGirthValue}>
                  <Icon
                    icon='plusWithCircle'
                    style={{ color: isAddGirthActionValid ? muiTheme.palette.text.primary : muiTheme.palette.text.disabled }}
                  />
                </i> */}

                <i className={trunkActions.isRemoveGirthActionValid ? '' : 'disabled'} onClick={removeGirthValue}>
                  <Icon
                    icon='minusWithCircle'
                    style={{ color: trunkActions.isRemoveGirthActionValid ? muiTheme.palette.text.primary : muiTheme.palette.text.disabled }}
                  />
                </i>
              </div>
            </div>
            <ul className='girth-list'>
              {trunkActions.largestTrunk?.girths?.map((girth: Girth, i: number) => {
                const calculatedGirth = parseFloat(calculateGirth(girth).toFixed(2));

                return (
                  <li
                    key={'girth-' + i}
                    className={`girth-list-item ${isToolActive && 'selected'}`}
                    style={{ cursor: 'default' }}
                  // onClick={() => onGirthClick(girth)} // only the largest is shown to the user, no need for selection
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '500px' }}>
                      <p>Stem {girth ? girth.height.toFixed(2) + ' ' : '??? '}m:</p>
                      {girth && (
                        <span className='number-input' onClick={(event) => editGirth(event, girth)}>
                          <PlainNumberInput
                            value={calculatedGirth}
                            step={0.1}
                            onChange={(value: number) => {
                              if (helper.isEditingDisabled && trunkActions.shownGirth?.local_id === girth.local_id) return;

                              const radius = Math.max(radiusFromCircumference(value), 0.01);
                              trunkActions.updateGirth({
                                rX: radius,
                                rY: radius,
                                diameter: value,
                                height: girth.height,
                                local_id: girth.local_id,
                                normal: girth.normal,
                                dX: girth.dX,
                                dY: girth.dY,
                                rotation: girth.rotation,
                              });
                              updateGirthRadius('rX', radius);
                              updateGirthRadius('rY', radius);
                            }}
                            min={0.02}
                          />
                          <span>{' m'}</span>
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        <div className='number-of-stems-container' onClick={(event) => event.stopPropagation()}>
          <div className='input-row'>
            <p>Number of stems (qty):</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
            <span className='number-input'>
              <PlainNumberInput
                value={treeMetrics.numberOfStems || 0}
                step={1}
                onChange={(value) => {
                  if (disabled || !treeMetrics.setNumberOfStems) return;

                  if (value < 0 || value > 15) {
                    cards.addToast('Value cannot be lower than 1 and higher than 15', TOAST_TYPE.ERROR);
                    return;
                  }

                  treeMetrics.setNumberOfStems(value);
                }}
                min={Number(0)}
                max={Number(15)}
              />
            </span>
          </div>
        </div>
      </div>
    </AttributeCard>
  );
};
export default TrunkDetailsCard;
