import { ButtonParams } from '../../@types/Button';
import Icon from '../Icon';

const Button = ({
  label,
  primary,
  destructive,
  onClick,
  disabled,
  leadingIcon,
  icon,
  id,
  reference,
  style,
  iconStyle,
  classNames,
}: ButtonParams) => {
  return (
    <button
      ref={reference || null}
      id={id || undefined}
      type={primary ? 'submit' : 'button'}
      className={`button-wrapper regular-button-states ${primary ? 'primary' : ''} ${destructive ? 'destructive' : ''} ${disabled ? 'disabled' : ''
        } ${classNames || ''}`}
      onClick={disabled ? () => false : onClick}
      style={style}
    >
      {leadingIcon && <Icon icon={leadingIcon} style={iconStyle} />}
      <span className='label'>{label}</span>
      {icon && <Icon icon={icon} style={iconStyle} />}
    </button>
  );
};

export default Button;
