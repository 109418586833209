import { VerticalTabs } from '../components';
import { withRouter } from 'react-router-dom';
import HeaderBar from '../components/HeaderBar/HeaderBar';

const Skeleton = ({ children, tabs = [], header = {} }) => {
  return (
    <div className={`skeleton-outer-wrapper new-header`}>
      <HeaderBar {...header} />
      <div className={`skeleton-wrapper ${!header.navTabs?.length && tabs.length ? 'tabs-visible' : 'tabs-hidden'}`}>
        {tabs?.length > 0 && <VerticalTabs tabs={tabs} />}
        <div className={`skeleton-content ${header.navTabs?.length ? 'full-height' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Skeleton);
