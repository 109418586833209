enum TreeStatus {
  LocationValidationTodo = 'location_validation_todo',
  LocationValidationDone = 'location_validation_done',
  LocationValidationDeleted = 'location_validation_deleted',
  LocationValidationSentToField = 'location_validation_sent_to_field',
  SemanticValidationTodo = 'semantic_validation_todo',
  SemanticValidationDone = 'semantic_validation_done',
  SemanticValidationDeleted = 'semantic_validation_deleted',
  SemanticValidationSentToField = 'semantic_validation_sent_to_field',
  SegmentationValidationDone = 'segmentation_validation_done',
  SegmentationValidationTodo = 'segmentation_validation_todo',
  SegmentationValidationDeleted = 'segmentation_validation_deleted',
  Completed = 'completed',
  Deleted = 'deleted',
  SentToField = 'sent_to_field',
}

export default TreeStatus;
