import { memo } from 'react';
import { ColumnType } from '../../@types/Table';
import formatter from './helpers';

type CellPropsType = {
  entry: any;
  format?: string;
  column: ColumnType;
  label?: string;
  resolver?: string | (() => null);
  updateTree?: any;
  subTitle?: boolean;
  subTitleAction?: (exportCsvCallback: any) => void;
  selected?: boolean;
  setQualityCheckStatus?: (id: string, qc_status: string) => void,
  sendToOfflineAnnotation?: (id: string) => void,
};

const TableCell = memo(({ entry, resolver, format, column, label, updateTree, selected, setQualityCheckStatus, sendToOfflineAnnotation }: CellPropsType) => {
  const value = Object.byResolver(entry, resolver);
  return <td>{formatter(column, format, value, label, entry, updateTree, selected, setQualityCheckStatus, sendToOfflineAnnotation)}</td>;
});

export default TableCell;
