import { Vector3 } from 'three';
import ValidationAction from '../../../@types/enums/ValidationAction';
import getTrunkLeaningDegree from '../../../utils/getTrunkLeaningDegree';
import AttributeCard from '../../Card/AttributeCard';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';
import { useEffect } from 'react';
import * as _ from 'lodash';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import { CardProps } from '../../../@types/CardProps';

const FirstBifurcationMidpointCard = ({ currentTree }: CardProps) => {
  const { treeMetrics, visibility, cards, validation, actions, helper, section } = useSemanticScreenContext();

  const leanStartVector = new Vector3();
  const locationLocal = currentTree?.location_local ? new Vector3(...currentTree?.location_local) : new Vector3();
  const leanDegree = leanStartVector ? getTrunkLeaningDegree(leanStartVector, treeMetrics.firstBifurcationPoint.clone().sub(locationLocal)) : null;
  const localHeight = currentTree?.location_local ? currentTree.location_local[2] : 0;

  const alert = cards.alerts.find((a) => a.name === METRICS_NAMES.LEANING_ANGLE);

  const setActiveToolTo = () => {
    if (helper.activeTool === ValidationAction.FirstBifurcationPoint) return;

    const correctedHeight = treeMetrics.firstBifurcationPoint.z - helper.viewerPosition.z - localHeight;
    const correctedTarget = new Vector3(0,0,correctedHeight);

    section.setDefaultDepth();
    section.setTarget(correctedTarget);
    section.setNormal(new Vector3(0, 0, 1));
    actions.setActiveTool(ValidationAction.FirstBifurcationPoint);
  };

  useEffect(() => {
    const isLeaningAngleInRange = _.inRange(
      Number(leanDegree),
      METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].max
    );
    if (!isLeaningAngleInRange) {
      const alreadyAlerted = cards.alerts.find((a) => a.name === METRICS_NAMES.LEANING_ANGLE);
      
      if (!alreadyAlerted?.name) {
        cards.setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.LEANING_ANGLE,
            message: `Leaning Angle must be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].min} and ${
              METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].max
            } ${METRICS_VALUE_LIMITS[METRICS_NAMES.LEANING_ANGLE].unit}`,
          },
        ]);
      }
    } else {
      cards.setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.LEANING_ANGLE));
    }
  }, [leanDegree]);

  return (
    <AttributeCard
      title='First Bifurcation: Midpoint'
      color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
      isExpanded={visibility.firstBifurcationPoint}
      onExpand={visibility.setFirstBifurcationPoint}
      setCompleted={validation.setIsFirstBifurcationMidPointCompleted}
      disabled={helper.isEditingDisabled}
      completed={validation.isFirstBifurcationMidPointCompleted}
      isActive={helper.activeTool === ValidationAction.FirstBifurcationPoint}
      setIsActive={setActiveToolTo}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Tree’s leaning angle</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <p>{leanDegree ?? '???'} °</p>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default FirstBifurcationMidpointCard;
