import React from 'react';
import Icon from './Icon';
const MobileOverlay = () => {
  return (
    <div className="mobile-overlay">
      <Icon icon='rtms' />
      <h1>Mobile devices not supported yet.</h1>
      <h2>Please open the system on a desktop computer, or on a tablet.</h2>
    </div>
  )
}

export default MobileOverlay;