import { useCallback } from 'react';
import Tree from '../../@types/Tree';

export interface BetaTreeIndexValue {
  selectTree: (treeId: string) => void;
  moveToNeighbour: (direction?: number) => void;
  getNextTree: (direction?: number) => Partial<Tree> | null;
}

export interface UseBetaTreeIndexProps {
  trees: Partial<Tree>[];
  todoTreesFilter: (tree: Partial<Tree>) => boolean;
  skippedTrees?: string[];
  currentTreeId: string;
  setTree: React.Dispatch<React.SetStateAction<Partial<Tree> | null>>;
}

export const useBetaTreeIndex = ({
  trees,
  todoTreesFilter,
  currentTreeId,
  setTree,
  skippedTrees,
}: UseBetaTreeIndexProps): BetaTreeIndexValue => {

  const selectTree = useCallback((treeId: string) => {
    if (!trees) return;

    const index = trees.findIndex((tree) => Number(tree.id) === Number(treeId));
    if (index > -1) {
      setTree(trees[index]);
    }
  }, [setTree, trees]);

  const getNextTree = useCallback((direction = 1): Partial<Tree> | null => {
    if (!trees) return null;

    const currentTreeIndex = trees.findIndex((tree) => tree.id === currentTreeId);
    const startIndex = currentTreeIndex !== -1 ? currentTreeIndex : 0;
    let nextIndex = startIndex;
    do {
      nextIndex = (nextIndex + direction + trees.length) % trees.length;
    }
    while (
      (!todoTreesFilter(trees[nextIndex]) || (direction === 1 && skippedTrees?.includes(trees[nextIndex].id!))) &&
      nextIndex !== startIndex
    );

    return trees[nextIndex];
  }, [currentTreeId, skippedTrees, trees, todoTreesFilter]);

  const moveToNeighbour = useCallback((direction = 1) => {
    const nextTree = getNextTree(direction);
    if (nextTree) {
      setTree(nextTree);
    }
  }, [getNextTree, setTree]);

  return {
    selectTree,
    moveToNeighbour,
    getNextTree,
  };
};
