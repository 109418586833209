import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useUser } from '../../providers/user';
import Tree from '../../@types/Tree';
import { handleRequest } from '../api';
import Toast, { ToastType } from '../../@types/Toast';

export interface QualityCheckedTreesContextValue {
  qualityCheckedTrees: Tree[];
  toasts: Toast[];

  fetchQualityCheckedTrees: (managedAreaId?: string) => void;
  showToast: (message: string, type: ToastType) => void;
}

export interface QualityCheckedTreesContextProps extends PropsWithChildren {
  //
}

export const QualityCheckedTreesContext = createContext<QualityCheckedTreesContextValue>(null as any);

export const useQualityCheckedTreesContext = () => useContext(QualityCheckedTreesContext);

export const QualityCheckedTreesContextProvider = (props: QualityCheckedTreesContextProps) => {
  const { token, rtmsToken } = useUser() as any;

  const [qualityCheckedTrees, setQualityCheckedTrees] = useState<QualityCheckedTreesContextValue['qualityCheckedTrees']>([]);
  const [toasts, setToasts] = useState<Toast[]>([]);


  const fetchQualityCheckedTrees = useCallback(async (managedAreaId?: string) => {
    try {
      const response = await handleRequest(token, rtmsToken)(`/v2/qc-trees${managedAreaId ? `?managedAreaId=${managedAreaId}` : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      setQualityCheckedTrees(data);
    } catch (e) {
      console.error('Failed to fetch quality checked trees', e);
    }
  }, [token, rtmsToken]);

  const showToast = (message: string, type: ToastType) => {
    setToasts((toasts: Toast[]) => [...toasts, { message, type }]);
    setTimeout(() => setToasts((toasts: Toast[]) => toasts.slice(1)), 3000);
  };

  const value: QualityCheckedTreesContextValue = {
    qualityCheckedTrees,
    toasts,

    fetchQualityCheckedTrees,
    showToast,
  };

  return <QualityCheckedTreesContext.Provider value={value}>{props.children}</QualityCheckedTreesContext.Provider>;
};
