import { Checkbox, FormControlLabel } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import {
  BetaManagedAreasContext,
  FILTERABLE_FIELDS,
  FilterableFields,
  MANAGED_AREA_STATUSES,
  ManagedAreaStatuses,
} from '../../hooks/betaHooks/beta-managed-areas-context';
import { Button } from '../inputs';
import { CustomSelect as StatusSelect } from '../inputs/Select';
import { CustomMultiSelect as QCIssuesSelect, CustomMultiSelect as QCStatusesSelect } from '../inputs/MultiSelect';
import { ScreenTypes } from '../../hooks/betaHooks/beta-use-tree';
import * as _ from 'lodash';
import QCStatuses, { QC_STATUSES } from '../../@types/enums/QualityCheckStatus';
import { BetaManagedAreaContext, QUALITY_CHECK_FILTERS, QualityCheckFilters } from '../../hooks/betaHooks/beta-managed-area-context';

export enum AvailableFilters {
  MANAGED_AREA_STATUS = 'managed_areas_status',
  HAS_PENDING_TREES = 'has_pending_trees',
  ISSUES = 'issues',
  QC_STATUS = 'qc_status',
}

export interface DropDownOption {
  id: string;
  label: string;
  value: string | ManagedAreaStatuses | QCStatuses | 'all' | 'reset';
}

interface Props {
  tableFiltersIsOpen: boolean;
  setTableFiltersIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  screenType: ScreenTypes;
  managedAreaId?: string;
}

export const TableFilters = ({ tableFiltersIsOpen, setTableFiltersIsOpen, screenType, managedAreaId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [qcIssuesSelectIsOpen, setQCIssuesSelectIsOpen] = useState(false);
  const [qcStatusesSelectIsOpen, setQCStatusesSelectIsOpen] = useState(false);
  const { updateFilters: updateManagedAreaFilters, maFilters } = useContext(BetaManagedAreasContext);
  const { updateQualityCheckFilters, qualityCheckFilters, qualityCheckIssues } = useContext(BetaManagedAreaContext);

  const qcIssueFirstOption = useMemo(() => {
    if (!qualityCheckFilters.qc_issues?.length) {
      return {
        id: 'ALL_ISSUES',
        label: 'All Issues',
        value: 'all',
      };
    }

    return {
      id: 'RESET_ISSUES',
      label: 'Reset Selection',
      value: 'reset',
    };
  }, [qualityCheckFilters.qc_issues]);

  const qcStatusFirstOption = useMemo(() => {
    if (!qualityCheckFilters.qc_statuses?.length) {
      return {
        id: 'ALL_STATUSES',
        label: 'All Statuses',
        value: 'all',
      };
    }

    return {
      id: 'RESET_STATUSES',
      label: 'Reset Selection',
      value: 'reset',
    };
  }, [qualityCheckFilters.qc_statuses]);

  const handleSelect = (value: ManagedAreaStatuses) => {
    const newFilter = {
      [FILTERABLE_FIELDS.STATUS]: value,
    } as Record<FilterableFields, ManagedAreaStatuses>;

    updateManagedAreaFilters(newFilter);
  };

  const handlePendingFilterChange = () => {
    const newFilter = {
      [FILTERABLE_FIELDS.PENDING_TREES]: !maFilters[FILTERABLE_FIELDS.PENDING_TREES],
    } as Record<FilterableFields, boolean>;

    updateManagedAreaFilters(newFilter);
  };

  const handleQCIssueSelect = (value: string | QCStatuses | 'all' | 'reset') => {
    const newValue: (string | QCStatuses | 'all' | 'reset')[] = (
      value.split(',') as unknown as (string | QCStatuses | 'all' | 'reset')[]
    ).filter((issue) => issue !== 'all');

    let newFilter: Record<QualityCheckFilters, (string | QCStatuses | 'all' | 'reset')[]>;

    if (newValue.includes('reset') || !value) {
      newFilter = { ...qualityCheckFilters, [QUALITY_CHECK_FILTERS.QC_ISSUES]: [] };
    } else {
      newFilter = { ...qualityCheckFilters, [QUALITY_CHECK_FILTERS.QC_ISSUES]: newValue };
    }

    updateQualityCheckFilters(newFilter);
  };

  const handleQCStatusSelect = (value: string | QCStatuses | 'all' | 'reset') => {
    const newValue: (string | QCStatuses | 'all' | 'reset')[] = (
      value.split(',') as unknown as (string | QCStatuses | 'all' | 'reset')[]
    ).filter((issue) => issue !== 'all');

    let newFilter: Record<QualityCheckFilters, (string | QCStatuses | 'all' | 'reset')[]>;

    if (newValue.includes('reset') || !value) {
      newFilter = { ...qualityCheckFilters, [QUALITY_CHECK_FILTERS.QC_STATUSES]: [] };
    } else {
      newFilter = { ...qualityCheckFilters, [QUALITY_CHECK_FILTERS.QC_STATUSES]: newValue };
    }

    updateQualityCheckFilters(newFilter);
  };

  const activeItem = maFilters[FILTERABLE_FIELDS.STATUS] || MANAGED_AREA_STATUSES.default;

  const qcIssuesSelectActiveItem: (string | QCStatuses | 'all' | 'reset')[] = useMemo(() => {
    if (!qualityCheckFilters.qc_issues?.length || qualityCheckFilters.qc_issues.includes('reset')) return ['all'];

    const qc_issues = qualityCheckFilters.qc_issues.filter((issue) => issue !== qcIssueFirstOption.value);

    return qc_issues;
  }, [qualityCheckFilters.qc_issues, qcIssueFirstOption.value]);

  const qcStatusesSelectActiveItem: (string | QCStatuses | 'all' | 'reset')[] = useMemo(() => {
    if (!qualityCheckFilters.qc_statuses?.length || qualityCheckFilters.qc_statuses.includes('reset')) return ['all'];

    const qc_statuses = qualityCheckFilters.qc_statuses.filter((issue) => issue !== qcStatusFirstOption.value);

    return qc_statuses;
  }, [qualityCheckFilters.qc_statuses, qcStatusFirstOption.value]);

  const options: DropDownOption[] = [
    {
      id: 'all',
      label: 'All Status',
      value: MANAGED_AREA_STATUSES.default,
    },
    {
      id: 'loc_val',
      label: 'Location Validation',
      value: MANAGED_AREA_STATUSES.LOCATION_VALIDATION,
    },
    {
      id: 'seg_val',
      label: 'Segmentation Validation',
      value: MANAGED_AREA_STATUSES.SEGMENTATION_VALIDATION,
    },
    {
      id: 'sem_val',
      label: 'Semantic Validation',
      value: MANAGED_AREA_STATUSES.SEMANTIC_VALIDATION,
    },
  ];

  const qcIssueOptions: DropDownOption[] = useMemo(() => {
    const defaultArray: DropDownOption[] = qualityCheckIssues.map((entry) => {
      const id = entry.toUpperCase();
      const label = entry.replaceAll('_', ' ');
      const value = entry;

      return {
        id,
        label,
        value,
      };
    });

    defaultArray.unshift(qcIssueFirstOption as DropDownOption);

    return defaultArray;
  }, [qualityCheckIssues, qcIssueFirstOption]);

  const qcStatusOptions: DropDownOption[] = useMemo(() => {
    const defaultArray: DropDownOption[] = Object.entries(QC_STATUSES).map((entry) => {
      const id = entry[0];
      const label = (entry[1] as string).replaceAll('_', ' ');
      const value = entry[1];

      return {
        id,
        label,
        value,
      };
    });

    if (!qualityCheckFilters.qc_statuses?.length) {
      defaultArray.unshift({
        id: 'ALL_STATUSES',
        label: 'All Statuses',
        value: 'all',
      });
    } else {
      defaultArray.unshift({
        id: 'RESET_STATUSES',
        label: 'Reset Selection',
        value: 'reset',
      });
    }

    return defaultArray;
  }, [qualityCheckFilters.qc_statuses]);

  if (screenType !== ScreenTypes.VALIDATION && screenType !== ScreenTypes.QUALITY_CHECK) return null;

  if (!tableFiltersIsOpen) {
    return (
      <span>
        <Button label='Table Filters' primary onClick={() => setTableFiltersIsOpen(true)} />
      </span>
    );
  }

  return (
    <div className='filter-container'>
      {!managedAreaId && (
        <StatusSelect
          label='MA Status'
          options={options}
          value={activeItem}
          onChange={handleSelect}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      {managedAreaId && screenType === ScreenTypes.QUALITY_CHECK && (
        <QCIssuesSelect
          label='QC Issues'
          options={qcIssueOptions}
          value={qcIssuesSelectActiveItem}
          onChange={handleQCIssueSelect}
          isOpen={qcIssuesSelectIsOpen}
          setIsOpen={setQCIssuesSelectIsOpen}
        />
      )}
      {managedAreaId && screenType === ScreenTypes.QUALITY_CHECK && (
        <QCStatusesSelect
          label='QC Statuses'
          options={qcStatusOptions}
          value={qcStatusesSelectActiveItem}
          onChange={handleQCStatusSelect}
          isOpen={qcStatusesSelectIsOpen}
          setIsOpen={setQCStatusesSelectIsOpen}
        />
      )}
      {!managedAreaId && (
        <FormControlLabel
          label='Has pending trees'
          className='filter-checkbox'
          control={
            <Checkbox
              onChange={handlePendingFilterChange}
              title='Has trees pending'
              checked={!!maFilters[FILTERABLE_FIELDS.PENDING_TREES]}
            />
          }
        />
      )}
      <Button label='Close' primary icon='fa-times' onClick={() => setTableFiltersIsOpen(false)} />
    </div>
  );
};
