import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Finished, ProgressControls, SegmentationEditor } from '../../components';
import appConfig from '../../config';
import { validationActions } from '../../core/progressActions';
import { useModal } from '../../providers/modal';
import { useHistory, useParams } from 'react-router-dom';
import { LoaderWrapper } from '../../layout';
import { CommentTypes, useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { BetaManagedAreaContext, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { SegmentationScreenContextProvider, useSegmentationScreenContext } from '../../hooks/betaHooks/segmentation-screen-context';
import { useBetaManagedAreasContext } from '../../hooks/betaHooks/beta-managed-areas-context';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import { QC_STATUSES } from '../../@types/enums/QualityCheckStatus';
import TreeStatus from '../../@types/enums/TreeStatus';
import ValidationTool from '../../@types/enums/ValidationTool';

const generateUrl = (id) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

export const RECLASS_TOOLS = {
  BRUSH: 'BRUSH',
  POLYGON: 'POLYGON',
};

export const ERROR_TOOLS = {
  POINT: 'POINT',
  LINE: 'LINE',
  POLYGON: 'POLYGON',
};

const SegmentationValidation = ({
  trees,
  todoTrees,
  currentTree,
  selection,
  onTreeAdd,
  managedArea,
  layerSources,
  selectTree,
  moveToNeighbour,
  presentModal,
  dismissModal,
  betaTree,
  isQualityCheck,
  refetchQualityCheckTrees,
  sourceVisible,
  mapLayers,
  filterLayers,
  layerVisible,
}) => {
  const {
    treeMetrics,
    actions,
    helper,
    reclass,
    pointCloudModule: {
      originalPointCloud: originalLas,
      setOriginalPointCloud: setOriginalLas,
      pointCloud: las,
      setPointCloud: setLas,
      environmentPointCloud: environmentLas,
      isEnvironmentNotFound,
      environmentPointCloudLoading,
      isEnvironmentVisible,
      setIsEnvironmentVisible,
    },
    section: { setDefaultDepth },
  } = useSegmentationScreenContext();

  const betaManagedAreaContext = useBetaManagedAreaContext();
  const betaManagedAreasContext = useBetaManagedAreasContext();

  const [rotationHandler, setRotationHandler] = useState(null);
  const init = useRef(false);

  const history = useHistory();

  const _handleTSEJob = () => {
    betaManagedAreaContext.startPostValidation(selection[0], 'segmentation_validation');
  };

  useEffect(() => {
    if (!currentTree) return;

    setDefaultDepth();

    betaTree.setTree({
      ...currentTree,
      location: JSON.parse(currentTree?.location),
    });
  }, [betaTree?.setTree, currentTree?.id]);

  useEffect(() => {
    if (!selection[0]?.code) return;
    if (currentTree?.id && !init.current) init.current = true;
    else if (currentTree?.id && init.current) window.history.pushState(null, null, generateUrl(currentTree?.id));

    reclass.setActionMode(ValidationTool.Inspection_View);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.id, selection[0]?.code]);

  const updateGeometry = ({ geometry, changes }) => {
    if (!changes.indices.length) return;

    let shouldUpdateGeometry = true;
    reclass.setReclassStates((curr) => {
      curr.push({ state: geometry, changes });

      const changeLimitReached = curr.length >= appConfig.maxUndo;
      const overChangeLimit = curr.length > appConfig.maxUndo;
      if (changeLimitReached) {
        helper.showToast(`Save changes before adding more!`, 'error');
        if (overChangeLimit) {
          curr.pop();
          shouldUpdateGeometry = false;
        }

        return curr;
      }

      const showPointCloudChangeCountBeforeLimitValue = appConfig.maxUndo - 3;
      const closeToChangeLimit = curr.length >= showPointCloudChangeCountBeforeLimitValue;
      if (closeToChangeLimit) {
        helper.showToast(`Changes left before save: ${appConfig.maxUndo - curr.length}`, 'warning');
      }

      if (curr) return curr;
    });

    if (!shouldUpdateGeometry) return;

    setLas({ ...las, geometry, pc: { ...las.pc, geometry } });
  };

  const changeActionMode = (newMode, isSemantics) => {
    const mode = newMode;

    resetGeometry();

    reclass.setActionMode((current) =>
      !isSemantics && (!ValidationTool[mode] || current === ValidationTool[mode]) ? ValidationTool.Inspection_View : ValidationTool[mode]
    );
  };

  const resetGeometry = () => {
    reclass.setReclassPoints([]);
    reclass.setReclassStates([]);
    setLas(originalLas);
  };

  const _handleSaveLAZ = async () => {
    const payload = {
      lastUrl: currentTree.tree_segmentation_laz,
      blobName: currentTree.tree_segmentation_laz,
      changes: reclass.reclassStates.map((rcs) => rcs.changes),
      treeId: currentTree?.id,
    };

    reclass.setReclassSaveInProgress(true);
    try {
      await betaTree.saveLaz(payload);
      reclass.setReclassPoints([]);
      reclass.setReclassStates([]);
      setOriginalLas(las);
      helper.showToast('LAZ file saved successfully!');
    } catch (err) {
      helper.showToast('Failed to save LAZ file!', 'error');
      console.error('Error during LAZ update:', err);
    }
    reclass.setReclassSaveInProgress(false);
  };

  const handleCompletedOnlineAnnotatedTrees = useCallback(async () => {
    if (todoTrees.length > 0) return null;

    trees.forEach(async (t) => {
      if (t.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationDone) {
        await betaTree.updateTreeWithId(t.id, { tree_flow_status: TreeFlowStatus.SegmentationValidationDone }, false);
      }
    });
  }, [todoTrees.length, trees, betaTree]);

  const environmentToggleProps = useMemo(() => {
    return {
      label: environmentPointCloudLoading ? 'Loading environment' : 'Show environment',
      value: isEnvironmentVisible,
      onPureChange: () => setIsEnvironmentVisible((prev) => !prev),
      disabled: environmentPointCloudLoading || !!isEnvironmentNotFound,
    };
  }, [environmentLas?.pointcloud, isEnvironmentNotFound, isEnvironmentVisible, environmentPointCloudLoading, currentTree]);

  if (!managedArea?.id) return null;

  return (
    <LoaderWrapper loading={!currentTree?.id || helper.isLoading}>
      <Finished
        finished={todoTrees.length === 0 && !isQualityCheck}
        onStart={_handleTSEJob}
        managedAreaId={managedArea.id}
        reloadManagedAreas={betaManagedAreasContext.fetch}
        handleCompletedOnlineAnnotatedTrees={handleCompletedOnlineAnnotatedTrees}
      >
        <div className='semantics-editor-wrapper'>
          <div className='viewers'>
            <SegmentationEditor
              currentTree={currentTree}
              selection={selection}
              onTreeAdd={onTreeAdd}
              // Not needed props
              environmentToggleProps={environmentToggleProps} // this is used in index.js, calculate/memoize data there
              loading={las === null} // this check can be done from the store
              setRotationHandler={setRotationHandler}
              saveLAZ={_handleSaveLAZ}
              updateGeometry={updateGeometry}
              selectTree={selectTree}
              layerSources={layerSources}
              isQualityCheck={isQualityCheck}
              sourceVisible={sourceVisible}
              mapLayers={mapLayers}
              filterLayers={filterLayers}
              layerVisible={layerVisible}
            />
          </div>
          <ProgressControls
            max={trees.length}
            value={trees.length - todoTrees.length}
            hideProgressBar={false}
            actions={validationActions({
              // onUndo: handleUndo,
              isUndoAvailable: actions.canUndo,
              tree: currentTree,
              history,
              markAsDone: async (tree, status) => {
                await betaTree.markAsDoneSegmentation();
                await betaTree.addComment({
                  message: 'OK',
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });
                await betaManagedAreaContext.fetchTrees();

                moveToNeighbour();
              },
              updateTree: async (status, tree_flow_status) => {
                await betaTree.updateTree({ status, tree_flow_status });
                await betaManagedAreaContext.fetchTrees();
                moveToNeighbour();
              },
              showTodoAction: true,
              commentTree: async (_, comment) => {
                await betaTree.addComment({
                  message: comment.value,
                  comment_type: CommentTypes.COMMENT,
                  isUndoAction: false,
                });
              },
              removeTreeComment: async (cid) => {
                await betaTree.removeComment(cid);
              },
              removeTree: async (args, status) => {
                await betaTree.deleteTreeSegmentation();
                await betaManagedAreaContext.fetchTrees();
                moveToNeighbour();
              },
              sendToField: () => alert('Unimplemented feature'),
              onRotation: rotationHandler,
              numberOfTrees: todoTrees.length,
              validationTool: reclass.actionMode,
              changeTool: changeActionMode,
              dismissModal,
              presentModal,
              showComment: true,
              isSemantics: true,
              showDeleteConfirmation: true,
              tmsCategory: treeMetrics.tmsCategory,
              onSetTmsCategory: async (category) => {
                if (category === 'l3') {
                  await betaTree.updateTree({
                    tms_category: 'l3',
                    status: TreeStatus.SentToField,
                    tree_flow_status: TreeFlowStatus.SentToField,
                  });

                  if (isQualityCheck) {
                    await betaTree.setQualityCheckStatus(currentTree.id, QC_STATUSES.QC_ERROR);
                    await refetchQualityCheckTrees(managedArea.id);
                  } else {
                    await betaManagedAreaContext.fetchTrees();
                  }

                  moveToNeighbour();
                } else {
                  const success = await betaTree.updateTmsCategory(category);
                  if (success) {
                    treeMetrics.setTmsCategory(category);
                  }
                }
              },
              showTmsButtons: true,
              showTmsL3Button: true,
              showNavigationArrows: false,
              isQualityCheck,
              managedAreaId: managedArea.id,
              currentStep: 'segmentation_validation',
              handleQCStatusChange: async (status) => {
                await betaTree.setQualityCheckStatus(currentTree.id, status);
                await refetchQualityCheckTrees(managedArea.id);
                moveToNeighbour();
              },
            })}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  );
};

const ContextWrappedSegmentationValidation = (props) => {
  const urlParams = useParams();
  const betaTree = useBetaTree({
    treeId: urlParams.tree,
    managedAreaId: props.managedArea?.id,
    managedAreaCode: props.managedArea?.code,
  });
  const { tree: currentTree, updateTree, loading } = betaTree;

  const { presentModal, dismissModal } = useModal();

  const hookProps = {
    currentTree,
    presentModal,
    dismissModal,
  };

  return (
    <SegmentationScreenContextProvider
      currentTree={currentTree}
      selectTree={props.selectTree}
      updateTree={updateTree}
      dismissModal={dismissModal}
      saveError={props.saveError}
      loading={loading}
      contextsLoadingState={props.contextsLoadingState}
      isQualityCheck={props.isQualityCheck}
    >
      <SegmentationValidation {...{ ...props, ...hookProps, betaTree }} />
    </SegmentationScreenContextProvider>
  );
};

export default ContextWrappedSegmentationValidation;
