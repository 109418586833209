import proj4 from 'proj4';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Map, Panorama, VoxelViewer } from '../../components';
import PointcloudViewer from '../../components/PointcloudViewer';
import ToolBar from '../../components/ViewToolBar';
import { getPointLabelDefaultHexColor, useConfig } from '../../providers/config';
import { getVoxel } from '../../providers/data';
import { useTheme } from '../../providers/theme';
import { useUser } from '../../providers/user';
import LASLoader from '../../utils/LASLoader';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import { QC_STATUSES } from '../../@types/enums/QualityCheckStatus';

const MAP_SOURCES = [
  { id: 'trees', source: 'trees', name: 'Trees' },
  { id: 'mas', source: 'managed_areas', name: 'Managed Areas' }
];

const environmentLoadingManager = new THREE.LoadingManager();
const environmentLazLoader = new LASLoader(environmentLoadingManager);
const loadingManager = new THREE.LoadingManager();
const loader = new LASLoader(loadingManager);

const TABS = [
  {
    label: 'Voxel',
    id: 'voxel',
    iconName: 'voxel',
  },
  {
    label: 'Panoramic',
    id: 'panoramic',
    iconName: 'panorama',
  },
  {
    label: 'Point Cloud',
    id: 'pointcloud',
    iconName: 'pointcloud_360',
  },
  {
    label: '2D Map',
    id: 'map',
    iconName: 'mapView',
  },
];

const LEFT_RIGHT_TABS = {
  left: TABS,
  right: TABS,
};

const useViews = (tree, { token, isDark, mapRef, voxel, setVoxel, pointcloud, setPointcloud, isEnvironmentEnabled, selectTree, setPanoramicLoading }) => {
  const { getConfig } = useConfig();
  const [panoramic, setPanoramic] = useState(null);
  const [map, setMap] = useState(null);
  const POINTCLOUD_CACHE = useRef({});
  const [environmentLas, setEnvironmentLas] = useState(null);
  const colors = {
    canopy: getPointLabelDefaultHexColor("canopy"),
    trunk: getPointLabelDefaultHexColor("trunk"),
  };

  useEffect(() => {
    const _handleVoxel = async () => {
      setVoxel(null);

      const voxelJSON = await getVoxel(tree, token);

      if (voxelJSON) {
        setVoxel(<VoxelViewer json={voxelJSON} backgroundColor={isDark ? '#000' : '#fff'} />);
      } else {
        setVoxel(
          <div className='view-error'>
            <p>Couldn't load voxel model.</p>
          </div>
        );
      }
    };

    const _handlePanoramic = () => {
      setPanoramic(<Panorama
          treeId={tree?.id}
          managedAreaCode={tree?.managed_area}
          enabledMiniMap={false}
          enableGoogleMapsButton={true}
          enableAddTree={false}
          onTreeChange={selectTree}
      />);
    };

    const _handleEnvironmentLasLoad = () => {
      environmentLazLoader.load(
        tree?.tree_segmentation_environment_laz_url,
        (pointcloud) => {
          setEnvironmentLas({ id: tree.id, pointcloud });
          POINTCLOUD_CACHE.current[tree.id].environment = { id: tree.id, pointcloud };
        },
        () => {},
      );
    };

    const _handlePointcloud = async () => {
      const setPointcloudViewer = (pointcloud) => {
        let environmentPointCloud;
        if (isEnvironmentEnabled && environmentLas) environmentPointCloud = environmentLas.pointcloud;

        setPointcloud(
          <PointcloudViewer
            colors={colors}
            pointcloud={pointcloud}
            environmentPointCloud={environmentPointCloud}
            position={proj4('EPSG:4326', 'localProjection', JSON.parse(tree?.location)?.coordinates?.slice(0) || [])}
          />
        );
      };

      if (POINTCLOUD_CACHE.current[tree.id]) {
        setPointcloudViewer(POINTCLOUD_CACHE.current[tree.id].pointcloud);
      } else {
        setPointcloud(null);
        loader.load(tree.tree_segmentation_laz_url, (pointcloud) => {
          setPointcloudViewer(pointcloud);
          POINTCLOUD_CACHE.current[tree.id] = { pointcloud };
        }, null, ()=>{
          setPointcloud(
            <div className='view-error'>
              <p>Couldn't load point cloud.</p>
            </div>
          );
        });
      }
    };

    const _handleMap = () => {
      const statuses = [
        TreeFlowStatus.LocationValidationQueued,
        TreeFlowStatus.LocationValidationRunning,
        TreeFlowStatus.LocationValidationDone,
        TreeFlowStatus.LocationValidationDeleted,
        TreeFlowStatus.SegmentationValidationQueued,
        TreeFlowStatus.SegmentationValidationRunning,
        TreeFlowStatus.SegmentationValidationDone,
        TreeFlowStatus.SegmentationValidationDeleted,
        TreeFlowStatus.SentToOfflineAnnotation,
        TreeFlowStatus.SentToOnlineAnnotationQueued,
        TreeFlowStatus.MeasurementValidationQueued,
        TreeFlowStatus.MeasurementValidationRunning,
        TreeFlowStatus.MeasurementValidationDone,
        TreeFlowStatus.MeasurementValidationDeleted,
        TreeFlowStatus.SpeciesValidationQueued,
        TreeFlowStatus.SpeciesValidationRunning,
        TreeFlowStatus.SpeciesValidationDone,
        TreeFlowStatus.SentToField,
        TreeFlowStatus.Completed,
        QC_STATUSES.QC_PENDING,
        QC_STATUSES.QC_APPROVED,
        QC_STATUSES.QC_MODIFIED,
        QC_STATUSES.QC_ERROR,
        'completed'
      ];

      const layers = [
        {
          id: 'mas',
          source: 'mas',
          type: 'fill',
          color: '#082',
        },
        {
          id: 'completed',
          source: 'trees',
          type: 'circle',
          filter: ['==', 'id', parseInt(tree.id)],
          color: getConfig(`colors.${getConfig(`statuses.completed`)?.color}`) || '#08f',
        },

        ...statuses.map((item) => ({
          id: item,
          source: 'trees',
          type: 'circle',
          filter: ['==', 'tree_flow_status', item],
          color: getConfig(`colors.${getConfig(`statuses.${item}`)?.color}`),
        })),
        ];


      setMap(
        <Map
          _ref={mapRef}
          sources={MAP_SOURCES}
          layers={layers}
          sourceVisbile={{ trees: true, mas: true }}
          layerVisible={{
            mas: true,
            completed: true,
          }}
          active={{
            mas: tree.managed_area_id,
            completed: parseInt(tree.id),
          }}
        />
      );
    };

    if (tree) {
      _handleEnvironmentLasLoad();
      _handleVoxel();
      _handlePanoramic();
      _handlePointcloud();
      _handleMap();
    }
  }, [tree?.id, isEnvironmentEnabled]);

  return {
    voxel,
    panoramic,
    pointcloud,
    map,
  };
};

const SingleTree = ({ tree, voxel, activeTabs, setActiveTabs, selectTree, setPanoramicLoading, setVoxel, pointcloud, setPointcloud }) => {
  const mapRef = useRef(null);
  const [pointSize, setPointSize] = useState(0.5);
  const [isEnvironmentEnabled, setIsEnvironmentEnabled] = useState(false);
  const { token } = useUser();
  const { isDark } = useTheme();
  const views = useViews(tree, {
    token,
    isDark,
    mapRef,
    voxel,
    setVoxel,
    pointcloud,
    setPointcloud,
    isEnvironmentEnabled,
    selectTree,
    setPanoramicLoading,
  });

  useEffect(() => {
    if (activeTabs.left === 'map' || activeTabs.right === 'map')
      setTimeout(() => mapRef.current?.focusOnPoint(JSON.parse(tree?.location)?.coordinates || []), 250);
  }, [activeTabs.left, activeTabs.right, JSON.parse(tree?.location)?.coordinates, tree?.id]);

  return (
    <div className='single-tree-wrapper'>
      <div className='views'>
        <div className='view-wrapper'>
          <ToolBar
            activeTabs={activeTabs}
            side={'left'}
            tabs={LEFT_RIGHT_TABS.left}
            setActiveTab={setActiveTabs}
            isEnvironmentEnabled={isEnvironmentEnabled}
            setIsEnvironmentEnabled={setIsEnvironmentEnabled}
            pointSize={pointSize}
            setPointSize={setPointSize}
          />
          {views[activeTabs.left]}
        </div>
        <div className='view-wrapper'>
          <ToolBar
            activeTabs={activeTabs}
            side={'right'}
            tabs={LEFT_RIGHT_TABS.right}
            setActiveTab={setActiveTabs}
            isEnvironmentEnabled={isEnvironmentEnabled}
            setIsEnvironmentEnabled={setIsEnvironmentEnabled}
            pointSize={pointSize}
            setPointSize={setPointSize}
          />
          {views[activeTabs.right]}
        </div>
      </div>
    </div>
  );
};

export default SingleTree;
