import { extend, MaterialNode, Object3DNode, useFrame } from '@react-three/fiber';
import { MeshLineGeometry, MeshLineMaterial } from 'meshline';
import { useRef } from 'react';
import { Color, Mesh, Vector3 } from 'three';
import { default as DragPoint } from '../DragPoint';
import { useSemanticScreenContext } from '../../../../hooks/betaHooks/semantic-screen-context';

extend({ MeshLineGeometry, MeshLineMaterial });

declare module '@react-three/fiber' {
  interface ThreeElements {
    meshLineGeometry: Object3DNode<MeshLineGeometry, typeof MeshLineGeometry>;
    meshLineMaterial: MaterialNode<MeshLineMaterial, typeof MeshLineMaterial>;
  }
}

const LEAN_GIZMO_POINT_COLORS = {
  point: new Color('#222D31'),
  border: new Color('#ffffff'),
  hover: new Color('#222D31'),
};

type Props = {
  color?: string;
  handleSize?: number;
};

const LeanGizmo = ({ color = '#00FF80', handleSize }: Props) => {
  const lineRef = useRef<Mesh<MeshLineGeometry, MeshLineMaterial>>(null);
  const startPointRef = useRef<Mesh>(null);
  const endPointRef = useRef<Mesh>(null);

  const { treeMetrics, visibility, helper } = useSemanticScreenContext();

  useFrame(() => {
    if (!lineRef.current || !treeMetrics.firstBifurcationPoint) return;

    const leanVector = treeMetrics.firstBifurcationPoint.clone().sub(helper.treeLocation);

    lineRef.current.geometry.setPoints([new Vector3(), leanVector]);
  });

  return (
    <group name='lean-gizmo' visible={visibility.leaningVector}>
      <mesh ref={lineRef} renderOrder={999}>
        <meshLineGeometry />
        <meshLineMaterial lineWidth={0.01} color={color} depthTest={false} depthWrite={false} />
      </mesh>

      <DragPoint
        ref={startPointRef}
        name={'start-point'}
        initialPosition={new Vector3()}
        restrictAxis={{ x: true, y: true, z: true }}
        colors={LEAN_GIZMO_POINT_COLORS}
        draggable={false}
        handleSize={handleSize}
        renderOrder={1000}
      />
      <DragPoint
        ref={endPointRef}
        name={'end-point'}
        initialPosition={treeMetrics.firstBifurcationPoint.clone().sub(helper.treeLocation)}
        restrictAxis={{ x: true, y: true, z: true }}
        colors={LEAN_GIZMO_POINT_COLORS}
        draggable={false}
        handleSize={handleSize}
        renderOrder={1000}
      />
    </group>
  );
};

export default LeanGizmo;
