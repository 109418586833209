import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { Color, MathUtils, Mesh, Vector2, Vector3, WebGLRenderer } from 'three';
import Tree from '../../../@types/Tree';
import XZYCoords from '../../../@types/XZYCoords';
import ValidationAction from '../../../@types/enums/ValidationAction';
import { useScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import { convertValueBetweenRanges } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import EllipseGizmo from '../Gizmos/EllipseGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import SectionCameraControls from '../ValidationControls/SectionCameraControls';
import { calculateGirth, parseGirth } from '../ValidationUtils';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';
import useDefaultZoom from '../hooks/useDefaultZoom';
import { getDefaultGirth } from '../../../helpers/defaultValues';
import { EllipseWithNormalAndHeight } from '../../../@types/defaults';
import { Girth } from '../../../@types/Girth';

/* const LEAN_START_POINT_COLORS = {
  point: new Color('#222D31'),
  border: new Color('#ffffff'),
  hover: new Color(SemanticActionsColorMap[ValidationAction.LeaningVector]),
}; */

const FIRST_BIF_START_POINT_COLORS = {
  point: new Color('#222D31'),
  border: new Color('#ffffff'),
  hover: new Color(SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]),
};

const GIRT_ELLIPSE_COLORS = {
  dragPoint: new Color('#222D31'),
  dragPointBorder: new Color('#ffffff'),
  dragPointHover: new Color(SemanticActionsColorMap[ValidationAction.Girth]),
  ellipseBody: new Color(SemanticActionsColorMap[ValidationAction.Girth]),
};

const MIN_MAX_ZOOM = {
  min: 20,
  max: 1500,
};

type SectionViewProps = {
  currentTree: Partial<Tree> | null;
  minZoom?: number;
  maxZoom?: number;
};

const SectionView = ({ currentTree, minZoom, maxZoom }: SectionViewProps) => {
  const {
    treeMetrics,
    trunkActions,
    visibility,
    pointCloudModule: {
      pointCloud,
      environmentPointCloud,
      isEnvironmentVisible,
      pointSize,
    },
    section: { target, depth, normal, lookAt, lookFrom },
    validation,
    helper,
  } = useScreenContext();
  const zoom = useDefaultZoom();

  const isGirthActive = helper.activeTool === ValidationAction.Girth;
  const isFirstBifActive = helper.activeTool === ValidationAction.FirstBifurcationPoint;

  /* const leanStartPointRef = useRef<Mesh>(null); */
  const firstBifPointRef = useRef<Mesh>(null);

  const scaledInitialZoom = useMemo(() => {
    if (!currentTree?.girth_1_0m) return zoom * 4;

    const girth = calculateGirth(parseGirth(currentTree as Tree));
    const scaledZoom = convertValueBetweenRanges({ min: 0, max: girth + 1 }, { min: minZoom || MIN_MAX_ZOOM.min, max: maxZoom || MIN_MAX_ZOOM.max }, girth);

    return MathUtils.clamp(scaledZoom, minZoom || MIN_MAX_ZOOM.min, maxZoom || MIN_MAX_ZOOM.max);
  }, [currentTree, zoom, trunkActions.shownGirth]);

  /*   const changeLeanStartPoint = (vector: XZYCoords) => {
      const startVector = new Vector3().copy(mergeXYZValues(leanStartVector!, vector));
      const leanVector = new Vector3().subVectors(treeMetrics.firstBifurcationPoint, startVector);
      setLeanState({ leanVector, startVector });
    }; */

  // EllipseGizmo sets up an eventlistener to handle changes, and for efficiency it's only setup ONCE
  //   therefore updateGirthRadius, updateGirthCenter, updateGirthRotation become closures,
  //     and they captures the lexical environment in which it was created.
  //   The shownGirth is no longer available from the closure.
  //   To solve this issue as the trunks can be selected, deselected,
  //     with actualActiveGirth it is made possible to keep up-to-date the truck value
  const { updateGirthRadius, updateGirthCenter, updateGirthRotation, setActualActiveGirth } = useMemo(() => {
    let actualActiveGirth = trunkActions.shownGirth;

    function update(updatedFields: { rX?: number, rY?: number, dX?: number, dY?: number, rotation?: number }) {
      if (!actualActiveGirth) {
        return actualActiveGirth ? actualActiveGirth : getDefaultGirth();
      }

      const newGirth = { ...actualActiveGirth, ...updatedFields };

      trunkActions.updateGirth(newGirth);
      trunkActions.setShownGirth(newGirth);

      return newGirth;
    }

    return {
      updateGirthRadius: (axis: 'rX' | 'rY', radius: number): EllipseWithNormalAndHeight => update({ [axis]: radius }),
      updateGirthCenter: (vector: Vector2) => update({ dX: vector.x, dY: vector.y }),
      updateGirthRotation: (rotation: number) => update({ rotation }),
      setActualActiveGirth: function (newTrunk: Girth) {
        actualActiveGirth = newTrunk;
      }
    };
  }, []);

  useEffect(() => {
    setActualActiveGirth(trunkActions.shownGirth);
  }, [trunkActions.shownGirth, setActualActiveGirth]);


  // Same like EllipseGizmo but for bifurcation point
  const { changeFirstBifPoint, setActualFirstBifurcationHeight } = useMemo(() => {
    // TODO SectionView should be separated based on what it shows, as after contestification it created clutter
    const isSemanticScreen = treeMetrics.firstBifurcationPoint instanceof Vector3 && 'setFirstBifurcationPoint' in treeMetrics;
    let actualFirstBifurcationHeight = isSemanticScreen ? treeMetrics.firstBifurcationPoint.z : 0;

    function changeFirstBifPoint(vector: XZYCoords) {
      if (!isSemanticScreen) return;

      const correctedX = (vector.x || 0) + helper.treeLocation.x;
      const correctedY = (vector.y || 0) + helper.treeLocation.y;

      const newFirstBif = new Vector3(correctedX, correctedY, actualFirstBifurcationHeight);
      treeMetrics.setFirstBifurcationPoint(newFirstBif);
    };

    return {
      changeFirstBifPoint,
      setActualFirstBifurcationHeight: function (newHeight: number) {
        actualFirstBifurcationHeight = newHeight;
      }
    };
  }, []);

  useEffect(() => {
    const isSemanticScreen = treeMetrics.firstBifurcationPoint instanceof Vector3 && 'setFirstBifurcationPoint' in treeMetrics;
    if (!isSemanticScreen) return;
    setActualFirstBifurcationHeight(treeMetrics.firstBifurcationPoint.z);
  }, [treeMetrics.firstBifurcationPoint, setActualFirstBifurcationHeight]);

  const cameraRef = useRef<THREE.OrthographicCamera | THREE.PerspectiveCamera | null>(null);

  const moveGirthToClickPosition = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isFirstBifActive || !cameraRef.current || validation?.isFirstBifurcationMidPointCompleted || helper.isEditingDisabled) return;

    const canvas = event.target as HTMLCanvasElement;

    const rect = canvas.getBoundingClientRect();
    const mouse = new Vector2();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    const vector = new Vector3(mouse.x, mouse.y, 0.5);
    vector.unproject(cameraRef.current);

    changeFirstBifPoint({ x: vector.x, y: vector.y });
  };

  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledInitialZoom,
        }}
        onCreated={({ camera }) => {
          cameraRef.current = camera;
        }}
        gl={(canvas) => {
          const renderer = new WebGLRenderer({ canvas });
          renderer.localClippingEnabled = true;
          return renderer;
        }}
        onClick={moveGirthToClickPosition}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

        <Suspense>
          <DragPoint
            name={'tree-location-point'}
            initialPosition={new Vector3()}
            color={new Color('red')}
            draggable={false}
            visibility={true}
            handleSize={0.1}
          />
          {/*           <DragPoint
            ref={leanStartPointRef}
            name={'lean-start-point'}
            initialPosition={leanStartVector!}
            onDrag={changeLeanStartPoint}
            colors={LEAN_START_POINT_COLORS}
            visibility={leanAngleVisibility && isLeanAngleActive}
            restrictAxis={{ z: true }}
            draggable={!props.disabled && leanAngleVisibility && isLeanAngleActive && !isLeanAngleCompleted}
            handleSize={0.15}
          /> */}
          <DragPoint
            ref={firstBifPointRef}
            name={'first-bif-point'}
            initialPosition={"firstBifurcationCoordinates" in treeMetrics ?
              new Vector3(...treeMetrics.firstBifurcationCoordinates)
              :
              treeMetrics.firstBifurcationPoint.clone().sub(helper.treeLocation)}
            onDrag={changeFirstBifPoint}
            colors={FIRST_BIF_START_POINT_COLORS}
            visibility={visibility.firstBifurcationPoint && isFirstBifActive}
            restrictAxis={{ z: true }}
            draggable={!helper.isEditingDisabled && visibility.firstBifurcationPoint && isFirstBifActive && !validation.isFirstBifurcationMidPointCompleted}
            handleSize={0.15}
          />
          <EllipseGizmo
            name={ValidationAction.Girth}
            ellipse={trunkActions.shownGirth}
            colors={GIRT_ELLIPSE_COLORS}
            visible={visibility.girth && isGirthActive}
            disabled={Boolean(!isGirthActive || helper.isEditingDisabled || validation.isTrunkDetailsCompleted)}
            handleSize={0.15}
            updates={{
              updateRadius: updateGirthRadius,
              updateCenter: updateGirthCenter,
              updateRotation: updateGirthRotation,
            }}
          />

          {(lookAt || lookFrom) && (
            <TreePointCloud
              section={{ depth, lookAt, lookFrom }}
              treePointCloud={pointCloud}
              envPointCloud={environmentPointCloud}
              isEnvVisible={isEnvironmentVisible}
              pointSize={pointSize}
              locationDelta={helper.locationDelta}
            />)}
        </Suspense>
        <SectionCameraControls target={target} normal={normal} minZoom={minZoom || MIN_MAX_ZOOM.min} maxZoom={maxZoom || MIN_MAX_ZOOM.max} />
      </Canvas>
    </div>
  );
};

export default SectionView;
