import { ObjectValues } from "./defaults";


export const TOAST_TYPE = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
} as const;

export type ToastType = ObjectValues<typeof TOAST_TYPE>;

type Toast = {
  message: string;
  type: ToastType;
};

export default Toast;