import { UndoMicroclimateFunctions } from '../../../@types/MicroclimateAttributes';
import Tree from '../../../@types/Tree';
import { GetGenusesOptions, GetSpeciesOptions } from '../../../hooks/betaHooks/beta-use-tree';
import PropertiesCard from '../PropertiesCard';
import TreeClassificationGenusSpeciesCard from "../TreeClassificationCard/TreeClassificationGenusSpeciesCard";

type AttributesTabProps = {
  tree: Tree;
  undo: UndoMicroclimateFunctions;
  onGenusAndSpeciesChange: (genusId: number | null, speciesId?: number | null) => void;
  getGenuses: (options: GetGenusesOptions) => Promise<any[]>;
  getSpecies: (options: GetSpeciesOptions) => Promise<any[]>;
  disableGenusAndSpecies: boolean;
  onCardStateChange: (modified: boolean) => void;
};

const AttributesTab = (props: AttributesTabProps): JSX.Element => {

  return (
    <div className='microclimate-inputs-wrapper'>
      <PropertiesCard
        genusConfidence={props.tree.genus_confidence} speciesConfidence={props.tree.species_confidence} markedAsUncertain={!!props.tree.microclimate.uncertain} />
      <TreeClassificationGenusSpeciesCard
        cardValue={props.tree}
        onGenusAndSpeciesChange={props.onGenusAndSpeciesChange}
        genusConfidenceLockEnabled={false} /* turned off in PRD-686 */
        genusConfidenceLock={.6}
        genusConfidenceValue={props.tree.genus_confidence}
        getGenuses={props.getGenuses}
        getSpecies={props.getSpecies}
        disabled={props.disableGenusAndSpecies}
        onCardStateChange={props.onCardStateChange}
      />
    </div>
  );
};

export default AttributesTab;
