import { useTheme as useMuiTheme } from '@mui/material/styles';
import { CSSProperties, useMemo, useState } from 'react';
import { UndoMicroclimateFunctions } from '../../../@types/MicroclimateAttributes';
import { MicroclimateParams } from '../../../@types/MicroclimateValidation';
import Tree from '../../../@types/Tree';
import TreeFlowStatus from '../../../@types/enums/TreeFlowStatus';
import { Comment, GetGenusesOptions, GetSpeciesOptions } from '../../../hooks/betaHooks/beta-use-tree';
import Attributes from '../../Icon/Attributes';
import Button from '../../inputs/Button';
import AttributesTab from './AttributeTab';

const buttonStyle: CSSProperties = {
  padding: '6px 16px',
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '20px',
};

type AttributesInputProps = {
  tree: any;
  /* updateTree: (id: any, change: any, postPatch?: any) => void; */
  updateTree: (tree_flow_status: TreeFlowStatus.SpeciesValidationQueued | TreeFlowStatus.SpeciesValidationDone) => Promise<void>;
  undo: UndoMicroclimateFunctions;
  style?: React.CSSProperties;
  isToolbarVisible: boolean;
  managedAreaId: string;
  setGenusAndSpecies: (genusId: number | null, speciesId?: number | null) => Promise<any>;
  updateMicroclimate: (options: MicroclimateParams) => Promise<any>;
  addTreeComment: (comment: Comment) => void;
  activeTabs: Record<'left' | 'right', string>;
  selectTree: (treeId: string) => void;
  getNextTree: (direction?: number) => Partial<Tree> | null;
  reloadTrees: () => Promise<any[] | undefined>;
  getGenuses: (options: GetGenusesOptions) => Promise<any[]>;
  getSpecies: (options: GetSpeciesOptions) => Promise<any[]>;
  reloadManagedArea: () => Promise<{ trees: any[]; managedArea: any; pipeline: any; } | undefined>;
};

const AttributesInput = (props: AttributesInputProps): JSX.Element => {
  const muiTheme = useMuiTheme();

  const [genusAndSpeciesModified, setGenusAndSpeciesModified] = useState(false);

  // While updating tree information we also refresh the tree list which can 
  // change the order of the trees with certain soring options. To keep the 
  // navigation consistent, we have to save the next tree before we update any 
  // tree attribute.
  const [nextTree, setNextTree] = useState<Partial<Tree> | null>(null);

  const completed = props.tree.tree_flow_status === TreeFlowStatus.SpeciesValidationDone;
  const uncertain = !!props.tree.microclimate.uncertain;

  const toggleUncertain = async (note: string) => {
    const localNextTree = nextTree ?? props.getNextTree();
    await props.updateMicroclimate({ ...props.tree.microclimate, uncertain: !uncertain });
    await props.reloadManagedArea();
    if (!uncertain && localNextTree?.id) {
      props.selectTree(localNextTree.id);
      setNextTree(null);
    }
  };

  const handleMarkComplete = async () => {
    const localNextTree = nextTree ?? props.getNextTree();
    const treeFlowStatus = completed ? TreeFlowStatus.SpeciesValidationQueued : TreeFlowStatus.SpeciesValidationDone;
    await props.updateMicroclimate({ ...props.tree.microclimate, completed: !completed });
    await props.updateTree(treeFlowStatus)
    await props.reloadManagedArea();
    if (!completed && localNextTree?.id) {
      props.selectTree(localNextTree.id);
      setNextTree(null);
    }
  };

  const handleGenusAndSpeciesChange = async (genusId: number | null, speciesId?: number | null) => {
    setNextTree(props.getNextTree());
    await props.updateMicroclimate({ ...props.tree.microclimate, uncertain: false });
    await props.setGenusAndSpecies(genusId, speciesId);
    await props.reloadTrees();
  }

  const canMarkComplete = useMemo(() => {
    return !props.tree.microclimate.uncertain && props.tree.genus_id && !genusAndSpeciesModified;
  }, [props.tree.genus_id, props.tree.microclimate.uncertain, genusAndSpeciesModified]);

  return (
    <div className={`attributes-wrapper`} style={{ backgroundColor: '#202324' }}>
      <div className={`attributes-container`} style={{ ...props.style, backgroundColor: muiTheme.palette.background.default }}>
        <div className='attributes-panel'>
          <p className='panel-title'>
            <Attributes color={muiTheme.palette.text.primary} /> Attributes
          </p>
          <AttributesTab
            tree={props.tree}
            undo={props.undo}
            getGenuses={props.getGenuses}
            getSpecies={props.getSpecies}
            onGenusAndSpeciesChange={handleGenusAndSpeciesChange}
            disableGenusAndSpecies={completed}
            onCardStateChange={setGenusAndSpeciesModified}
          />
        </div>
      </div>
      <div className='attributes-actions-wrapper'>
        {!completed && (
          <Button
            id='uncertainButton'
            label={uncertain ? 'Mark Confident' : 'Mark Uncertain'}
            primary={true}
            destructive={false}
            leadingIcon={'questionMark'}
            style={buttonStyle}
            iconStyle={{ color: '#333' }}
            onClick={toggleUncertain}
          />
        )}
        <Button
          id='markCompleteButton'
          label={completed ? 'Revert Complete' : 'Mark Complete'}
          primary={true}
          destructive={completed}
          disabled={!completed && !canMarkComplete}
          leadingIcon={completed ? 'undo-alt' : 'checkmark'}
          style={buttonStyle}
          classNames={!completed ? 'success' : undefined}
          iconStyle={{ color: canMarkComplete ? 'white' : 'rgba(51, 51, 51, 0.5)' }}
          onClick={handleMarkComplete}
        />
      </div>
    </div>
  );
};

export default AttributesInput;
