import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Icon } from '../..';
import TreeStatus from '../../../@types/enums/TreeStatus';
import useApi from '../../../hooks/api';
import { useGlobalConfig } from '../../../providers/data';
import { Button } from '../../inputs';
import DropdownInput from '../../inputs/DropdownInput';
import ClassOption from '../ClassOption';
import TreeFlowStatus from '../../../@types/enums/TreeFlowStatus';
import { CommentTypes } from '../../../hooks/betaHooks/beta-use-tree';
import Tree from '../../../@types/Tree';

type BadSegmentationModalProps = {
  currentTree: Partial<Tree> | null;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSave: (changes: { status: TreeStatus, tree_flow_status: TreeFlowStatus }) => Promise<void>;
  handleComment: (comment: { message: string; comment_type: CommentTypes }) => Promise<void>;
  removeComment: (commentId: string) => Promise<void>;
};

const BadSegmentationModal = ({ currentTree, setVisible, onSave, handleComment, removeComment }: BadSegmentationModalProps): JSX.Element => {
  const { loaded, getGlobalConfig } = useGlobalConfig();
  const [comment, setComment] = useState('Other');
  const [otherComment, setOtherComment] = useState('');
  const config = getGlobalConfig('predefined-comments');
  const [prevComments, setPrevComments] = useState<any[]>([]);
  const { handleRequest } = useApi();

  const predefinedComments = config?.value
    ? config?.value
        ?.filter((c: string) => c !== 'Ok')
        .concat(['Other'])
        .map((comment: any) => ({ value: comment, label: comment }))
    : [];

  useEffect(() => {
    if (!currentTree?.id) return;
    handleRequest(`v1/comments/${currentTree.id}`)
      .then((res: any) => res.json())
      .then((res: any) => {
        setPrevComments(res);
      });
  }, [handleRequest, currentTree?.id, handleComment]);

  const handleOnSave = () => {
    onSave({ status: TreeStatus.SegmentationValidationTodo, tree_flow_status: TreeFlowStatus.SentToOnlineAnnotationQueued });

    if (currentTree?.comment?.length) {
      currentTree?.comment.forEach((comment) => {
        if (comment?.value?.toString() === "OK")
        removeComment(comment.id)
      })
    }
    
    setVisible(false);
  };

  const addComment = () => {
    const newComment = {
      message: comment === 'Other' ? otherComment : comment,
      comment_type: CommentTypes.COMMENT,
    };
    handleComment(newComment);
    setPrevComments((oldComments) => {
      const newComments = [...oldComments, newComment];

      return newComments;
    });
  };

  const _removeComment = (removableCommentId: string) => {
    removeComment(removableCommentId);
    setPrevComments(prevComments.filter((pc) => pc.id !== removableCommentId));
  };

  const isSaveButtonDisabled = (): boolean => !prevComments.filter((comment) => comment.value !== 'OK').length;

  return (
    <div className='bad-segmentation-modal-wrapper'>
      <div className='modal'>
        <div className='header'>
          <p>Reason for sending back</p>
          <i className='close-button' onClick={() => setVisible((val) => !val)}>
            <Icon icon='removeX' />
          </i>
        </div>
        <div className='body'>
          <div>
            <p className='description'>Please select why you want to flag this tree for deletion.</p>
            {loaded && predefinedComments ? (
              <Fragment>
                <DropdownInput
                  options={predefinedComments}
                  value={comment}
                  onPureChange={(val) => setComment(val)}
                  optionComponent={ClassOption}
                  small
                  label={undefined}
                  leadingIcon={undefined}
                  error={undefined}
                  warning={undefined}
                  clearLabel={undefined}
                  showNumberOfOptions={undefined}
                  isLimited={undefined}
                  style={{
                    backgroundColor: 'white',
                    color: '#35393A',
                  }}
                />
                {comment === 'Other' && (
                  <textarea
                    className='other-input'
                    placeholder='Please elaborate...'
                    onChange={(e) => setOtherComment(e.target.value)}
                    value={otherComment}
                  />
                )}
                <Button classNames={`button`} label='Add comment' primary onClick={() => addComment()} />
              </Fragment>
            ) : (
              <p>Could not load dropdown options</p>
            )}
          </div>
          <div>
            <p className='comments-title'>Previous Comments</p>
            {prevComments?.length ? (
              <ul className='comment-list'>
                {prevComments.map((c, i) => (
                  <li className='comment-item' key={i}>
                    <div className='comment-item__header'>
                      <span className='content'>{c.value}</span>
                      <div className={`comment-item__action-remove svg-container`} onClick={() => _removeComment(c.id)}>
                        <Icon icon='times' />
                      </div>
                    </div>
                    <span className='creator'>{c.creator}</span>
                    <span className='posted-at'>{c.date}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No comments are available</p>
            )}
          </div>
        </div>
        <div className={`action-wrapper`}>
          <div
            className={`button ${isSaveButtonDisabled() ? 'disabled' : ''}`}
            onClick={() => {
              if (isSaveButtonDisabled()) return;
              handleOnSave();
            }}
          >
            <Icon icon='tick-grey' />
            <span>OK</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadSegmentationModal;
