import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconType } from '../../@types/Icon';
import Icon from '../Icon';
import IconButton from '../inputs/IconButton';

export interface TreeSelectorParams {
  treeId: number;
  onChangeTree: (direction: number) => void;
}

const TreeSelector = ({ treeId, onChangeTree }: TreeSelectorParams): JSX.Element => {

  const handleBackwardStep = () => {
    onChangeTree(-1);
  };

  const handleForwardStep = () => {
    onChangeTree(1);
  };

  useHotkeys('left', handleBackwardStep, { keydown: true, enabled: true }, [handleBackwardStep]);
  useHotkeys('right', handleForwardStep, { keydown: true, enabled: true }, [handleForwardStep]);

  return (
    <div className={`tree-selector`}>
      <IconButton icon={faAngleLeft as IconType} onClick={handleBackwardStep} tooltip='Previous tree' />
      <Icon icon='treeIcon' />
      <span>{treeId}</span>
      <IconButton icon={faAngleRight as IconType} onClick={handleForwardStep} tooltip='Next tree' />
    </div>
  );
};

export default TreeSelector;
