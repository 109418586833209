import * as THREE from "three";
import { MeshLine, MeshLineMaterial } from "three.meshline";
import SegmentationMover from "./SegmentationMover";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";

class SemanticControl {
  /** @type {THREE.Object3D} */
  object;
  /** @param {THREE.Scene} */
  scene;

  constructor({
    scene,
    onMovingChange,
    camera,
    container,
    points,
    repaint,
    getConfig,
    isDark,
    zoom,
    color,
    name,
    treePosition,
    girthClippingPlane
  }) {
    this.scene = scene;
    this.onMovingChange = onMovingChange;
    this.camera = camera;
    this.container = container;
    this.repaint = repaint;
    this._points = points;
    this.zoom = zoom;
    this.visible = false;
    this.intersections = [];
    this.raycaster = new THREE.Raycaster();
    this.intersection = new THREE.Vector3();
    this.mouse = new THREE.Vector2();
    this.plane = new THREE.Plane();
    this.name = name;

    this.getConfig = getConfig;
    this.isDark = isDark;

    this.pointSize = null;

    this.moving = false;

    this.pointFilters = null;
    this.setFilter();

    this.initialDone = false;
    this.moveWithCtrl = false;
    this.color = color;

    this.treePosition = treePosition;
    this.treePositionOffset = parseFloat(this.treePosition ? this.treePosition.z : 0);

    this.girthClippingPlane = girthClippingPlane;

  }

  remove() {
    this.scene.remove(this.object);
  }

  setVisibility(visible) {
    if (this.visible === visible && this.initialDone) return;

    this.initialDone = true;
    this.object.visible = visible;
    this.visible = visible;
    this.setFilter();
    this.setSize();
    this.setZoom();
  }

  /**
   *
   * @param {THREE.Vector3} position
   * @returns {SemanticControl}
   */
  setPosition(position) {
    const adjustedHeight = parseFloat(position.z) + this.treePositionOffset;
    this.object.position.copy({...position, z: adjustedHeight});
    return this;
  }

  setFilter() {
    const isGirthControl = this.name === 'girth1'; 
    const filters = isGirthControl ? { 
      minZ: this.girthClippingPlane?.bottom ?? SegmentationMover._GIRTH_MIN_VERTICAL_POINT,
      maxZ: this.girthClippingPlane?.top ?? SegmentationMover._GIRTH_MAX_VERTICAL_POINT,
    } : this.pointFilters;
    if (this.object && this.object.visible && this._points) {
      this._points.material.userData.filterPoints(filters);
    } else if (this.object && !this.object.visible && this._points) {
      this._points.material.userData.filterPoints(null);
    }
  }

  setZoomLevel(e) {
    this.zoom = e.toFixed(2);
  }

  setZoom() {
    if (
      this.object &&
      this.object.visible &&
      this._points &&
      this.zoom &&
      this.camera.zoom !== this.zoom
    ) {
      this.camera.zoom = this.zoom;
      this.camera.updateProjectionMatrix();
      this.repaint();
    }
  }

  setSize() {
    if (this.object && this.object.visible && this._points)
      this._points.material.userData.setSize(this.pointSize);
    else if (this.object && !this.object.visible && this._points)
      this._points.material.userData.setSize(null);
  }

  set points(points) {
    this._points = points;
    this.setFilter();
  }

  /**
   * @param {PointerEvent} e
   */
  handlePointerDown(e) {
    e.preventDefault();

    if (e.button !== 0) return;
    this.moving = true;

    this.plane.setFromNormalAndCoplanarPoint(
      this.camera.position.clone().normalize(),
      this.object.position
    );

    this.moving = true;
    this.onMovingChange(true);

    this.handlePointerMove(e);
  }

  /**
   * @param {PointerEvent} event
   */
  handlePointerMove(event) {}

  handlePointerUp(e) {
    e.preventDefault();

    this.moving = false;
    this.onMovingChange(false);
  }

  /**
   * @param {PointerEvent} e
   * @param {THREE.Object3D[]} [objects]
   * @returns {THREE.Intersection[]}
   */
  updateIntersection(e, objects) {
    const rect = this.container.getBoundingClientRect();
    this.mouse.x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);
    return this.raycaster.intersectObjects(objects || this.object.children);
  }
}

export class HeightControl extends SemanticControl {
  constructor(props) {
    super(props);
    this.onHeightChange = props.onHeightChange;
    this.color = props.color;
    this.name = props.name;

    this.object = this.initGeometry(
      props.radius,
      HeightControl.width,
      true,
      100,
      this.name
    );
    this.largeObject = this.initGeometry(
      props.radius,
      HeightControl.largeWidth,
      true,
      0,
      this.name
    );

    this.object.visible = false;

    this.inverse = props.inverse;

    this.diff = 0;

    this.scene.add(this.largeObject);
    this.scene.add(this.object);
  }

  initGeometry = (radius, width, transparent, opacity, name) => {
    const geometry = new THREE.CylinderGeometry(
      radius,
      radius,
      width,
      100
    );
    const material = this.initMaterial(transparent, opacity);
    geometry.rotateX(Math.PI / 2);
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = name;

    return mesh;
  };

  initMaterial = (transparent, opacity) => {
    let material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(this.isDark ? 0xffff00 : 0xff8c00),
      side: THREE.DoubleSide,
      transparent: transparent,
      opacity: opacity,
    });

    if (this.color) {
      material = new THREE.MeshBasicMaterial({
        color: new THREE.Color(this.color),
        side: THREE.DoubleSide,
        transparent: transparent,
        opacity: opacity,
      });
    }

    return material;
  };

  static width = 0.05;
  static largeWidth = 0.4;

  updateRadius = (...attributes) => {
    const radiusX = attributes[0];
    const radiusY =
      typeof attributes[1] !== "undefined" ? attributes[1] : radiusX;

    this.largeObject.geometry = this.updateGeometry(
      radiusX,
      radiusY,
      HeightControl.largeWidth
    );
    this.object.geometry = this.updateGeometry(
      radiusX,
      radiusY,
      HeightControl.width
    );
  };

  updateGeometry = (radiusX, radiusY, width) => {
    const geometry =
      radiusY === radiusX
        ? new THREE.CylinderGeometry(radiusX, radiusY, width, 100)
        : new THREE.ExtrudeGeometry(
            new THREE.Shape().absellipse(
              0,
              0,
              radiusX,
              radiusY,
              0,
              Math.PI * 2,
              false,
              0
            ),
            {
              depth: width,
              bevelEnabled: false,
            }
          );

    if (radiusX === radiusY) {
      geometry.rotateX(Math.PI / 2);
    }

    geometry.name = this.name;

    return geometry;
  };

  setPosition = (vec2, height) => {
    let adjustedHeight = parseFloat(height) + this.treePositionOffset;
    const adjustedDiff = this.diff + this.treePositionOffset;
    if (this.inverse) {
      const inverseHeight = adjustedDiff - height;
      this.copyPosition(vec2, inverseHeight);
    } else {
      this.copyPosition(vec2, adjustedHeight);
    }

    this.setMaterialColor();
  };

  setDifference = (diff) => (this.diff = parseFloat(diff));

  handlePointerMove = (e) => {
    e.preventDefault();

    if (!this.moving) return;
    if (!e.ctrlKey && this.moveWithCtrl) return;

    e.stopPropagation();

    const rect = this.container.getBoundingClientRect();
    this.mouse.x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);

    let newHeight = this.intersection.z;
    if (this.treePosition) {
      newHeight = this.intersection.z - this.treePositionOffset;
    }

    if (this.inverse) {
      const inverseHeight = this.diff - newHeight
      this.onHeightChange(inverseHeight.toFixed(2), this.intersection.z.toFixed(2));
    } else {
      this.onHeightChange(Number(newHeight.toFixed(2)), Number(this.intersection.z.toFixed(2)));
    } 
  };

  copyPosition = (vec2, height) => {
    this.object.position.copy(new THREE.Vector3(vec2.x, vec2.y, height));
    this.largeObject.position.copy(new THREE.Vector3(vec2.x, vec2.y, height));
    if (this.largeObject.geometry.type === "ExtrudeGeometry") {
      this.largeObject.position.copy(
        new THREE.Vector3(
          vec2.x,
          vec2.y,
          height - (HeightControl.largeWidth - HeightControl.width) / 2
        )
      );
    }
  };

  setMaterialColor = () => {
    if (this.color) {
      this.object.material.color = new THREE.Color(this.color);
      this.largeObject.material.color = new THREE.Color(this.color);
    } else {
      this.object.material.color = new THREE.Color(
        this.isDark ? 0xffff00 : 0xff8c00
      );
      this.largeObject.material.color = new THREE.Color(
        this.isDark ? 0xffff00 : 0xff8c00
      );
    }
    this.object.material.needsUpdate = true;
    this.largeObject.material.needsUpdate = true;
  };

  setVisibility = (visible) => {
    super.setVisibility(visible);
    this.object.visible = visible;
    this.largeObject.visible = visible;
  };
}

export class EllipseControl extends SemanticControl {
  constructor(props) {
    super(props);
    this.height = props.height;

    this.onChange = (state) =>
      props.onChange({
        ...state,
        perimeter: EllipseControl.calculateCircumference(state),
      });
    this.filter = props.filter !== false;

    this.object = new THREE.Group();

    this.state = {};

    this.ellipseMaterial = new THREE.MeshBasicMaterial({
      color: new THREE.Color(this.isDark ? 0xffff00 : 0xff8c00),
      opacity: 0.4,
      transparent: true,
    });
    const circleGeometry = new THREE.CircleGeometry(
      EllipseControl.pointRadius,
      32
    );

    this.object.position.set(0, 0, props.height);

    this.circles = [];
    {
      const material = new THREE.MeshBasicMaterial({
        color: EllipseControl.pointColor,
      });
      const circle = new THREE.Mesh(circleGeometry, material);
      circle.position.set(0, 0, 0.01);
      circle.name = "point-center";
      this.object.add(circle);
      this.circles.push(circle);
      this.centerCircle = circle;
    }
    {
      const material = new THREE.MeshBasicMaterial({
        color: EllipseControl.pointColor,
      });
      const circle = new THREE.Mesh(circleGeometry, material);
      circle.position.set(0, 0, 0.01);
      circle.name = "point-rX";
      this.object.add(circle);
      this.circles.push(circle);
      this.rXCircle = circle;
    }
    {
      const material = new THREE.MeshBasicMaterial({
        color: EllipseControl.pointColor,
      });
      const circle = new THREE.Mesh(circleGeometry, material);
      circle.position.set(0, 0, 0.01);
      circle.name = "point-rY";
      this.object.add(circle);
      this.circles.push(circle);
      this.rYCircle = circle;
    }

    this.pointSize = props.pointSize;
    this.name = props.name;

    this.scene.add(this.object);
  }

  static ellipseColor = 0xffff00;
  static pointColor = 0xff0000;
  static activeColor = 0x00ffff;
  static clippingRadius = 0.2;
  static pointRadius = 0.2;
  static minRadius = 0.05;

  static calculateAngle(p1, p2) {
    return Math.atan2(p2.y - p1.y, p2.x - p1.x);
  }

  static calculateCircumference({ rX, rY }) {
    // Method 1
    // const h = Math.pow((rX - rY), 2) / Math.pow((rX+rY), 2);
    // return (Math.PI * (rX + rY)) * (1 + ((3 * h) / (10 + Math.sqrt(4 - (3 * h) )) ));

    // Method 2
    return 2 * Math.PI * Math.sqrt((rX ** 2 + rY ** 2) / 2);
  }

  setPosition = (pos, val) => {
    if (!val) return;
    const { dX, dY, rX, rY, rotation } = val;
    this.position = pos;
    this.object.position.copy(new THREE.Vector3(pos.x + dX, pos.y + dY, 100));
    this.renderEllipse({ rX, rY, rotation });
    this.state = { dX, dY, rX, rY, rotation };
    this.pointFilters = this.filter
      ? {
          minZ: this.height - EllipseControl.clippingRadius,
          maxZ: this.height + EllipseControl.clippingRadius,
        }
      : null;

    this.ellipseMaterial.color = new THREE.Color(
      this.isDark ? 0xffff00 : 0xff8c00
    );
    this.ellipseMaterial.needsUpdate = true;
  };

  renderEllipse = ({ rX, rY, rotation }) => {
    this.object.rotation.z = THREE.MathUtils.degToRad(rotation);

    this.circles.forEach((circle) => {
      const scale = 1 / this.camera.zoom;
      circle.scale.set(scale, scale, scale);
    });

    if (this.state.rX === rX && this.state.rY === rY) return;
    if (this.ellipse) this.object.remove(this.ellipse);

    const path = new THREE.Shape();
    path.absellipse(0, 0, rX, rY, 0, Math.PI * 2, false, 0);
    this.ellipse = new THREE.Mesh(
      new THREE.ShapeGeometry(path, 100),
      this.ellipseMaterial
    );
    this.object.add(this.ellipse);

    this.rXCircle.position.set(rX, 0, this.rXCircle.position.z);
    this.rYCircle.position.set(0, rY, this.rYCircle.position.z);
    this.centerCircle.position.set(0, 0, this.centerCircle.position.z);
  };

  handlePointerDown = (e) => {
    e.preventDefault();

    if (e.button !== 0) return;
    this.moving = true;

    this.plane.setFromNormalAndCoplanarPoint(
      this.camera.position.clone().normalize(),
      this.object.position
    );

    const intersects = this.updateIntersection(e, this.object.children);
    const point = intersects.find((i) => i.object.name.startsWith("point-"));
    if (point) {
      this.selPoint = point.object;
      this.selPoint.material.color.setHex(EllipseControl.activeColor);
      this.currentPoint.material.needsUpdate = true;
    }
    const mouseIntersect = new THREE.Vector2(
      this.intersection.x,
      this.intersection.y
    );

    this.oldAngle = EllipseControl.calculateAngle(
      this.object.position,
      this.intersection
    );
    this.oldRotation = THREE.MathUtils.degToRad(this.state.rotation);
    this.oldVector = mouseIntersect
      .clone()
      .sub(new THREE.Vector2(this.object.position.x, this.object.position.y));

    this.moving = true;
    this.onMovingChange(true);
  };

  handlePointerUp = (e) => {
    e.preventDefault();

    this.moving = false;
    this.onMovingChange(false);

    this.currentPoint = this.selPoint;
    this.selPoint = null;
  };

  handlePointerMove = (e) => {
    e.preventDefault();

    const intersects = this.updateIntersection(e, this.object.children);
    if (!this.selPoint) {
      const point = intersects.find((i) => i.object.name.startsWith("point-"));
      if (this.currentPoint !== point) {
        if (this.currentPoint) {
          this.currentPoint.material.color.setHex(EllipseControl.pointColor);
          this.currentPoint.material.needsUpdate = true;
          this.repaint();
          this.currentPoint = null;
        }
        if (point) {
          point.object.material.color.setHex(EllipseControl.activeColor);
          point.object.material.needsUpdate = true;
          this.currentPoint = point.object;
          this.repaint();
        }
      }
    }

    if (!this.moving) return;
    if (!e.ctrlKey && this.moveWithCtrl) return;

    e.stopPropagation();

    const mouseIntersect = new THREE.Vector2(
      this.intersection.x,
      this.intersection.y
    );
    const position = new THREE.Vector2(
      this.object.position.x,
      this.object.position.y
    );

    // Rotation
    if (!this.selPoint) {
      return this.onChange({
        ...this.state,
        rotation: THREE.MathUtils.radToDeg(
          this.oldRotation -
            (this.oldAngle -
              EllipseControl.calculateAngle(
                this.object.position,
                this.intersection
              ))
        ),
      });
    }

    // We need to calculate the angle between the old center->point and new center->point vector to decide if radius can be increased
    const a = mouseIntersect.clone().sub(position);
    const b = a.clone().multiply(this.oldVector);
    const angle = Math.acos(
      (b.x + b.y) / (this.oldVector.length() * a.length())
    );

    const distance = mouseIntersect.distanceTo(position);

    // Point controls
    if (this.selPoint.name === "point-center") {
      this.onChange({
        dX: this.intersection.x - this.position.x,
        dY: this.intersection.y - this.position.y,
        rX: this.state.rX,
        rY: this.state.rY,
        rotation: this.state.rotation,
      });
    } else if (this.selPoint.name === "point-rX") {
      this.onChange({
        dX: this.object.position.x - this.position.x,
        dY: this.object.position.y - this.position.y,
        rX: angle < Math.PI / 2 ? distance : EllipseControl.minRadius,
        rY: this.state.rY,
        rotation: this.state.rotation,
      });
    } else if (this.selPoint.name === "point-rY") {
      this.onChange({
        dX: this.object.position.x - this.position.x,
        dY: this.object.position.y - this.position.y,
        rX: this.state.rX,
        rY: angle < Math.PI / 2 ? distance : EllipseControl.minRadius,
        rotation: this.state.rotation,
      });
    }
  };
}

/**
 * @typedef SemanticControlOptions {Object}
 * @property scene {THREE.Scene}
 * @property onMovingChange {(isMoving: boolean) => unknown}
 * @property camera {THREE.Camera}
 * @property container {HTMLDivElement}
 * @property points {THREE.Points | undefined}
 * @property repaint {() => unknown}
 * @property isDark {boolean}
 * @property zoom {number}
 */

export class ClippingMaskControl extends SemanticControl {
  static _INITIAL_HEIGHT = 0.4;
  object = new THREE.Group();
  /** @type {THREE.Mesh} */
  _verticalMeasuringLine;
  /** @type (min: number, max: number) => unknown */
  _onChange;
  /** @type (min: number, max: number) => unknown */
  _onResize;
  /** @type {number} */
  _min;
  /** @type {number} */
  _max;
  /** @type {THREE.Vector4} */
  _initialPosition;
  _isResizing = false;
  width = 3;
  /** @type {number} */
  _color;

  /**
   * @param options {SemanticControlOptions & {onChange: (min: number, max: number) => unknown, min: number, max: number, initialPosition: THREE.Vector4, color: number}}
   */
  constructor(options) {
    super(options);

    this._onChange = options.onChange;
    this._onResize = options.onResize;
    this._min = options.min;
    this._max = options.max;
    this._initialPosition = options.initialPosition;
    this._color = options.color;

    this._initialize();
  }

  _initialize() {
    this.object.name = "ClippingMaskControl";

    const clippingMask = this._createRectangle(
      this.width,
      ClippingMaskControl._INITIAL_HEIGHT
    );
    const clippingMaskBorder = this._createBorder(
      this.width,
      ClippingMaskControl._INITIAL_HEIGHT
    );
    const middleLine = this._createDashedLine(
      new THREE.Vector3(-this.width / 2, 0, 0),
      new THREE.Vector3(this.width / 2, 0, 0),
      this._color
    );

    const upperHandle = this._createHandle();
    upperHandle.position.set(0, ClippingMaskControl._INITIAL_HEIGHT / 2, 0);

    const lowerHandle = this._createHandle();
    lowerHandle.position.set(0, -ClippingMaskControl._INITIAL_HEIGHT / 2, 0);

    this.object.add(
      clippingMask,
      upperHandle,
      lowerHandle,
      clippingMaskBorder,
      middleLine,
      this._verticalMeasuringLine
    );
    this.object.rotateX(-Math.PI / 2);
    this.object.rotation.y = this._initialPosition.w;
    this.object.position.set(
      this._initialPosition.x,
      this._initialPosition.y,
      this._initialPosition.z
    );

    this._verticalMeasuringLine = this._createDashedLine(
      new THREE.Vector3(this._initialPosition.x, 0, this._initialPosition.y),
      new THREE.Vector3(
        this._initialPosition.x,
        -this._initialPosition.z,
        this._initialPosition.y
      ),
      this._color
    );
    this._verticalMeasuringLine.rotateX(-Math.PI / 2);

    this.scene.add(this.object, this._verticalMeasuringLine);
  }

  handlePointerDown(e) {
    if (e.button !== 0) return;
    this._setMousePosition(e.clientX, e.clientY);
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.moving =
      this._isResizing ||
      this.object.children.some(
        (it) => this.raycaster.intersectObject(it).length > 0
      );

    this.onMovingChange(this.moving);

    this.handlePointerMove(e);
  }

  handlePointerMove(event) {
    if (!this.moving) return;

    this._setMousePosition(event.clientX, event.clientY);
    this.plane.setFromNormalAndCoplanarPoint(
      this.camera.position.clone().normalize(),
      this.object.position
    );
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);
    const verticalPosition = this.intersection.z;

    if (this._isResizing) {
      this._setHeight(Math.abs(verticalPosition - this.object.position.z));
    } else {
      if (verticalPosition < this._min || verticalPosition > this._max) return;
      this.setPosition(verticalPosition);
    }

    requestAnimationFrame(() => {
      this.repaint();
      const bounds = new THREE.Box3().setFromObject(this.object);
      if (!this._isResizing) {
        this._onChange(bounds.min.z, bounds.max.z);
      } else {
        this._onResize(bounds.min.z, bounds.max.z);
      }
    });
  }

  /**
   * @returns {{min: number, max: number}}
   */
  getBounds() {
    const bounds = new THREE.Box3().setFromObject(this.object);
    return { min: bounds.min.z, max: bounds.max.z };
  }

  handlePointerUp(event) {
    super.handlePointerUp(event);

    this._resetResizeHandles();
  }

  setVisibility(visible) {
    super.setVisibility(visible);
    this.object.children.forEach((it) => (it.visible = visible));
    this._verticalMeasuringLine.visible = visible;
  }

  /**
   * @param rotation {THREE.Euler}
   */
  setRotation(rotation) {
    this.object.rotation.copy(rotation);
  }

  /**
   * @param verticalPosition {number}
   */
  setPosition(verticalPosition, optionalHorizontalPosition) {
    const adjustedVerticalPosition = parseFloat(parseFloat(verticalPosition) + this.treePositionOffset);
    const fallbackPosition = this.object.position ?? this._initialPosition;
    const newPosition = {
      x: !isNaN(optionalHorizontalPosition?.x) ? optionalHorizontalPosition.x : fallbackPosition.x,
      y: !isNaN(optionalHorizontalPosition?.y) ? optionalHorizontalPosition.y : fallbackPosition.y,
      z: adjustedVerticalPosition
    };
    this.object.position.copy(newPosition);
    this.scene.remove(this._verticalMeasuringLine);
    const visibility = this._verticalMeasuringLine.visible;
    this._verticalMeasuringLine = this._createDashedLine(
      new THREE.Vector3(newPosition.x, 0, newPosition.y),
      new THREE.Vector3(
        newPosition.x,
        -newPosition.z,
        newPosition.y
      ),
      this._color
    );
    this._verticalMeasuringLine.visible = visibility;
    this._verticalMeasuringLine.rotateX(-Math.PI / 2);

    this.scene.add(this._verticalMeasuringLine);
  }

  /**
   * @param height {number}
   * @private
   */
  _setHeight(height) {
    const bounds = new THREE.Box3().setFromObject(this.object);
    const currentHeight = bounds.max.z - bounds.min.z;
    const minHeight = 0.05;
    const maxHeight = Math.max(
      Math.min(this.object.position.z, this._max - this.object.position.z),
      minHeight
    );
    const distance = Math.min(Math.max(height, minHeight), maxHeight);
    const originalHeight = currentHeight / this.object.scale.y;
    this.object.scale.setY((distance / originalHeight) * 2);
  }

  _resetResizeHandles() {
    this._isResizing = false;
    this.object.children.forEach(
      (it) =>
        it.hasOwnProperty("element") && it.element.classList.remove("active")
    );
  }

  _createHandle() {
    const point = new CSS2DObject(document.createElement("div"));
    point.element.classList.add("red-dot");

    point.element.addEventListener("mouseenter", () =>
      point.element.classList.add("active")
    );

    point.element.addEventListener("mouseleave", () => {
      if (this._isResizing) return;
      point.element.classList.remove("active");
    });

    point.element.addEventListener("pointerdown", (event) => {
      event.preventDefault();
      this._isResizing = true;
    });

    return point;
  }

  /**
   * @param mouseX {number}
   * @param mouseY {number}
   * @private
   */
  _setMousePosition(mouseX, mouseY) {
    const rect = this.container.getBoundingClientRect();
    this.mouse.x = ((mouseX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((mouseY - rect.top) / rect.height) * 2 + 1;
  }

  /**
   *
   * @param {number} width
   * @param {number} height
   * @return {THREE.Mesh}
   * @private
   */
  _createBorder(width, height) {
    const line = new MeshLine();
    line.setPoints([
      new THREE.Vector3(-width / 2, -height / 2, 0),
      new THREE.Vector3(width / 2, -height / 2, 0),
      new THREE.Vector3(width / 2, height / 2, 0),
      new THREE.Vector3(-width / 2, height / 2, 0),
      new THREE.Vector3(-width / 2, -height / 2, 0),
    ]);
    const material = new MeshLineMaterial({
      color: this._color,
      lineWidth: 4,
      sizeAttenuation: false,
      resolution: new THREE.Vector2(
        this.container.clientWidth,
        this.container.clientHeight
      ),
    });
    return new THREE.Mesh(line, material);
  }

  /**
   *
   * @param width {number}
   * @param height {number}
   * @return {THREE.Mesh}
   * @private
   */
  _createRectangle(width, height) {
    const geometry = new THREE.PlaneGeometry(width, height);
    const material = new THREE.MeshBasicMaterial({
      color: this._color,
      transparent: true,
      opacity: 0.2,
      side: THREE.DoubleSide,
    });
    return new THREE.Mesh(geometry, material);
  }

  /**
   *
   * @param startingPoint {THREE.Vector3}
   * @param endingPoint {THREE.Vector3}
   * @param color {number}
   * @return {THREE.Mesh}
   * @private
   */
  _createDashedLine(startingPoint, endingPoint, color) {
    const material = new MeshLineMaterial({
      color,
      lineWidth: 4,
      transparent: true,
      sizeAttenuation: false,
      dashArray: 1 / startingPoint.distanceTo(endingPoint) / 12,
      dashRatio: 0.5,
      resolution: new THREE.Vector2(
        this.container.clientWidth,
        this.container.clientHeight
      ),
      depthTest: false,
    });
    const line = new MeshLine();
    line.setPoints([startingPoint, endingPoint]);
    return new THREE.Mesh(line, material);
  }

  setVisibility() {}

  toggleVisibility(show) {
    this.object.visible = show;
    this.object.children.forEach((it) => {
      it.visible = show;
    });

    this._verticalMeasuringLine.visible = show;
  }
}

export class SinglePointControl extends SemanticControl {
  static _POINT_RADIUS = 0.5;
  static _ACTIVE_COLOR = 0x00ffff;
  static _DEFAULT_COLOR = 0xff0000;
  /** @type {THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>} */
  object = new THREE.Mesh(
    new THREE.CircleGeometry(SinglePointControl._POINT_RADIUS, 36),
    new THREE.MeshBasicMaterial({
      color: SinglePointControl._DEFAULT_COLOR,
      depthTest: false,
    })
  );
  /** @type {(x: number, y: number) => unknown} */
  _onChange;

  /**
   * @param {SemanticControlOptions & {onChange: (x: number, y: number) => unknown}} options
   */
  constructor(options) {
    super(options);

    this._onChange = options.onChange;
    this._init();
    this.rescaleObject();
  }

  _init() {
    this.object.renderOrder = 1000;
    this.scene.add(this.object);
  }

  handlePointerMove(event) {
    if (!this.moving) return;

    this._setPositionFromMousePosition(event);

    this.repaint();
  }

  rescaleObject() {
    const scale = Math.min(SinglePointControl._POINT_RADIUS / this.camera.zoom);
    this.object.scale.set(scale, scale, scale);
  }

  handlePointerDown(e) {
    e.preventDefault();

    if (e.button !== 0) return;
    this.moving = true;

    this.plane.setFromNormalAndCoplanarPoint(
      this.camera.position.clone().normalize(),
      this.object.position
    );

    this.moving = true;
    this.onMovingChange(true);

    this._setPositionFromMousePosition(e);

    this._setColor();

    this.repaint();
  }

  /**
   * @param event {PointerEvent}
   * @private
   */
  _setPositionFromMousePosition(event) {
    const rect = this.container.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);

    this.object.position.copy(this.intersection);
    this._onChange(this.object.position.x, this.object.position.y);
  }

  handlePointerUp(e) {
    super.handlePointerUp(e);
    this._setColor();
  }

  _setColor() {
    this.object.material.color.setHex(
      this.moving
        ? SinglePointControl._ACTIVE_COLOR
        : SinglePointControl._DEFAULT_COLOR
    );
  }
}
