import { FrontSide, Matrix3, Matrix3Tuple, Mesh, MeshBasicMaterial, PlaneGeometry, Vector3 } from 'three';

export interface PanoramaImageSphereOptions {
  cameraMatrix: Matrix3;
  directionVector: Vector3;
  upVector: Vector3;
  width: number;
  height: number;
  url: string;
  rawUrl?: string;
}

export class PanoramaImageSphere {
  public cameraMatrix: PanoramaImageSphereOptions['cameraMatrix'];
  public directionVector: PanoramaImageSphereOptions['directionVector'];
  public upVector: PanoramaImageSphereOptions['upVector'];
  public width: PanoramaImageSphereOptions['width'];
  public height: PanoramaImageSphereOptions['height'];
  public url: PanoramaImageSphereOptions['url'];
  public rawUrl: PanoramaImageSphereOptions['rawUrl'];

  constructor(options: PanoramaImageSphereOptions) {
    this.cameraMatrix = options.cameraMatrix;
    this.directionVector = options.directionVector;
    this.upVector = options.upVector;
    this.width = options.width;
    this.height = options.height;
    this.url = options.url;
    this.rawUrl = options.rawUrl;
  }

  public static createFromServerResponse(options: any) {
    const [width, height, fx, fy, cx, cy, url] = [
      options.Nx || options.nx || 0.0000000000001, // width
      options.Ny || options.ny || 0.0000000000001, // height         qki
      options.Fx || options.fx || 0.0000000000001, // Focal x
      options.Fy || options.fy || 0.0000000000001, // Focal y
      options.Cx || options.cx || 0.0000000000001, // Real center x
      options.Cy || options.cy || 0.0000000000001, // Real center y
      `${options.path || options.guf_path}`
    ];

    const directionVector = new Vector3().fromArray(options.direction.coordinates);
    const upVector = new Vector3().fromArray(options.up.coordinates);

    const cameraMatrix = new Matrix3().set(fx, 0, cx, 0, fy, cy, 0, 0, 1);

    return new PanoramaImageSphere({
      cameraMatrix,
      width,
      height,
      directionVector,
      upVector,
      url,
      rawUrl: options.rawUrl,
    });
  }

  public getRotationMatrix3() {
    const r2 = this.upVector.clone().negate();
    const r1 = this.directionVector.clone().cross(this.upVector.clone());
    const r3 = r1.clone().cross(r2);

    return new Matrix3().set(r1.x, r2.x, r3.x, r1.y, r2.y, r3.y, r1.z, r2.z, r3.z).transpose();
  }

  public createTileVectors(distance: number) {
    const multiplyMatrix = (t1: any, t2: any) => [
      t1[0] * t2[0] + t1[3] * t2[1] + t1[6] * t2[2],
      t1[1] * t2[0] + t1[4] * t2[1] + t1[7] * t2[2],
      t1[2] * t2[0] + t1[5] * t2[1] + t1[8] * t2[2],
    ];

    const calculateVector = (t1: Matrix3Tuple, distance: number, matrix: Matrix3, x: number, y: number) => {
      const t2 = [x, y, 1];
      let p = multiplyMatrix(t1, t2);
      p = p.map((n) => n * (distance / p[2]));
      p = multiplyMatrix(matrix.clone().transpose().toArray(), p);

      return new Vector3(...p);
    };

    const m = this.getRotationMatrix3();

    const t1 = this.cameraMatrix.clone().invert().toArray();

    return [
      calculateVector(t1, distance, m, 0, 0),
      calculateVector(t1, distance, m, this.width - 1, 0),
      calculateVector(t1, distance, m, 0, this.height - 1),
      calculateVector(t1, distance, m, this.width - 1, this.height - 1),
    ];
  }

  public createGeometry(distance: number) {
    return new PlaneGeometry().setFromPoints(this.createTileVectors(distance));
  }

  public createMaterial() {
    return new MeshBasicMaterial({
      side: FrontSide,
    });
  }

  public createObject(distance: number) {
    return new Mesh(this.createGeometry(distance), this.createMaterial());
  }

  public checksum() {
    return btoa(
      JSON.stringify({
        cameraMatrix: this.cameraMatrix,
        directionVector: this.directionVector,
        upVector: this.upVector,
        width: this.width,
        height: this.height,
        url: this.url,
      })
    );
  }
}
