import React from 'react';

const Progress = ({ value = 0, label }) => {
  return (
    <div className="progress-wrapper">
      <div className="bar-container">
        <span className="label">{Math.round(value * 10000) / 100}%</span>
        <div className="bar" style={{ width: `${value * 100}%` }} />
      </div>
      <span className="label">{ label }</span>
    </div>
  )
}

export default Progress;