import React from 'react';

const Button = ({ label, value, onChange = () => {}, onPureChange = () => {}, name, disabled, color }) => {
  const _handleChange = () => {
    onPureChange(!value);
    onChange({ name, value: !value })
  }

  return (
    <div className={`checkbox-input-wrapper regular-button-states ${disabled ? 'disabled' : ''} ${value ? 'active' : 'inactive'}`} onClick={_handleChange}>
      <div className="checkbox-wrapper">
        <div className="knob" style={{ background: color }}></div>
      </div>
      <span className="label">{label}</span>
    </div>
  )
}

export default Button;
