import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Icon, Panorama } from '..';
import { LoaderWrapper } from '../../layout';
import { useConfig } from '../../providers/config';
import { RECLASS_TOOLS } from '../../routes/Validation/SegmentationValidation';
import { Button, Color, DropdownInput, Slider, Toggle } from '../inputs';
import ClassOption, { ClassOptionConfig } from './ClassOption';
import SegmentationMover, { VIEW } from './SegmentationMover';
import ValidationTool from '../../@types/enums/ValidationTool';
import ClickableBadge from './ClickableBadge';
import ViewWrapper from './Views/ViewWrapper';
import { PointCloudViewType } from '../../@types/enums/PointCloudViewType';
import ValidationAction from '../../@types/enums/ValidationAction';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import { useSegmentationScreenContext } from '../../hooks/betaHooks/segmentation-screen-context';
import { useTheme } from '../../providers/theme';
import { useModal } from '../../providers/modal';

/* const generateUrl = (id) =>
  `${window.location.pathname.split("/").slice(0, 3).join("/")}/location/${id}`; */
const SHOWN_FIRST_BIFURCATION_TOAST = 'hasShownFirstBifurcationToast';

export const exactFirstBifurcationRangeOffset = 0.2;

/**
 * @typedef {Object} ExactFirstBifurcationUpdatePayload
 * @property {number | undefined} min
 * @property {number | undefined} max
 * @property {number | undefined} x
 * @property {number | undefined} y
 * @property {number | undefined} z
 */

const SegmentationEditor = ({
  loading,
  currentTree,
  selection,
  environmentToggleProps,
  selectTree,
  layerSources,
  saveLAZ,
  setRotationHandler,
  updateGeometry,
  isQualityCheck,
  mapLayers,
  sourceVisible,
  filterLayers,
  layerVisible,
}) => {
  const {
    helper: { toasts, errorTool, errorPoints, showToast, lockView, editing, setEditing, pointCloudCorrectedPosition, setActiveTool, viewerPosition, setPanoramicLoading },
    reclass: { actionMode, reclassStates, reclassTool, changeReclassTool, brushRadius, setBrushRadius, reclassPoints, setReclassPoints },
    error: { changeErrorTool, addErrorPoint, handleSaveError, handleCancelError, handleErrorUndo },
    pointCloudModule: {
      pointCloud: pointcloud,
      environmentPointCloud,
      isEnvironmentVisible,
      setConfigColor,
      configItems,
      pointClassStyles,
      toggleOpacity
    },
    section: {
      setTarget: setSectionTarget,
      setNormal: setSectionNormal,
      setDefaultDepth
    },
    treeMetrics
  } = useSegmentationScreenContext();
  const { isDark } = useTheme();
  const background = isDark ? 0x000000 : 0xffffff;
  const { presentModal, dismissModal } = useModal();

  const hasSeenFirstBifurcationToast = sessionStorage.getItem(SHOWN_FIRST_BIFURCATION_TOAST) === 'true';
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const view = searchParams.get('view') ?? VIEW.DEFAULT;
  const { getConfig } = useConfig();
  const [isPanoramic, setIsPanoramic] = useState(true);


  const [pointSize, setPointSize] = useState(0.5);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [activeEditor, setActiveEditor] = useState('');
  const [reclassifySource, setReclassifySource] = useState(20);
  const [reclassifyTarget, setReclassifyTarget] = useState(21);
  const [showMeasurementEllipse, setShowMeasurementEllipse] = useState(false);

  useEffect(() => {
    if (!currentTree?.id && loading) return;
    setActiveTool(null);
    setShowMeasurementEllipse(false);
  }, [currentTree?.id]);

  const reclassSaveDisabled = useMemo(
    () => actionMode !== ValidationTool.Reclassify || !reclassStates.length,
    [actionMode, reclassStates.length]
  );

  const onColorChange = ({ name, value }) => {
    let pointcloudColors = {};
    const pointcloudColorsStr = localStorage.getItem('pointcloud-colors');
    if (pointcloudColorsStr) {
      pointcloudColors = JSON.parse(pointcloudColorsStr);
    }
    pointcloudColors[name] = value;
    localStorage.setItem('pointcloud-colors', JSON.stringify(pointcloudColors));
    setConfigColor(name, value);
  };

  const onColorVisibilityChange = ({ name, value }) => {
    toggleOpacity(name, value);
  };

  const opacities = { canopy: pointClassStyles.canopy.opacity, trunk: pointClassStyles.trunk.opacity }

  useEffect(() => {
    if (hasSeenFirstBifurcationToast || view !== VIEW.FIRST_BIFURCATION) return;
    sessionStorage.setItem(SHOWN_FIRST_BIFURCATION_TOAST, 'true');
  }, [hasSeenFirstBifurcationToast, view]);

  const toggleMeasurementEllipse = () => {
    if (!showMeasurementEllipse) {
      setDefaultDepth();
      setActiveTool(ValidationAction.Girth);
    } else {
      setActiveTool(null);
    }
    setShowMeasurementEllipse((prevState) => !prevState);
  };

  // --- minimap handling
    const mapRef = useRef(null);

    const maLayer = {
      id: 'mas',
      source: 'mas',
      type: 'fill',
      color: '#082',
      opacity: 0.32,
    };
  
    const treeLayers = [
      {
        id: TreeFlowStatus.SegmentationValidationQueued,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.SegmentationValidationQueued],
        color: '#ff0000',
      },
      {
        id: TreeFlowStatus.SegmentationValidationDone,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.SegmentationValidationDone],
        color: '#AFFF14',
      },
      {
        id: TreeFlowStatus.SegmentationValidationDeleted,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.SegmentationValidationDeleted],
        color: '#666',
      },
      {
        id: TreeFlowStatus.SentToField,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'tree_flow_status'], TreeFlowStatus.SentToField],
        color: '#ffff00',
      },
    ].map((layer) => {
      const config = getConfig(`statuses.${layer.id}`);
      return {
        ...layer,
        onClick: (centre, feature, id) => {
          selectTree(feature.id);
        },
        label: config?.label || 'Unnamed',
        color: getConfig(`colors.${config?.color}`) || '#08f',
      };
    });
  
    const layers = [maLayer, ...treeLayers];

  // --- minimap handling

  return (
    <LoaderWrapper loading={loading}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        {view === VIEW.FIRST_BIFURCATION && !hasSeenFirstBifurcationToast && (
          <div className='toast'>Click anywhere to place the first bifurcation point</div>
        )}

        {Boolean(toasts.length) && (
          <div className='toast-wrapper'>
            {toasts.map((toast, index) => (
              <div key={index} className={`toast ${toast.type} ${toasts.length - 1 === index ? 'last' : ''}`}>
                {toast.message}
              </div>
            ))}
          </div>
        )}

        {(pointcloud || pointcloud === false) && (
          <Fragment>
            {actionMode === ValidationTool.Inspection_View && (
              <div className='semantic-upper-toolbar inspection-view'>
                <span>
                  <b>Inspection View</b>
                </span>
              </div>
            )}
            {actionMode === ValidationTool.Reclassify && (
              <div className='semantic-upper-toolbar reclassify'>
                <div className='tools-wrapper'>
                  <span>Tool</span>
                  <ClickableBadge
                    icon={'paint-brush'}
                    activeColor={'green'}
                    selected={reclassTool === RECLASS_TOOLS.BRUSH}
                    onClick={() => changeReclassTool(RECLASS_TOOLS.BRUSH)}
                  />
                  <ClickableBadge
                    icon={'vector-square'}
                    activeColor={'green'}
                    selected={reclassTool === RECLASS_TOOLS.POLYGON}
                    onClick={() => changeReclassTool(RECLASS_TOOLS.POLYGON)}
                  />
                </div>
                <span>Source class</span>
                <DropdownInput
                  options={ClassOptionConfig}
                  value={reclassifySource}
                  onPureChange={setReclassifySource}
                  optionComponent={ClassOption}
                  small
                />
                <span>Target class</span>
                <DropdownInput
                  options={ClassOptionConfig.filter((o) => o.value !== reclassifySource)}
                  value={reclassifyTarget}
                  onPureChange={setReclassifyTarget}
                  optionComponent={ClassOption}
                  small
                />
                {reclassTool === RECLASS_TOOLS.BRUSH && (
                  <div className='brush-size-input-wrapper'>
                    <span>Brush</span>
                    <Slider
                      valueFormatter={(val) => val?.toFixed(0) ?? '-'}
                      label='Brush size'
                      value={brushRadius}
                      onPureChange={setBrushRadius}
                      min={12}
                      max={60}
                      step={12}
                    />
                  </div>
                )}
                <Button label='Save changes' disabled={reclassSaveDisabled} onClick={saveLAZ} />
              </div>
            )}
            <div className='semantic-panorama-toggle'>
              <Toggle {...environmentToggleProps} />
              <Toggle value={isPanoramic} onPureChange={() => setIsPanoramic((old) => !old)} label='Toggle Panorama' />
            </div>
            <div className='segmentation-views-container'>
              <SegmentationMover
                useMouseLeftButtonForEdit                                                                             // REALLY USED
                view={view}
                tree={currentTree}
                // Not needed
                pointcloud={pointcloud}
                actionMode={actionMode}                                                                               // REALLY USED
                editorName='height' // Will become obsolete, panels will be identified via ID                         // REALLY USED
                // -> Moved to ValidationSlice
                pointSize={pointSize}
                colors={configItems}
                opacities={opacities}
                azimuthAngle={azimuthAngle}
                setAzimuthAngle={setAzimuthAngle}                                                                     // REALLY USED
                setActiveEditor={setActiveEditor} // Renamed to setActivePanel, will use ID instead of name           // REALLY USED
                activeEditor={activeEditor} // Renamed to activePanelId, will use numerical ID instead of name
                // -> Moved to ReclassSlice
                brushRadius={brushRadius}
                reclassifySource={reclassifySource}
                reclassifyTarget={reclassifyTarget}
                reclassTool={reclassTool}
                // Not needed, will be rewrote
                firstBifurcationDelta={treeMetrics.firstBifurcationDelta}                                             // REALLY USED
                position={pointCloudCorrectedPosition}                                                                // REALLY USED
                // setPosition={changePosition}                                                                       // NOT USED
                exactFirstBifurcation={treeMetrics.exactFirstBifurcation}
                onExactFirstBifurcationChange={treeMetrics.onExactFirstBifurcationChange}
                // rest of the props currently passed down, below
                getConfig={getConfig}                                                                                 // REALLY USED
                isDark={isDark}                                                                                       // REALLY USED
                background={background}                                                                               // REALLY USED
                setRotationHandler={setRotationHandler}
                editing={editing}                                                                                     // REALLY USED
                setEditing={setEditing}                                                                               // REALLY USED
                height={treeMetrics.height}
                onHeightChange={treeMetrics.heightHandler}
                onTrunkHeightChange={treeMetrics.trunkHeightHandler}
                canopyHeight={treeMetrics.canopyHeight}
                onCanopyHeightChange={treeMetrics.canopyHeightHandler}
                canopy={treeMetrics.canopy}
                onCanopyChange={treeMetrics.canopyHandler}
                girth1={treeMetrics.girth1}
                onGirth1Change={treeMetrics.girth1Handler}
                environmentPointcloud={environmentPointCloud}
                isEnvironmentVisible={isEnvironmentVisible}
                reclassPoints={reclassPoints}
                setReclassPoints={setReclassPoints}                                                             // REALLY USED
                updateGeometry={updateGeometry}
                errorTool={errorTool}
                changeErrorTool={changeErrorTool}
                errorPoints={errorPoints}
                addErrorPoint={addErrorPoint}
                saveError={handleSaveError}
                cancelError={handleCancelError}                                                                       // REALLY USED
                handleErrorUndo={handleErrorUndo}
                showToast={showToast}
                // isUp={false}                                                                                       // REALLY USED
                setBrushRadius={setBrushRadius}                                                                       // WAS NOT PASSED DOWN, BUT USED
                lockView={lockView}                                                                                   // WAS NOT PASSED DOWN, BUT USED
              />
              <div className='grid-right-side'>
                {isPanoramic ? (
                  <Panorama
                    onTreeChange={selectTree}
                    treeId={currentTree?.id}
                    managedAreaCode={selection?.[0]?.code}
                    optionalPosition={null}
                    enableAddTree={false}
                    setLoading={setPanoramicLoading}
                    mapSources={layerSources}
                    sourceVisible={sourceVisible}
                    mapLayers={isQualityCheck ? mapLayers : layers}  
                    layerVisible={layerVisible}
                    filterLayers={filterLayers}
                    enabledMiniMap={true}
                    mapRef={mapRef}
                    enableGoogleMapsButton={true}
                  />
                ) : (
                  <>
                    <SegmentationMover
                      position={pointCloudCorrectedPosition}
                      // setPosition={changePosition}                                                             // NOT USED
                      pointcloud={pointcloud}
                      editing='canopy'
                      isUp
                      useMouseLeftButtonForEdit
                      colors={configItems}
                      pointSize={pointSize}
                      azimuthAngle={azimuthAngle}
                      setAzimuthAngle={setAzimuthAngle}
                      activeEditor={activeEditor}
                      setActiveEditor={setActiveEditor}
                      editorName='canopy'
                      opacities={opacities}
                      view={view}
                      presentModal={presentModal}
                      dismissModal={dismissModal}
                      tree={currentTree}
                      actionMode={actionMode}
                      // rest of the props currently passed down
                      getConfig={getConfig}
                      isDark={isDark}
                      background={background}
                      setRotationHandler={setRotationHandler}
                      setEditing={setEditing}
                      height={treeMetrics.height}
                      onHeightChange={treeMetrics.heightHandler}
                      onTrunkHeightChange={treeMetrics.trunkHeightHandler}
                      canopyHeight={treeMetrics.canopyHeight}
                      onCanopyHeightChange={treeMetrics.canopyHeightHandler}
                      canopy={treeMetrics.canopy}
                      onCanopyChange={treeMetrics.canopyHandler}
                      girth1={treeMetrics.girth1}
                      onGirth1Change={treeMetrics.girth1Handler}
                      environmentPointcloud={environmentPointCloud}
                      isEnvironmentVisible={isEnvironmentVisible}
                      reclassPoints={reclassPoints}
                      setReclassPoints={setReclassPoints}
                      updateGeometry={updateGeometry}
                      errorTool={errorTool}
                      changeErrorTool={changeErrorTool}
                      errorPoints={errorPoints}
                      addErrorPoint={addErrorPoint}
                      saveError={handleSaveError}
                      cancelError={handleCancelError}
                      handleErrorUndo={handleErrorUndo}
                      showToast={showToast}
                      setBrushRadius={setBrushRadius}                                                          // WAS NOT PASSED DOWN, BUT USED
                      lockView={lockView}                                                                      // WAS NOT PASSED DOWN, BUT USED
                    />
                  </>
                )}
                {pointcloud && (
                  <div className='girth-section-container'>
                    <ViewWrapper
                      currentTree={currentTree}
                      hideTabSelector={true}
                      initialView={PointCloudViewType.SECTION}
                      disabled={currentTree?.tree_flow_status === TreeFlowStatus.MeasurementValidationQueued}
                      minZoom={40}
                      maxZoom={800}
                    />
                    <div className='measurement-ellipse-toggle'>
                      <Toggle value={showMeasurementEllipse} onPureChange={toggleMeasurementEllipse} label='Show Ellipse' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
        <div className='semantic-pc-controls'>
          <div className='semantic-pc-colors'>
            <Color label='Canopy' name='canopy' onChange={onColorChange} value={configItems.canopy} key='canopy' />
            <Toggle name='canopy' onChange={onColorVisibilityChange} label='' value={pointClassStyles.canopy.opacity} />
            <Color label='Trunk' name='trunk' onChange={onColorChange} value={configItems.trunk} key='trunk' />
            <Toggle name='trunk' onChange={onColorVisibilityChange} label='' value={pointClassStyles.trunk.opacity} />
            <span>Point size</span>
            <Slider
              valueFormatter={(val) => val?.toFixed(2) ?? '-'}
              label='Point size'
              value={pointSize}
              onPureChange={setPointSize}
              min={0.5}
              max={3}
              step={0.5}
            />
          </div>
          {lockView && <Icon className='error-lock' icon='lock' />}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default SegmentationEditor;
