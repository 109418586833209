import { useCallback, useMemo, useState } from 'react';
import { ColumnType } from '../../@types/Table';
import { tableSorter } from '../../components/Table/helpers';
import { TableConfig } from '../../components/Table/Table';

export interface TableSortOptions {
  key: string;
  direction: 1 | -1 | undefined;
}

export interface BetaTableSorterProps {
  data: any[];
}

export interface BetaTableSorterValue {
  sortedData: any[];
  tableSortOptions: TableSortOptions;
  handleTableSortChange: (tableSortOptions: TableSortOptions) => void;
  handleTableConfigChange: (config: TableConfig) => void;
}

const useBetaTableSorter = ({ data }: BetaTableSorterProps): BetaTableSorterValue => {
  const [tableSortOptions, setTableSortOptions] = useState<TableSortOptions>({ key: 'id', direction: undefined });
  const [config, setConfig] = useState<TableConfig>();

  const handleTableSortChange = useCallback((tableSortOptions: TableSortOptions) => {
    setTableSortOptions(tableSortOptions);
  }, []);

  const handleTableConfigChange = useCallback((config: TableConfig) => {
    setConfig(config);
  }, []);

  const sortedData = useMemo(() => {
    if (!config || !tableSortOptions) return [];

    const sortKey = tableSortOptions.key;
    const sortDir = tableSortOptions.direction;
    const column = config.columns.find((column: ColumnType) => column.key === sortKey);
    return tableSorter(data, sortDir, column!, Object.byResolver);
  }, [data, config, tableSortOptions])

  return {
    sortedData,
    tableSortOptions,
    handleTableSortChange,
    handleTableConfigChange
  };
};

export default useBetaTableSorter;
