import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionParams } from '../../@types/Action';
import { MetaKeyType } from '../../@types/MetaKey';
import { User } from '../../@types/User';
import { useNotifications } from '../../hooks';
import { useModal } from '../../providers/modal';
import { UserDropdown } from '../Dropdowns';
import { Preferences } from '../modals';
import Action from './Action';

const getName = (user: User) => (!user ? 'NA' : user?.name);

const METAKEYS: MetaKeyType = {
  ALT: 'altKey',
  CTRL: 'ctrlKey',
  CMD: 'metaKey',
};

const GENERATE_ACTIONS = ({ presentModal, user, history, dismissModal }: ActionParams) =>
  [
    user && {
      dropdownContent: <UserDropdown name={getName(user)} />,
      image: `https://eu.ui-avatars.com/api/?name=${getName(user)}`,
      title: 'User preferences',
      action: () =>
        presentModal({
          title: 'User preferences',
          content: <Preferences history={history} dismissModal={dismissModal} />,
        }),
      accelerator: ['CMD+Comma', 'CTRL+KeyU'],
    },
  ].filter((_) => !!_);

type ActionParamsProp = ActionParams & RouteComponentProps;

const Actions = ({ history, location, user }: ActionParamsProp) => {
  const { presentModal, dismissModal } = useModal();
  const { isNewNotification } = useNotifications();
  const [actions] = useState(
    GENERATE_ACTIONS({
      presentModal,
      user,
      history,
      location,
      dismissModal,
      isNewNotification
    })
  );

  const _checkAccelerator = (action: ActionParams, e: any): any => {
    if (Array.isArray(action.accelerator))
      return action.accelerator.forEach((accelerator: string) => _checkAccelerator({ ...action, accelerator }, e));
    const keys = action?.accelerator?.split('+');
    const meta = keys?.map((key: string) => METAKEYS[key]).filter((meta) => !!meta)[0];
    const keyCode = keys?.filter((key) => !METAKEYS[key])[0];
    if (e.code === keyCode && (meta ? e[meta] : true)) {
      e.preventDefault();
      e.stopPropagation();
      action.action?.();
    }
  };

  const _handleKeyDown = (e: any) =>
    actions
      .filter((action) => action && !!action.accelerator)
      .forEach((action) => {
        if (action !== null) {
          _checkAccelerator(action as ActionParams, e);
        }
      });

  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown);
    return () => window.removeEventListener('keydown', _handleKeyDown);
  }, []);
  
  return (
    <div className='actions-wrapper'>
      {actions.map((action: any) => (
        <Action key={`${action.title}-${action.icon}`} {...action} />
      ))}
    </div>
  );
};

export default withRouter(Actions);
