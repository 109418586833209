import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DropDownOption } from '../Map/TableFilter';
import QCStatuses from '../../@types/enums/QualityCheckStatus';

interface Props {
  label: string;
  options: DropDownOption[];
  value: (string | QCStatuses | 'all' | 'reset')[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (value: (string | QCStatuses | 'all' | 'reset')) => void;
}

export const CustomMultiSelect = ({ label, options, value, isOpen, setIsOpen, onChange }: Props) => {
  const renderOptions = () =>
    options.map((option) => {
      return (
        <MenuItem key={option.id} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  return (
    <div className='filter-multi-select-component'>
      <FormControl>
        <InputLabel
          id='select-label'
          style={{
            color: '#fff',
          }}
          className='filter-select-label'
        >
          {label}
        </InputLabel>
        <Select
          multiple
          labelId='select-label'
          id='multi-select'
          value={value}
          onChange={(e) => onChange(e?.target?.value?.toString() as (string | QCStatuses | 'all' | 'reset'))}
          autoWidth
          open={isOpen}
          onClick={(e) => handleClick(e)}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </div>
  );
};
