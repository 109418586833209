import { ObjectValues } from '../defaults';

export const QC_STATUSES = {
  QC_PENDING: 'QC_PENDING',
  QC_APPROVED: 'QC_APPROVED',
  QC_MODIFIED: 'QC_MODIFIED',
  QC_ERROR: 'QC_ERROR',
} as const;

/* enum QC_STATUSES {
  QC_PENDING = 'qc_pending',
  QC_PASSED = 'qc_passed',
  QC_FAILED = 'qc_failed',
  QC_SKIPPED = 'qc_skipped',
  QC_REJECTED = 'qc_rejected',
  QC_MANUAL_REVIEW = 'qc_manual_review',
} */

/* NEW STATUS IDEAS

QC_PENDING remain the same
QC_APPROVED will be QC_APPROVED -> this means that the tree was inspected successfully
but nothing has to be changed.
QC_MODIFIED will be QC_MODIFIED -> this means that the tree was inspected successfully
but some changes have to be made.
QC_ERROR will be the same -> this means that the tree was inspected successfully
but it needs to be offline annotated or some jobs have to be ran on it.

*/

type QCStatuses = ObjectValues<typeof QC_STATUSES>;

export default QCStatuses;
