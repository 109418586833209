import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const TabView = ({ history, location, tabs = [], id = '', active, onSelect = () => {}, onPureSelect = () => {}, disabled = [] }) => {
  const _handleSelect = (tab) => {
    onSelect({ name: id, value: tab });
    onPureSelect(tab);
  }

  useEffect(() => {
    if (tabs?.find(tab => tab.id === active)?.disabled) onPureSelect(tabs[0].id);
  }, [tabs])

  return (
    <div className="tab-view-wrapper">
      {
        tabs.map((tab) => <Tab key={tab.id} { ...tab } disabled={tab.disabled || disabled.includes(tab.id)} active={active === tab.id} onSelect={_handleSelect} />)
      }
    </div>
  )
}

const Tab = ({ label, onSelect = () => {}, active, id, disabled }) => {
  const _handleClick = () => onSelect(id);
  return (
    <div className={`tab-wrapper ${disabled ? 'disabled' : ''} ${active ? 'active' : 'inactive'}`} onClick={_handleClick}>
      <span className="label">{ label }</span>
    </div>
  )
}

export default withRouter(TabView);