import { useCallback, useEffect, useMemo, useRef } from 'react';
import TreePopup from '../../components/TreePopup';
import LocationValidation from '../Validation/LocationValidation';
import SegmentationValidation from './SegmentationValidation';
import TreeAdd from '../Validation/TreeAdd';
import SemanticValidation from './SemanticValidation';
import {
  BetaManagedAreasContextProvider,
  MANAGED_AREA_STATUSES,
  useBetaManagedAreasContext,
} from '../../hooks/betaHooks/beta-managed-areas-context';
import { BetaManagedAreaContextProvider, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import { ScreenTypes } from '../../hooks/betaHooks/beta-use-tree';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import TreeSelector from '../../components/HeaderBar/TreeSelector';

export const TODO_TREE_STATUSES = [
  TreeFlowStatus.LocationValidationQueued,
  TreeFlowStatus.SegmentationValidationQueued,
  TreeFlowStatus.MeasurementValidationQueued,
  TreeFlowStatus.SentToOnlineAnnotationQueued,
]

const typeOfValidation = (pipeline) => {
  if (pipeline?.current_manual_step === 'location_validation') return 'location';
  if (pipeline?.current_manual_step === 'semantic_validation') return 'semantic';
  if (pipeline?.current_manual_step === 'segmentation_validation') return 'segmentation';
  return null;
};

const layerFilter = [
  {
    id: 'sent_to_field',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.SentToField],
  },
  {
    id: 'validation_todo',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.LocationValidationQueued, TreeFlowStatus.SentToOnlineAnnotationQueued, TreeFlowStatus.SegmentationValidationQueued, TreeFlowStatus.MeasurementValidationQueued],
  },
  {
    id: 'validation_done',
    source: 'trees',
    type: 'circle',
    statuses: [
      TreeFlowStatus.LocationValidationDone,
      TreeFlowStatus.SegmentationValidationDone,
      TreeFlowStatus.MeasurementValidationDone,
      TreeFlowStatus.Completed,
    ],
  },
  {
    id: 'validation_deleted',
    source: 'trees',
    type: 'circle',
    statuses: [
      TreeFlowStatus.LocationValidationDeleted,
      TreeFlowStatus.SegmentationValidationDeleted,
      TreeFlowStatus.MeasurementValidationDeleted,
    ],
  },
];

const tableColumns =
  (pipeline, sortTrees, jumpToTree) =>
  (selection, proximityDataVisible = false, proximityAlertTreeIDs = []) => {
    return selection
      ? [
          {
            title: 'ID',
            key: 'id',
            resolver: 'id',
            sortable: true,
            onSort: sortTrees,
            format: 'ESS_ID',
            clickNavigation: (entry) => jumpToTree(entry, pipeline),
          },
          {
            title: 'Confidence level',
            key: 'confidence_level',
            resolver: (row) => {
              const validation = typeOfValidation(pipeline);
              if (validation === 'location') return row.location_confidence;
              if (validation === 'segmentation') return row.ml_instance_confidence;
              if (validation === 'semantic') return row.confidence_girth_ellipse;
              return null;
            },
            format: 'PERCENTAGE',
            sortable: true,
            onSort: (key, direction) => {
              const validation = typeOfValidation(pipeline);
              if (key === 'confidence_level') {
                if (validation === 'location') key = 'location_confidence';
                if (validation === 'segmentation') key = 'ml_instance_confidence';
                if (validation === 'semantic') key = 'confidence_girth_ellipse';
              }
              sortTrees(key, direction);
            },
          },
          ...(pipeline?.current_manual_step === 'segmentation_validation'
            ? [
                {
                  title: 'Confidence level 2',
                  key: 'confidence_level_2',
                  resolver: (row) => {
                    const validation = typeOfValidation(pipeline);
                    if (validation !== 'segmentation') return null;
      
                    return row.ml_semseg_confidence;
                  },
                  format: 'PERCENTAGE',
                  sortable: true,
                  onSort: (key, direction) => {
                    if (key === 'confidence_level_2') {
                      if (typeOfValidation(pipeline) !== 'segmentation') return null;
      
                      key = 'ml_semseg_confidence';
                    }
      
                    sortTrees(key, direction);
                  },
                },
              ]
            : []),
          {
            title: 'Status',
            resolver: 'tree_flow_status',
            key: 'tree_flow_status',
            format: 'STATUS',
            sortable: true,
            onSort: sortTrees,
          },
          {
            title: 'TMS Category',
            resolver: ({ tms_category }) => (tms_category ? tms_category.toUpperCase() : '-'),
            key: 'tms_category',
            sortable: true,
          },
          {
            title: 'Manually corrected',
            resolver: ({ manually_corrected }) => (manually_corrected ? 'YES' : 'NO'),
            key: 'manually_corrected',
            sortable: true,
          },
          {
            title: 'Comment',
            key: 'comment',
            resolver: ({ comment }) => (comment ? comment.map(({ value }) => value).join(', ') : '-'),
            sortable: true,
            onSort: sortTrees,
          },
          ...(proximityDataVisible
            ? [
                {
                  title: 'Proximity Alert',
                  key: 'proximity',
                  resolver: (it) => (proximityAlertTreeIDs.includes(it.id) ? 'issue' : 'correct'),
                  sortable: true,
                },
              ]
            : []),
          ...(pipeline?.current_manual_step === 'location_validation'
            ? [
                {
                  title: 'Actions',
                  sortable: false,
                  uniqueKey: 'action',
                  format: 'REVERT_STATUS_BUTTON',
                },
              ]
            : []),
        ]
      : [
          {
            title: 'Area code',
            key: 'code',
            resolver: 'code',
            format: 'CODE',
            sortable: true,
          },
          {
            title: 'Status',
            key: 'status',
            resolver: 'current_manual_step',
            format: 'STATUS',
            sortable: true,
          },
          {
            title: 'OIC',
            key: 'officer_in_charge',
            resolver: (ma) => (ma.oic_full_name ? ma.oic_full_name : ma.oic_user_name) || '-',
            sortable: true,
          },
          {
            title: 'Tree number',
            key: 'tree_number',
            resolver: 'tree_number',
            sortable: true,
          },
          {
            title: 'Pending',
            key: 'pending',
            resolver: 'pending',
            sortable: true,
          },
          {
            title: 'Validated',
            key: 'validated',
            resolver: 'validated',
            sortable: true,
          },
          {
            title: 'Accepted',
            key: 'accepted',
            resolver: 'accepted',
            sortable: true,
          },
          {
            title: 'Sent to Field',
            key: 'sent_to_field',
            resolver: 'sent_to_field',
            sortable: true,
          },
          {
            title: 'Deleted',
            key: 'deleted',
            resolver: 'deleted',
            sortable: true,
          },
          {
            title: 'Manually corrected',
            key: 'manually_corrected',
            resolver: 'manually_corrected',
            sortable: true,
          },
        ];
  };

const steps = {
  location_validation: 'location',
  semantic_validation: 'semantics',
  segmentation_validation: 'segmentation',
};

const getHeaderTitle = (path) => {
  if (path.includes('location')) return 'Location';
  if (path.includes('semantics')) return 'Semantics';
  if (path.includes('segmentation')) return 'Segmentation';
  return 'Validation';
};

const ValidationRouterComponent = ({ location, history, match }) => {
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  useEffect(() => {
    betaMASCtx.reset();
    betaMASCtx.fetch();
  }, []);

  useEffect(() => {
    betaMACtx.reset();

    if (!managedAreaId) return;

    betaMACtx.fetch();
  }, [managedAreaId]);

  const mapRef = useRef(null);
  const pipeline = betaMACtx.pipeline;
  const managedArea = betaMACtx.managedArea;

  const betaMASCtxLoading = betaMASCtx.loading;

  const betaMACtxLoading = betaMACtx.loading || betaMACtx.loadingManagedAreaAndPipeline || betaMACtx.loadingTrees;

  const contextsLoadingState = betaMASCtxLoading || betaMACtxLoading;

  const selection = useMemo(() => [managedArea, managedArea?.id], [managedArea]);

  const layerFilters = useMemo(() => {
    return layerFilter.map((layer) => ({
      ...layer,
    }));
  }, []);

  const treePopup = useCallback(
    ({ feature, ...props }) => {
      const clickedTree = betaMACtx?.trees?.find((tree) => Number(tree.id) === Number(feature.id));

      const treeData = {
        ...feature,
        properties: {
          ...feature.properties,
          manual_scientific_name: clickedTree?.manual_scientific_name,
          maven_tree_id: clickedTree?.tree_id,
          spsc_nm: clickedTree?.spsc_nm,
        },
      };
      return <TreePopup tree={treeData} {...props} />;
    },
    [betaMACtx?.trees]
  );

  const jumpToTree = (tree, managedarea) => {
    const subPath = steps[managedarea?.current_manual_step];
    if (!subPath) return;
    history.push((window.location.pathname.split('/').slice(0, 4).join('/') + `/${subPath}/` + tree.id).replace('//', '/'));
  };

  const todoTreesFilter = (tree) => TODO_TREE_STATUSES.includes(tree.tree_flow_status);

  const generateNavigationTabs = useCallback((managedAreaId, managedAreaCode, treeId, onChangeTree) => {
    return [
      {
        type: 'link',
        path: `/validation`,
        active: true,
        onClick: () => history.push(`/validation`),
        icon: 'map',
        titleOnHover: 'Jump to the Organization',
      },
      {
        type: 'link',
        active: !!managedAreaId && !!managedAreaCode,
        onClick: () => history.push(`/validation/${managedAreaId}`),
        title: managedAreaCode,
        titleOnHover: 'Jump to the Managed Area',
      },
      {
        type: 'component',
        active: !!treeId,
        params: {
          treeId: treeId,
          onChangeTree,
        },
        component: TreeSelector,
      },
    ];
  }, [history]);

  const treesFilter = useCallback(({ tree_flow_status }) => {
    const statusLogic = 
      tree_flow_status?.toLowerCase().includes("queued") ||
      tree_flow_status?.toLowerCase().includes("done") ||
      tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationQueued ||
      (
        pipeline?.current_manual_step === MANAGED_AREA_STATUSES.LOCATION_VALIDATION &&
        (
          tree_flow_status?.toLowerCase().includes("deleted") || 
          tree_flow_status?.toLowerCase().includes("field")
        )
      )

    return statusLogic;
  }, [pipeline?.current_manual_step])

  return (
    <SkeletonWithManagedAreaMap
      basePath='validation'
      title={getHeaderTitle(location.pathname)}
      screens={[
        {
          title: `${managedArea?.code} | Segmentation | Validation`,
          path: '/validation/:MA/segmentation/:tree?',
          Component: SegmentationValidation,
        },
        {
          title: `${managedArea?.code} | Semantics | Validation`,
          path: '/validation/:MA/semantics/:tree?',
          Component: SemanticValidation,
        },
        {
          title: `${managedArea?.code} | Location Validation | Validation`,
          path: '/validation/:MA/location/new/:tree?',
          Component: TreeAdd,
        },
        {
          title: `${managedArea?.code} | Location Validation | Validation`,
          path: '/validation/:MA/location/:tree?',
          Component: LocationValidation,
        },
      ]}
      mapRef={mapRef}
      layerFilter={layerFilters}
      treePopup={treePopup}
      treesFilter={treesFilter}
      customColumns={tableColumns(pipeline, () => void 0, jumpToTree)}
      screenType={ScreenTypes.VALIDATION}
      selection={selection}
      contextsLoadingState={contextsLoadingState}
      isMicroClimate={false}
      todoTreesFilter={todoTreesFilter}
      generateNavigationTabs={generateNavigationTabs}
    />
  );
};

const ValidationRouter = (props) => {
  const managedAreaId = props.match.params?.MA ? parseInt(props.match.params?.MA) : null;

  return (
    <BetaManagedAreasContextProvider>
      <BetaManagedAreaContextProvider managedAreaId={managedAreaId}>
        <ValidationRouterComponent {...props} />
      </BetaManagedAreaContextProvider>
    </BetaManagedAreasContextProvider>
  );
};

export default ValidationRouter;
