import { memo } from 'react';
import Icon from '../Icon';

type TitleProps = {
  title: string;
  onSort: () => void;
  id: string;
  sort: { [key: string]: any };
  sortable?: boolean;
  subTitle?: boolean;
  subTitleAction?: (data: any) => void;
  data: any;
};

const Title = memo(({ title, onSort, id, sort, sortable, subTitle, subTitleAction, data }: TitleProps) => {
  const _handleAction = () => sortable && onSort();
  const _handleSubTitleAction: React.MouseEventHandler<HTMLDivElement> = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    subTitleAction && subTitleAction(data);
  };
  
  const sortUpActive = sort && sort.key === id && sort.direction > 0;
  const sortDownActive = sort && sort.key === id && sort.direction < 0;

  return (
    <th onClick={_handleAction}>
      <div className='title-wrapper'>
        {title}
        <div
          className={`sort-wrapper`}
          style={{ flexDirection: 'column', gap: '4px' }}
        >
          {sortable &&
            <>
              <Icon icon='sortUp' style={{ color: sortUpActive ? '#FFFFFF' : '#6B6B6B' }} />
              <Icon icon='sortDown' style={{ color: sortDownActive ? '#FFFFFF' : '#6B6B6B' }} />
            </>
          }
          {subTitle &&
            <div className='title-wrapper second-title-action' onClick={_handleSubTitleAction}>
              💾
            </div>
          }
        </div>
      </div>
    </th>
  );
});

export default Title;
