import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const RouteComponent = ({ ...props }) => {
  return (
    <Route
      { ...props }
      render={routeProps => <RouteContent { ...props } { ...routeProps } />}
    />
  )
}

const RouteContent = ({ title, component: Comp, render, ...props }) => {
  useEffect(() => {
    document.title = `${title}${title ? ' | ' : ''}RTMS`
  }, [title])
  if (render) return render(props);
  return Comp ? <Comp { ...props } /> : null;
}

export default RouteComponent;