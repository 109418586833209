import React, { useRef, useState, useEffect } from 'react';
import Icon from '../Icon';

const className = (className: string) => className || '';

interface Props {
  autoFocus?: boolean;
  type?: string;
  value?: string;
  onChange?: ({name, value}: {name: string | undefined, value: string}) => void;
  label?: string;
  onPureChange?: (value: string) => void;
  name?: string;
  error?: boolean;
  warning?: boolean;
  disabled?: boolean;
  leadingIcon?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any;
} 

const TextInput = ({ autoFocus, type, value, onChange = () => {}, label, onPureChange = () => {}, name, error, warning, disabled, leadingIcon, onFocus, onBlur, ...props }: Props) => {
  const input = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const _handleChange = ({ target: { value }}: {target: any}) => {
    onPureChange(value);
    onChange({ name, value })
  }

  const _handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };

  const _handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  useEffect(() => {
    if (!input?.current || autoFocus) return;

    autoFocus && setTimeout(() => input?.current && input.current.focus(), 100);
  }, [input?.current, autoFocus])

  return (
    <div className={`input-wrapper ${warning && className( 'warning')} ${error && className('error')} ${isFocused ? 'focused' : 'blured'} ${leadingIcon ? 'icon' : ''} ${disabled ? 'disabled' : ''}`}>
      { leadingIcon && <Icon icon={leadingIcon} />}
      <input
        autoFocus={autoFocus || false}
        ref={input}
        type={type}
        value={value || ''}
        onChange={_handleChange}
        onFocus={_handleFocus}
        onBlur={_handleBlur}
        placeholder={label}
        disabled={disabled}
        {...props}
      />
      { (error || warning) && <span className="alert">{ error || warning }</span> }
    </div>
  )
}

export default TextInput;
