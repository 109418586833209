import { useState, useEffect, useCallback } from 'react';
import { Icon, Popup } from '../../components';
import { Slider, Button, TextInput } from '../../components/inputs';
import { LoaderWrapper } from '../../layout';
import { useGlobalConfig } from '../../providers/data';

const VALUE_FORMATTERS = {
  length: (val) => (val?.toFixed(2) ?? '-') + ' m',
  day: (val) => (val?.toFixed(0) ?? '-') + ' days',
  min: (val) => (val?.toFixed(0) ?? '-') + ' mins',
  percent: (val) => (val?.toFixed(1) ?? '-') + ' %'
};


const CommentSettings = ({value, onChange}) => {
  const [availableComments, setAvailableComments] = useState(value);
  const [activeComment, setActiveComment] = useState();
  const [commentInputValue, setCommentInputValue] = useState('');

  useEffect(() => {setAvailableComments(value)}, [value])

  const resetState = () => {
    setActiveComment(null);
    setCommentInputValue('');
  }

  const onSelectComment = (comment) => {
    setActiveComment(comment);
    setCommentInputValue(comment);
  }

  const onSaveEdit = () => {
    if (commentInputValue !== '') {
      const newComments = [...availableComments];
      newComments.splice(availableComments.indexOf(activeComment), 1, commentInputValue);
      setAvailableComments(newComments);
      onChange({name: 'predefined-comments', value: newComments});
      resetState();
    }
  }

  const onSaveNewComment = () => {
    if (commentInputValue !== '' && !availableComments.includes(commentInputValue)) {
      const newComments = [...availableComments, commentInputValue];
      setAvailableComments(newComments);
      onChange({name: 'predefined-comments', value: newComments});
      setCommentInputValue('');
    }
  }

  const onDeleteComment = (comment) => {
    const index = availableComments.indexOf(comment);
    const newComments = [...availableComments];
    newComments.splice(index, 1);
    setAvailableComments(newComments);
    onChange({name: 'predefined-comments', value: newComments});
    if (comment === activeComment) {
      resetState();
    } 
  }

  return (
    <div className="slider-outer-wrapper">
      <div className="comment-list">
        {availableComments?.length > 0 && availableComments.map(c => (
        <div className={`comment-list-item ${activeComment === c ? 'active' : ''}`} key={c}>
          {c}
          <Button icon="pen" onClick={() => onSelectComment(c)}/>
          <Button icon="trash" onClick={() => onDeleteComment(c)}/>
        </div>))}
      </div>
      <TextInput type="text" label="insert new comment here" onPureChange={setCommentInputValue} value={commentInputValue}/>
      <div className="comment-list-buttons">
        <Button primary label="Save comment" onClick={activeComment ? onSaveEdit : onSaveNewComment}/>
        {activeComment && <Button destructive label="Cancel" onClick={resetState}/>}
      </div>
    </div>
  )
}

const PARAMETERS = [
  {
    key: 'overdue-notification-custom-timespan',
    label: 'Overdue notification custom timespan',
    description: 'Interval in days where notification is sent to user.',
    formatterType: 'day',
    min: 4,
    max: 30,
    step: 1,
  },
  {
    key: 'db-match-max-distance',
    label: 'DB match default max distance',
    description: 'Default value of distance in db matching.',
    formatterType: 'length',
    min: 0,
    max: 10,
    step: 0.1,
  },
  {
    key: 'db-match-max-girth',
    label: 'DB match default max girth difference',
    description: 'Default values of girth difference in db matching.',
    formatterType: 'length',
    min: 0,
    max: 1,
    step: 0.01,
  },
  {
    key: 'user-idle-timeout',
    label: 'User idle timeout',
    description: 'Default values of user activity idle time (sec).',
    formatterType: 'min',
    min: 1,
    max: 120,
    step: 1,
  },
  {
    key: 'location-validation-confidence-level-max',
    label: 'Location validation confidence level max',
    description: 'Location of trees above this confidence level can be automatically validated',
    formatterType: 'percent',
    min: 0,
    max: 100,
    step: 0.5,
  },
  {
    key: 'location-validation-confidence-level-min',
    label: 'Location validation confidence level min',
    description: 'Location of trees below this confidence level can be automatically deleted',
    formatterType: 'percent',
    min: 0,
    max: 100,
    step: 0.5,
  },
  {
    key: 'predefined-comments',
    label: 'Predefined comments',
    description: 'List of frequently used comments users can choose from beside free-text comment',
    Component: CommentSettings
  },
];

const AdministrationalParameters = () => {
  const [adminParams, setAdminParams] = useState(
    PARAMETERS.map((param) => ({ ...param, name: param.key, value: null }))
  );

  const { globalConfig, loaded, updateGlobalConfig } = useGlobalConfig();

  const loadParams = useCallback(
    () =>
      setAdminParams((params) =>
        params.map((param) => ({
          ...param,
          value:
            globalConfig?.find?.((config) => config.config_name === param.key)
              ?.value?.value || null,
        }))
      ),
    [globalConfig]
  );

  useEffect(() => {
    loadParams();
  }, [globalConfig, loadParams]);

  const saveParams = () => {
    updateGlobalConfig(
      adminParams.map((param) => ({
        config_name: param.key,
        value: { value: param.value },
      }))
    );
  };

  const onParameterChange = ({ name, value }) => {
    setAdminParams((params) => {
      const i = params.findIndex((param) => param.key === name);

      const newParams = [...params];
      newParams[i].value = value;

      return newParams;
    });
  };

  return (
    <div className="preferences-wrapper">
      <h1>Administrational Parameters</h1>
      <LoaderWrapper loading={!loaded}>
        <div className="parameters">
          {adminParams?.map((parameter) => (
            <Parameter {...parameter} onChange={onParameterChange} />
          ))}
        </div>
      </LoaderWrapper>

      <div className="button-container">
        <Button label="Reset" onClick={loadParams} disabled={!loaded} />
        <Button primary label="Save" onClick={saveParams} disabled={!loaded} />
      </div>
    </div>
  );
};

const Parameter = ({
  label,
  description,
  value,
  formatterType,
  onChange,
  name,
  min,
  max,
  step,
  Component
}) => {
  return (
    <div className="parameter-wrapper">
      <div className="label">
        <span className="label">{label}</span>
        <Popup title={description} direction="right">
          <div className="question">
            <Icon icon="question" />
          </div>
        </Popup>
      </div>
      {
        Component ? <Component value={value} onChange={onChange}/> : (
        <Slider
          name={name}
          value={value}
          min={min}
          max={max}
          valueFormatter={VALUE_FORMATTERS[formatterType]}
          onChange={onChange}
          step={step}
        />)
      }
    </div>
  );
};

export default AdministrationalParameters;
