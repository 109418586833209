import { Vector3 } from "three";
import { DEFAULT_ELLIPSE } from "../components/SemanticsValidation/Cards/TrunkDetailsCard/Constants";
import { EllipseWithNormalAndHeight, ExactFirstBifurcation } from "../@types/defaults";
import Ellipse from "../@types/Ellipse";
import { Girth } from "../@types/Girth";

export const getDefaultGirth = (): Girth => {
  return {
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    ...DEFAULT_ELLIPSE,
  };
};

export const getDefaultCanopyWithNormalAndHeight = (): EllipseWithNormalAndHeight => {
  return {
    dX: 0,
    dY: 0,
    rX: 1.8,
    rY: 2.2,
    rotation: 0,
    diameter: null,
    normal: new Vector3(0, 0, 1),
    height: 1,
  };
};

export const getDefaultCanopy = (): Ellipse => {
  return {
    dX: 0,
    dY: 0,
    rX: 1.8,
    rY: 2.2,
    rotation: 0.1,
    diameter: null,
  };
}

export const getDefaultTrunk = (tree_id: string) => {
  return {
    tree_id,
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    girths: [getDefaultGirth()],
  };
}

export const getDefaultGirthForSemanticMover = (): Ellipse => {
  return {
    dX: 0,
    dY: 0,
    rX: 1,
    rY: 2,
    rotation: 0,
    diameter: null,
  }
}

export const getDefaultExactFirstBifurcation = (): ExactFirstBifurcation => {
  return {
    min: 0.8,
    max: 1.2,
    x: 0,
    y: 0,
    z: 1,
  }
}

export const getDefaultFirstBifurcation = () => {
  return `{"type":"Point","coordinates":[0,0,0],"isInitial":true}`;
}