import { Vector3 } from "three";
import Number3 from "../@types/Number3";


export const DEFAULT_SECTION_DEPTH = .15;
export const DEFAULT_SECTION_HEIGHT = 100;
export const DEFAULT_AZIMUTH_ANGLE = 0;
export const DEFAULT_VIEWER_POSITION = new Vector3(0, 0, 0);
export const DEFAULT_TARGET_VECTOR = new Vector3(0, 0, 0);
export const DEFAULT_NORMAL_VECTOR = new Vector3(0, 0, 1);
export const DEFAULT_NUMBER_OF_STEMS = 0;
export const DEFAULT_TREE_HEIGHT = 3;
export const DEFAULT_CANOPY_HEIGHT = 1;
export const DEFAULT_CANOPY_VIEW_BOUNDARIES = [100, 100];
export const DEFAULT_LOCATION_POINT: Number3 = [0, 0, 0];
export const DEFAULT_BRUSH_TOOL_RADIUS = 12;
export const DEFAULT_TRUNK_HEIGHT = 0.7;
export const DEFAULT_TMS_CATEGORY = 'l0'