import Icon from "./Icon";
import Popup from "./Popup";
import { useMemo, useState } from "react";
import ConfirmationPopup from "./ConfirmationPopup/ConfirmationPopup";
import Progress from "./Progress";

interface Props {
  actions: any[];
  max: number;
  value: number;
  disabled?: boolean;
  hideProgressBar?: boolean;
}

interface ActionProps {
  icon: string;
  label: string;
  action: () => void;
  disabled?: boolean;
  color?: string;
  activeColor?: string;
  isActive?: boolean;
  tooltip: string;
  hasNotification: boolean;
  showConfirmationPopup: boolean;
  confirmationPopupText: string;
  hasChecked: boolean;
}

const ProgressControls = ({ actions = [], max, value, disabled = false, hideProgressBar = false}: Props) => {
  const currentValue = `00000000000${Math.max(0, value)}`.slice(
    -String(max).length
  );
  const label = `${currentValue} / ${max}`;
  const actionsOnBar = useMemo(() => {
    const target: any[] = [];

    actions.forEach((action, index) => {
      if (action?.type === "separator") {
        target.push(
          <Separator key={index} /> 
        );
      } else if (action) {
        target.push(<Action {...action} key={index} />);
      }
    });

    return target;
  }, [actions]);

  return (
    <div className={`progress-controls-wrapper ${disabled ? "disabled" : ""}`}>
      <div className="actions-wrapper">{actionsOnBar}</div>
      {!hideProgressBar && <Progress {...{ value: max ? value / max || 0 : 1, label }} />}
    </div>
  );
};

const Action = ({
  icon,
  label,
  action,
  disabled,
  color = "",
  activeColor,
  isActive,
  tooltip,
  hasNotification,
  showConfirmationPopup,
  confirmationPopupText,
  hasChecked
}: ActionProps) => {
  const [confirmationPopup, setConfirmationPopup] = useState(false);

  const handleConfirmationPopupState = () => {
    setConfirmationPopup(!confirmationPopup);
  }
  const actionContent = (
    <div
      className={`action-wrapper ${disabled ? "disabled" : ""} ${color} ${hasChecked ? 'checked' : 'not-checked'}`}
      onClick={showConfirmationPopup ? () => handleConfirmationPopupState() : action}
    >
      <Icon
        icon={icon}
        color={confirmationPopup ? 'red' : isActive ?  activeColor : ""}
        className="progress-icon"
      />
      {label && <span className="label">{label}</span>}
      {hasNotification && <div className="notification"></div>}
    </div>
  );


  const confirmationAction = showConfirmationPopup ?  (
    <div>
    <Popup direction="top" centerAlign={true} noHover={false} noDelay={undefined} active={confirmationPopup}>
      {confirmationPopup && <ConfirmationPopup action={action} handleConfirmationPopupState={handleConfirmationPopupState} title={confirmationPopupText}/>}
    </Popup>
    {actionContent}
    </div>
  ) : (
    actionContent
  );

  return tooltip ? (
    <Popup title={tooltip} direction="top">
      {confirmationAction}
    </Popup>
  ) : (
    confirmationAction
  );
};

const Separator = ({ content }: any) => {
  if (content) {
    return <div className="separator-content">{content}</div>;
  }

  return <div className="separator"></div>;
};

export default ProgressControls;
