import { ObjectValues } from "./defaults";

export const USER_ROLES = {
  ADMIN: "admin",
  DEFAULT: "default",
  GUEST: "guest",
  OFFICER: "officer",
  SUPERVISOR: "supervisor",
  QC_OFFICER: "qc_officer",
  // Add more roles as needed, e.g., "VIP", "VIP_PLUS"
} as const;

type UserRoles = ObjectValues<typeof USER_ROLES>;

export default UserRoles;