import { useState } from 'react';
import QCStatuses, { QC_STATUSES } from '../../@types/enums/QualityCheckStatus';
import Tree from '../../@types/Tree';
import { Button } from '../inputs';
import { CustomSelect } from '../inputs/Select';
import { DropDownOption } from '../Map/TableFilter';

interface Props {
  tree: Tree;
  handleStatusChange: (status: QCStatuses) => void;
  handleClose: () => void;
}

const QualityCheckDetails = ({ tree, handleStatusChange, handleClose }: Props) => {
  const [selectedQCStatus, setSelectedQCStatus] = useState<QCStatuses>(QC_STATUSES.QC_APPROVED);
  const [statusSelectIsOpen, setStatusSelectIsOpen] = useState(false);

  const options: DropDownOption[] = Object.entries(QC_STATUSES).map((status) => {
    const id = status[0];
    const label = status[1].replaceAll('_', ' ');
    const value = status[1];

    return { id, label, value };
  });

  return (
    <div className='qc-details-container'>
      <div>
        <p>
          <strong>Quality Check Status:</strong> {tree.qc_status}
        </p>
        <div>
          <p>
            <strong>Quality Check Issues:</strong>
          </p>
          <ul
            style={{
              listStyleType: 'disc',
              padding: 0,
              margin: 0,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              gap: '1vw',
            }}
          >
            {tree?.qc_issues?.map((issue: string, index: number) => (
              <div key={index} style={{ backgroundColor: 'rgba(236, 74, 74, 0.75)', padding: '1vh 1vw', borderRadius: '5px' }}>
                <span className='label'>{issue}</span>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <div className='qc-status-select'>
        <CustomSelect
          label='QC Status'
          options={options}
          value={selectedQCStatus}
          onChange={setSelectedQCStatus}
          isOpen={statusSelectIsOpen}
          setIsOpen={setStatusSelectIsOpen}
        />
      </div>
      <Button label='Update QC Status' primary onClick={() => {
          handleStatusChange(selectedQCStatus)
          handleClose()
        }} />
    </div>
  );
};

export default QualityCheckDetails;
