import { useFrame, useThree } from '@react-three/fiber';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { BufferGeometry, Color, DoubleSide, Mesh, MeshBasicMaterial, Vector2, Vector3 } from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { clamp } from 'three/src/math/MathUtils';
import config from '../../config';
import useForwardedRef from '../../hooks/useForwardedRef';
import useScale from '../../hooks/useScale';

type Props = {
  name: string;
  position0: Vector3;
  constrict?: {
    x?: [number, number];
    y?: [number, number];
  };
  onChange: (diff: Vector2, isDragEnd: boolean) => void;
  disabled: boolean;
  colors?: { point?: Color; border?: Color; hover?: Color };
  handleSize?: number;
  isChangeForPointEnabled: (pointName: string) => boolean;
};

const EllipseDragPoint = forwardRef<Mesh<BufferGeometry, MeshBasicMaterial>, Props>(
  ({ name, position0, constrict, onChange, disabled, colors, handleSize, isChangeForPointEnabled }: Props, fwdRef) => {
    const ref = useForwardedRef(fwdRef);
    const ringRef = useRef<any>(null);
    const isDragRef = useRef<boolean>(false);
    const controlsRef = useRef<DragControls | null>(null);
    const { camera, gl } = useThree();
    const { getScaledValue } = useScale();
    const [hover, setHover] = useState(false);

    const baseSize = handleSize ?? config.minHandleSize;

    const onDrag = (e: any) => {
      if (!isDragRef.current || !isChangeForPointEnabled(name)) return;

      e.object.position.x = clamp(e.object.position.x, constrict?.x?.[0] ?? -Infinity, constrict?.x?.[1] ?? Infinity);
      e.object.position.y = clamp(e.object.position.y, constrict?.y?.[0] ?? -Infinity, constrict?.y?.[1] ?? Infinity);
      e.object.position.z = 0;

      onChange(e.object.position, false);

      if (ringRef?.current) ringRef.current.position.copy(e.object.position);
      if (ref.current) ref.current.position.copy(e.object.position);
    };

    const onDragStart = (e: any) => {
      if (isChangeForPointEnabled(name)) {
        isDragRef.current = true
      };
    };

    const onDragEnd = (e: any) => {
      if (!isDragRef.current) return;
      e.object.position.x = clamp(e.object.position.x, constrict?.x?.[0] ?? -Infinity, constrict?.x?.[1] ?? Infinity);
      e.object.position.y = clamp(e.object.position.y, constrict?.y?.[0] ?? -Infinity, constrict?.y?.[1] ?? Infinity);
      e.object.position.z = 0;

      onChange(e.object.position, true);
      isDragRef.current = false;
    };

    const setMaterialColor = (material: MeshBasicMaterial, color?: Color) => (material.color = color ?? new Color('white'));

    // Set drag event listeners
    useEffect(() => {
      if (!ref.current || disabled) return;
      controlsRef.current = new DragControls([ref.current], camera, gl.domElement);

      controlsRef.current.addEventListener('drag', onDrag);
      controlsRef.current.addEventListener('dragstart', onDragStart);
      controlsRef.current.addEventListener('dragend', onDragEnd);

      return () => controlsRef.current?.dispose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    // Set scale
    useFrame(({ camera }) => {
      let scaledValue = getScaledValue(camera.zoom);
      if (hover && !disabled) scaledValue *= 1.4;
      if (ref.current) ref.current.scale.set(scaledValue, scaledValue, 1);
      if (ringRef?.current) ringRef.current.scale.set(scaledValue, scaledValue, 1);
    });

    return (
      <group>
        <mesh
          ref={ref}
          position={position0}
          name={name}
          renderOrder={998}
          frustumCulled={false}
          onPointerEnter={() => {
            setHover(true);
            setMaterialColor(ref.current!.material as MeshBasicMaterial, colors?.hover);
          }}
          onPointerLeave={() => {
            if (!isDragRef.current) setHover(false);
            setMaterialColor(ref.current!.material as MeshBasicMaterial, colors?.point);
          }}
        >
          <circleGeometry args={[baseSize, 20]} userData={{ isDraggable: true }} />
          <meshBasicMaterial color={colors?.point} depthTest={false} depthWrite={false} transparent opacity={1} side={DoubleSide} />
        </mesh>
        <mesh ref={ringRef} position={position0} renderOrder={999} frustumCulled={false}>
          <ringGeometry args={[baseSize, baseSize + baseSize * 0.2, 20, 20]} />
          <meshBasicMaterial color={colors?.border} depthTest={false} depthWrite={false} transparent opacity={1} />
        </mesh>
      </group>
    );
  }
);

export default EllipseDragPoint;
