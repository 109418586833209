import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import * as _ from 'lodash';
import { useEffect } from 'react';
import { Vector3 } from 'three';
import { CardProps } from '../../../@types/CardProps';
import ValidationAction from '../../../@types/enums/ValidationAction';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES, METRICS_VALUE_LIMITS } from './TrunkDetailsCard/Constants';

const TO_FIXED = 2;

const FirstBifurcationCard = ({ currentTree }: CardProps) => {
  const { treeMetrics, visibility, cards, validation, actions, helper, section, pointCloudModule } = useSemanticScreenContext();

  const localHeight = currentTree?.location_local ? currentTree.location_local[2] : 0;
  const min = pointCloudModule.pointCloud?.geometry?.boundingBox?.min.z ?? METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].min;
  const max = pointCloudModule.pointCloud?.geometry?.boundingBox?.max.z ?? METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].max;

  const alert = cards.alerts.find((a) => a.name === METRICS_NAMES.FIRST_BIFURCATION_HEIGHT);

  const setValue = (value: number) => {
    if (helper.isEditingDisabled) return;
    const updated = new Vector3().copy(treeMetrics.firstBifurcationPoint);
    updated.z = value + helper.viewerPosition.z + localHeight;
    treeMetrics.setFirstBifurcationPoint(updated);

    const sectionUpdated = new Vector3(0, 0, value);
    section.setTarget(sectionUpdated);
    section.setNormal(new Vector3(0, 0, 1));
    section.setDefaultTarget(sectionUpdated);
    section.setDefaultNormal(new Vector3(0, 0, 1));
  };

  const setActiveToolTo = () => {
    if (helper.activeTool === ValidationAction.FirstBifurcationPoint) return;

    const correctedHeight = treeMetrics.firstBifurcationPoint.z - helper.viewerPosition.z - localHeight;
    const correctedTarget = new Vector3(0, 0, correctedHeight);

    section.setDefaultDepth();
    section.setTarget(correctedTarget);
    section.setNormal(new Vector3(0, 0, 1));
    actions.setActiveTool(ValidationAction.FirstBifurcationPoint);
  };

  useEffect(() => {
    const isHeightInRange = _.inRange(
      Number(treeMetrics.firstBifurcationPoint.z - localHeight),
      METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].min,
      METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].max
    );
    if (!isHeightInRange) {
      const alreadyAlerted = cards.alerts.find((a) => a.name === METRICS_NAMES.FIRST_BIFURCATION_HEIGHT);

      if (!alreadyAlerted?.name) {
        cards.setAlerts((prev) => [
          ...prev,
          {
            name: METRICS_NAMES.FIRST_BIFURCATION_HEIGHT,
            message: `First Bifurcation height must be between ${METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].min} and ${METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].max
              } ${METRICS_VALUE_LIMITS[METRICS_NAMES.FIRST_BIFURCATION_HEIGHT].unit}`,
          },
        ]);
      }
    } else {
      cards.setAlerts((prev) => prev.filter((a) => a.name !== METRICS_NAMES.FIRST_BIFURCATION_HEIGHT));
    }
  }, [treeMetrics.firstBifurcationPoint.z]);

  return (
    <AttributeCard
      title='First Bifurcation: Height'
      color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
      isExpanded={visibility.firstBifurcationPoint}
      onExpand={visibility.setFirstBifurcationPoint}
      setCompleted={validation.setIsFirstBifurcationCompleted}
      disabled={helper.isEditingDisabled}
      completed={validation.isFirstBifurcationCompleted}
      isActive={helper.activeTool === ValidationAction.FirstBifurcationPoint}
      setIsActive={setActiveToolTo}
    >
      <div className={'body-content'}>
        <div className='card-content'>
          <div className='detail-title'>
            <p>Vertical position</p>
            {alert?.message && (
              <Tooltip placement='left' title={alert?.message}>
                <WarningAmberOutlinedIcon color='warning' />
              </Tooltip>
            )}
          </div>
          <span className='number-input'>
            <PlainNumberInput
              value={Number((treeMetrics.firstBifurcationPoint.z - helper.viewerPosition.z - localHeight).toFixed(TO_FIXED))}
              step={0.01}
              onChange={setValue}
              min={Number(min?.toFixed(TO_FIXED))}
              max={Number(max?.toFixed(TO_FIXED))}
            />
            <span>{' m'}</span>
          </span>
        </div>
      </div>
    </AttributeCard>
  );
};

export default FirstBifurcationCard;
