import Segment from './Segment';
import { Toggle, Checkbox } from './inputs';
import ViewOptions from './ViewOptions';

const MapControls = ({ layers = {}, layersVisible = {}, onLayerVisibleChange = () => {}, showLayerFilters = false,
                       sources = {}, sourcesVisible = {}, onSourceVisibleChange = () => {}, showSourceFilters = false,
                       showViewOptions = false, isSatellite, setIsSatellite = () => {},
                       isPanoramaVisible, isPanoramaSelected, setPanoramaSelected,
}) => {
  const _handleLayer = ({ name, value }) => {
    const _layers = { ...layersVisible };
    _layers[name] = value;
    onLayerVisibleChange(_layers)
  }

  const _handleSource = ({ name, value }) => {
    const _sources = { ...sourcesVisible };
    _sources[name] = value;
    onSourceVisibleChange(_sources);
  }

  return (
    <div className="sidebar-map-controls-wrapper">
      {
        showViewOptions && (
          <Segment label='Basemap'>
            <ViewOptions
              isSatellite={isSatellite}
              setIsSatellite={setIsSatellite}
              isPanoramaSelected={isPanoramaSelected}
              isPanoramaVisible={isPanoramaVisible}
              setPanoramaSelected={setPanoramaSelected}
            />
          </Segment>
        )
      }
      {
        showSourceFilters && (
          <Segment label='Layers'>
              {
                sources.filter(source => !!source.name).map(source => <Toggle key={source.id} onChange={_handleSource} name={source.id} value={sourcesVisible[source.id]} label={source.name} />)
              }
          </Segment>
        )
      }
      {
        (showLayerFilters && layers.length > 0) && (
          <Segment label='Filter trees'>
            {
              layers.map(layer => <Checkbox key={layer.id} name={layer.id} label={layer.label} onChange={_handleLayer} value={layersVisible[layer.id]} color={layer.color} />)
            }
          </Segment>
        )
      }
    </div>
  )
}

export default MapControls;