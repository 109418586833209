import ValidationAction from '../../../@types/enums/ValidationAction';
import { circumferenceOfEllipse, radiusFromCircumference } from '../../../utils/mathUtils';
import AttributeCard from '../../Card/AttributeCard';
import { PlainNumberInput } from '../../inputs/PlainNumberInput';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import { METRICS_NAMES } from './TrunkDetailsCard/Constants';
import * as _ from 'lodash';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';

const TO_FIXED = 2;

const CanopyCard = () => {
  const {
    treeMetrics,
    visibility,
    cards,
    validation,
    actions,
    helper,
    section: { setDefaultDepth, resetSectionTargetToDefault }
  } = useSemanticScreenContext();

  const circumference = circumferenceOfEllipse(treeMetrics.canopy?.rX ?? 0.1, treeMetrics.canopy?.rY ?? 0.1);

  const alert = cards.alerts.find((a) => a.name === METRICS_NAMES.CROWN_CIRCUMFERENCE);

  const diameterA = treeMetrics.canopy?.rX * 2 ?? 0.01;
  const diameterB = treeMetrics.canopy?.rY * 2 ?? 0.01;

  const setCircumferenceByDiameter = (diameter: number, axis: 'rX' | 'rY') => {
    const selectedRadius = diameter ? diameter / 2 : 0.01;
    treeMetrics.setCanopy((prev) => ({...prev, [axis]: selectedRadius }));
  };
  return (
    <AttributeCard
      title='Canopy Size'
      color={SemanticActionsColorMap[ValidationAction.Canopy]}
      isExpanded={visibility.canopy}
      onExpand={visibility.setCanopy}
      setCompleted={validation.setIsCanopyCompleted}
      disabled={helper.isEditingDisabled}
      completed={validation.isCanopyCompleted}
      isActive={helper.activeTool === ValidationAction.Canopy}
      setIsActive={() => {
        resetSectionTargetToDefault();
        setDefaultDepth();
        actions.setActiveTool(ValidationAction.Canopy);
      }}
    >
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1 }}>Circumference</p>
        <span className='number-input'>
          <PlainNumberInput
            value={parseFloat(circumference.toFixed(TO_FIXED))}
            step={0.1}
            onChange={(value: number) => {
              if (helper.isEditingDisabled) return;
              const radius = Math.max(radiusFromCircumference(value), 0.01);
              treeMetrics.setCanopy({...treeMetrics.canopy, rX: radius, rY: radius})
            }}
            min={0.1}
          />
          <span>{' m'}</span>
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1 }}>Diameter A</p>
        <span className='number-input'>
          <PlainNumberInput
            value={parseFloat(diameterA.toFixed(TO_FIXED))}
            step={0.1}
            onChange={(diameter: number) => {
              if (helper.isEditingDisabled) return;
              const correctedDiameter = Math.max(diameter, 0.01);
              setCircumferenceByDiameter(correctedDiameter, 'rX');
            }}
            min={0.01}
          />
          <span>{' m'}</span>
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={{ flexGrow: 1 }}>Diameter B</p>
        <span className='number-input'>
          <PlainNumberInput
            value={parseFloat(diameterB.toFixed(TO_FIXED))}
            step={0.1}
            onChange={(diameter: number) => {
              if (helper.isEditingDisabled) return;
              const correctedDiameter = Math.max(diameter, 0.01);
              setCircumferenceByDiameter(correctedDiameter, 'rY');
            }}
            min={0.01}
          />
          <span>{' m'}</span>
        </span>
      </div>
    </AttributeCard>
  );
};

export default CanopyCard;
