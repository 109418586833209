import { Tooltip } from '@mui/material';
import { ColumnType } from '../../@types/Table';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import { sorter } from '../../utils/sorter';
import { Button } from '../inputs';
import Completed from './Completed';
import IconButton from './IconButton';
import MultiSelect from './MultiSelect';
import Select from './Select';
import Status from './Status';
import Uncertain from './Uncertain';
import * as _ from 'lodash';
import { QC_STATUSES } from '../../@types/enums/QualityCheckStatus';
import Tree from '../../@types/Tree';

const qcAllowedToRevertToPending = (tree: Tree) => {
  if (tree.qc_status === QC_STATUSES.QC_PENDING) return false;

  if (tree.qc_status === QC_STATUSES.QC_ERROR && (tree.tree_flow_status === TreeFlowStatus.SentToField || tree.tree_flow_status === TreeFlowStatus.SentToOfflineAnnotation)) {
    return false;
  }

  return true;
}

const dateFormatter = (date?: Date) => {
  if (!date) return '-';
  date = new Date(date);

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const dateTimeFormatter = (date?: Date) => {
  if (!date) return '-';
  date = new Date(date);

  const hour = date.getHours();
  const minutes = date.getMinutes();

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${hour}:${minutes}`;
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const monthFormatter = (date?: Date) => {
  if (!date) return '-';
  date = new Date(date);

  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

export const tableSorter = (data: any[], sortDir: any, column: ColumnType, getValueByResolver: (entry: any, resolver: any) => any) => {
  if (column && data) {
    const sortCb = (a: string, b: string) => {
      const valueA = getValueByResolver(a, column.resolver);
      const valueB = getValueByResolver(b, column.resolver);

      return sorter(valueA, valueB, sortDir, column.format);
    };

    return [...data].sort(sortCb);
  } else {
    return data || [];
  }
};

const formatter = (
  column: ColumnType,
  format?: string,
  value?: string | number | Date,
  label?: string,
  entry?: any,
  updateTree?: (id: string, fieldsToUpdate: Object) => void,
  selected?: boolean,
  setQualityCheckStatus?: (id: string, qc_status: string) => void,
  sendToOfflineAnnotation?: (id: string) => void,
): JSX.Element | JSX.Element[] | string => {
  switch (format) {
    case 'TITLE':
      return <span className='title'>{String(value)}</span>;
    case 'DATE':
      return <span className='date'>{dateFormatter(value as Date)}</span>;
    case 'DATETIME':
      return <span className='date'>{dateTimeFormatter(value as Date)}</span>;
    case 'MONTH':
      return <span className='date'>{monthFormatter(value as Date)}</span>;
    case 'STATUS':
      return <Status status={value as string} />;
    case 'ICONBUTTON':
      return <IconButton {...column} entry={entry} />;
    case 'CODE':
      return <pre className='code'>{String(value)}</pre>;
    case 'PERCENTAGE':
      return (
        <span className='value percentage'>
          {value !== null ? `${(Math.round(Number(value as string) * 1000) / 10).toFixed(1)}%` : '-'}
        </span>
      );
    case 'SELECT':
      return <Select entry={entry} options={column.options!} value={value as string} column={column} />;
    case 'MULTISELECT':
      return <MultiSelect entry={entry} label={label!} options={column.options!} value={value as string} column={column} />;
    case 'REVERT_STATUS_BUTTON':
      return entry.tms_category === 'l3' ||
        entry.tree_flow_status === TreeFlowStatus.LocationValidationDeleted ||
        entry.tree_flow_status === TreeFlowStatus.LocationValidationDone ? (
        <Button
          label='Revert to todo'
          primary={true}
          classNames='table-action-button'
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const requestParams = {
              status: 'location_validation_todo',
              tree_flow_status: TreeFlowStatus.LocationValidationQueued,
              tms_category: null,
            };
            return updateTree?.(entry.id, requestParams);
          }}
        />
      ) : (
        <></>
      );
    case 'UNCERTAIN':
      return <Uncertain value={!value} />;
    case 'COMPLETED':
      return <Completed value={!!value} />;
    case 'ESS_ID':
      return (
        <div style={{ display: 'flex', justifyContent: selected ? 'space-between' : 'normal' }}>
          <span className='value text' style={{ marginTop: selected ? '6px' : '' }}>
            {String(value)}
          </span>
          {selected && column.clickNavigation ? <IconButton icon={'expand'} onClick={column.clickNavigation} entry={entry} /> : ''}
        </div>
      );
    case 'ESS_STATUS':
      if (value === 'To Do') {
        return (
          <div className='ess-status-wrapper' style={{ backgroundColor: 'rgba(236, 177, 74, 0.5)' }}>
            <span className='label'>To Do</span>
          </div>
        );
      }
      return (
        <div className='ess-status-wrapper' style={{ backgroundColor: 'rgba(5, 139, 98, 0.5)' }}>
          <span className='label'>Done</span>
        </div>
      );
    case 'CONFIDENCE':
      if (!value) {
        return '-';
      }
      if (value === 1) {
        return (
          <div className='ess-status-wrapper' style={{ backgroundColor: 'rgba(236, 177, 74, 0.5)' }}>
            <span className='label'>Set manually</span>
          </div>
        );
      }
      return `${Number(value) * 100}%`;
    case 'QC_ISSUES':
      if (!value) {
        return `-` as any;
      }

      let issuesArray = value.toString().slice(0).replaceAll(`"`, '').split(',');

      if (!issuesArray.length) return `-` as any;

      issuesArray = _.sortBy(_.clone(issuesArray));

      const renderTags = () => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            gap: '1vw',
            margin: '.5vh .5vw',
          }}
        >
          {issuesArray.map((issue: string, index: number) => (
            <div key={index} style={{ backgroundColor: 'rgba(236, 74, 74, 0.75)', padding: '1vh 1vw', borderRadius: '5px' }}>
              <span className='label'>{issue}</span>
            </div>
          ))}
        </div>
      );

      const issueTags = () => {
        if (issuesArray?.length > 3) {
          return (
            <Tooltip title={renderTags()} placement='left'>
              <div
                style={{
                  backgroundColor: 'rgba(236, 74, 74, 0.75)',
                  padding: '1vh 1vw',
                  textAlign: 'center',
                  margin: '0 auto',
                  overflow: 'hidden',
                  borderRadius: '5px',
                  maxWidth: '90%',
                }}
              >
                <span>{issuesArray?.length} issues</span>
              </div>
            </Tooltip>
          );
        }

        return renderTags();
      };

      return issueTags();
    case 'QC_ACTIONS':
      return (
        <div className='qc_action_column_wrapper'>
          {qcAllowedToRevertToPending(entry) ? (
            <Button
              label='Revert to pending'
              primary
              classNames='qc_table_button'
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                return setQualityCheckStatus?.(entry.id, QC_STATUSES.QC_PENDING);
              }}
            />
          ) : (
            <></>
          )}
          <Button
            label='Send to offline Annotation'
            primary
            classNames='qc_table_button'
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              return sendToOfflineAnnotation?.(entry.id);
            }}
          />
        </div>
      );
    default:
      return <span className='value text'>{String(value)}</span>;
  }
};

export default formatter;
