import { faAngleLeft, faAngleRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { CommentTree, QualityCheckDetails } from '../components/modals';
import TreeFlowStatus from '../@types/enums/TreeFlowStatus';
import ValidationTool from '../@types/enums/ValidationTool';

const changeStep = (currentStep, direction) => {
  if (direction === 1) {
    switch (currentStep) {
      case 'location_validation':
        return 'segmentation';
      case 'segmentation_validation':
        return 'semantics';
      case 'semantic_validation':
        return 'location';
      default:
        return;
    }
  } else if (direction === -1) {
    switch (currentStep) {
      case 'location_validation':
        return 'semantics';
      case 'segmentation_validation':
        return 'location';
      case 'semantic_validation':
        return 'segmentation';
      default:
        return;
    }
  }
};

export const validationActions = ({
  onUndo,
  isUndoAvailable,
  tree,
  managedAreaId,
  history,
  updateTree,
  markAsDone = () => void 0,
  showTodoAction,
  removeTree,
  sendToField,
  commentTree,
  removeTreeComment = () => {},
  onSkip = () => {},
  numberOfTrees = 0,
  dismissModal,
  presentModal,
  saveDisabled,
  deleteHidden,
  showComment,
  disabled,
  onRotation,
  validationTool,
  changeTool,
  isSemantics,
  showDeleteConfirmation,
  tmsCategory,
  onSetTmsCategory,
  showTmsButtons,
  showTmsL3Button,
  showNavigationArrows,
  isLocationVal = false,
  isQualityCheck = false,
  currentStep = '',
  handleQCStatusChange,
}) => [
  !deleteHidden && {
    icon: 'trash',
    id: 'trash',
    disabled: !numberOfTrees || disabled,
    action: () => removeTree(tree),
    color: 'red',
    showConfirmationPopup: true,
  },
  !deleteHidden && {
    type: 'separator',
  },
  !!showTmsL3Button &&
    !!tree &&
    sendToField && {
      label: 'L3',
      id: 'l3',
      action: () => onSetTmsCategory('l3'),
      tooltip: 'Set TMS category to L3',
      color: 'green',
      hasChecked: tmsCategory === 'l3',
      disabled: tmsCategory === 'l3' || !numberOfTrees || disabled,
      showConfirmationPopup: true,
      confirmationPopupText: 'Do you really want to set L3?',
    },
  !!showTmsL3Button &&
    !!tree &&
    sendToField && {
      type: 'separator',
    },
  /* isSemantics && {
    icon: "eye",
    id: "view",
    color: "primary",
    activeColor: "primary",
    tooltip: "View",
    isActive: validationTool === ValidationTool.View || !validationTool,
    action: () => changeTool(ValidationTool.View),
  },
  isSemantics && {
    type: "separator",
  },
  isSemantics && {
    icon: "paint-brush",
    id: "brush",
    color: "green",
    activeColor: "green",
    tooltip: "Reclassify",
    isActive: validationTool === ValidationTool.Reclassify,
    action: () => changeTool(ValidationTool.Reclassify),
  },
  isSemantics && {
    type: "separator",
  },
  isSemantics && {
    icon: "bug",
    id: "error",
    color: "red",
    activeColor: "red",
    tooltip: "Mark errors",
    isActive: validationTool === ValidationTool.Error,
    action: () => changeTool(ValidationTool.Error),
  },
  isSemantics && {
    type: "separator",
  },*/
  isSemantics && {
    icon: 'eye',
    id: 'inspection_view',
    color: 'primary',
    activeColor: 'green',
    tooltip: 'Inspection View',
    isActive: validationTool === ValidationTool.Inspection_View,
    action: () => changeTool(ValidationTool.Inspection_View, true),
  },
  isSemantics && {
    icon: 'paint-brush',
    id: 'brush',
    color: 'green',
    activeColor: 'green',
    tooltip: 'Reclassify',
    isActive: validationTool === ValidationTool.Reclassify,
    action: () => changeTool(ValidationTool.Reclassify, true),
  },
  isSemantics && {
    type: 'separator',
  },
  onUndo && {
    icon: 'undo-alt',
    id: 'undo',
    disabled: !isUndoAvailable,
    action: onUndo,
    tooltip: 'Undo',
  },
  showComment && {
    icon: 'comment',
    id: 'comment',
    disabled: disabled,
    color: 'primary',
    hasNotification: tree?.comment?.length > 0,
    action: () =>
      presentModal({
        title: 'Add comment to tree',
        content: (
          <CommentTree key={`comment-tree-modal-${tree.id}`} onComment={commentTree} onRemoveComment={removeTreeComment} tree={tree} />
        ),
      }),
  },
  showComment && {
    type: 'separator',
  },
  updateTree &&
    showTodoAction && {
      icon: 'pen',
      id: 'pen',
      disabled: !numberOfTrees || saveDisabled || disabled || (!!showTmsButtons && !tmsCategory) || !tree?.comment?.length,
      action: async () => {
        await updateTree('sent_to_offline_annotation', TreeFlowStatus.SentToOfflineAnnotation);
      },
      tooltip: (() => {
        if (!tree?.comment?.length) return 'Please give a comment';
        if (!!showTmsButtons && !tmsCategory) return 'Must select TMS category';

        return 'Send to offline annotation';
      })(),
      color: 'primary',
    },
  updateTree &&
    showTodoAction && {
      type: 'separator',
    },
  markAsDone &&
    (isSemantics || isLocationVal) &&
    !isQualityCheck && {
      icon: 'check',
      id: 'check',
      disabled: saveDisabled || disabled || (!!showTmsButtons && !isSemantics && !tmsCategory),
      action: () => markAsDone(tree),
      tooltip: 'Mark as done',
      color: 'green',
    },
  markAsDone &&
    (isSemantics || isLocationVal) &&
    !isQualityCheck && {
      type: 'separator',
    },
  !!showTmsButtons &&
    !!tree &&
    sendToField && {
      label: 'L0',
      id: 'l0',
      action: () => onSetTmsCategory('l0'),
      tooltip: 'Set TMS category to L0',
      color: 'green',
      hasChecked: tmsCategory === 'l0',
      disabled: tmsCategory === 'l3' || disabled,
    },
  !!showTmsButtons &&
    !!tree &&
    sendToField && {
      label: 'L1',
      id: 'l1',
      action: () => onSetTmsCategory('l1'),
      tooltip: 'Set TMS category to L1',
      color: 'green',
      hasChecked: tmsCategory === 'l1',
      disabled: tmsCategory === 'l3' || disabled,
    },
  !!showTmsButtons &&
    !!tree &&
    sendToField && {
      label: 'L2',
      id: 'l2',
      action: () => onSetTmsCategory('l2'),
      tooltip: 'Set TMS category to L2',
      color: 'green',
      hasChecked: tmsCategory === 'l2',
      disabled: tmsCategory === 'l3' || disabled,
    },
  !!showTmsButtons &&
    !!tree &&
    sendToField && {
      type: 'separator',
    },
  !!showNavigationArrows && {
    icon: 'arrow-left',
    id: 'previous',
    disabled: !numberOfTrees || disabled,
    action: onSkip(-1),
  },
  !!showNavigationArrows && {
    icon: 'arrow-right',
    id: 'next',
    disabled: !numberOfTrees || disabled,
    action: onSkip(1),
  },
  isQualityCheck && {
    icon: faMagnifyingGlass,
    id: 'quality-check-details',
    tooltip: 'Quality check settings',
    action: () =>
      presentModal({
        title: 'Quality Check Details',
        content: (
          <QualityCheckDetails
            key={`qc-details-${tree.id}`}
            tree={tree}
            handleStatusChange={handleQCStatusChange}
            handleClose={dismissModal}
          />
        ),
      }),
  },
  isQualityCheck &&
    currentStep && {
      icon: faAngleLeft,
      tooltip:
        (currentStep === 'location_validation' && 'Semantic Validation') ||
        (currentStep === 'segmentation_validation' && 'Location Validation') ||
        (currentStep === 'semantic_validation' && 'Segmentation Validation'),
      label: 'Previous Screen',
      action: () => {
        const nextStep = changeStep(currentStep, -1);

        if (!nextStep) return;

        history.push(`/quality_check/${managedAreaId}/${nextStep}/${tree?.id}`);
      },
    },
  isQualityCheck &&
    currentStep && {
      icon: faAngleRight,
      tooltip:
        (currentStep === 'location_validation' && 'Segmentation Validation') ||
        (currentStep === 'segmentation_validation' && 'Semantic Validation') ||
        (currentStep === 'semantic_validation' && 'Location Validation'),
      label: 'Next Screen',
      action: () => {
        const nextStep = changeStep(currentStep, 1);

        if (!nextStep) return;

        history.push(`/quality_check/${managedAreaId}/${nextStep}/${tree?.id}`);
      },
    },
  isQualityCheck &&
    currentStep && {
      type: 'separator',
    },
];
