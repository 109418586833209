import React from 'react';

const Segment = ({ label, children }) => {
  return (
    <div className="content-segment-wrapper">
      <h2 className="label">{ label }</h2>
      <div className="content">{ children }</div>
    </div>
  )
}

export default Segment;