import React from 'react';
import { MsalProvider } from '@azure/msal-react';

import { Provider as ThemeProvider } from './theme';
import { Provider as ModalProvider } from './modal';
import { Provider as PopupProvider } from './alert';
import { Provider as ConfigProvider } from './config';
import { Provider as UserProvider } from './user';
import { Provider as NotificationProvider } from '../hooks/notifications';
import { Idle as IdleTimer } from './idle';
import { Toaster } from 'react-hot-toast';

const Providers = ({ children, pca }) => {
  return (
    <MsalProvider instance={pca}>
      <UserProvider>
        <IdleTimer>
          <NotificationProvider>
            <ThemeProvider>
              <ConfigProvider>
                <ModalProvider>
                  <PopupProvider>{children}</PopupProvider>
                </ModalProvider>
              </ConfigProvider>
            </ThemeProvider>
          </NotificationProvider>
        </IdleTimer>
      </UserProvider>
      <Toaster />
    </MsalProvider>
  );
};

export default Providers;
